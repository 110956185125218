import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import React, {useEffect} from "react";
import FilterLabelWithContent from "./FilterLabelWithContent";
import {TextField} from "../../../../../components/form/TextField";

export type StandardFilterTextFieldProps = {
    type: AlgotestTextFieldType,
    hideLabel?: boolean,
    isFlexGrow?: boolean
}


export type AlgotestTextFieldType =
    'toCity'
    | 'toPostalCode'
    | 'fromCity'
    | 'fromPostalCode'
    | 'affiliate'
    | 'affiliateParameter'
    | 'distance'
    | 'squareMeters'

const numberRegex = /^\d+(\.|,|)(\d{0,2})?$/
const StandardFilterTextField = (props: StandardFilterTextFieldProps) => {
    const {type, hideLabel, isFlexGrow} = props;
    const {t} = useTranslation('algotest');
    const formik = useFormikContext();
    const isNumeric = ['squareMeters', 'distance'].indexOf(type) !== -1
    const shouldCheckRegex = ['distance'].indexOf(type) !== -1
    const ref = React.useRef<any>();
    const fieldProps = formik.getFieldProps(type)

    useEffect(() => {
        if (ref.current && ref.current.value !== fieldProps.value) {
            ref.current.value = fieldProps.value
        }
    }, [fieldProps])

    return (
        <FilterLabelWithContent sx={{flexGrow: isFlexGrow ? 1 : 0}} type={type} hideLabel={hideLabel}>
            <TextField id={type} placeholder={t(`form.${type}`)}
                       sx={{marginTop: '5px !important', display: isFlexGrow ? 'block' : 'inline-block'}}
                       type={isNumeric ? 'number' : 'text'}
                       value={fieldProps.value}
                       inputRef={ref}
                       inputProps={{
                           'data-testid': 'alogtest-text-field-input'
                       }}
                       data-testid={'alogtest-text-field'}
                       onChange={(event) => {
                           const {value} = event.target
                           if (!shouldCheckRegex || (value?.length === 0 || numberRegex.test(value))) {
                               formik?.handleChange(event)
                           }
                       }}/>
        </FilterLabelWithContent>
    )
}
export default StandardFilterTextField
