import {apiVersion, getApiClient} from "../ApiUtils";
import {Page} from "api/Page";
import {PartnerStatisticsPage, PartnerStatisticsRequestFilter} from "api/statistics/PartnerStatisticsResource";
import {formatISO} from "date-fns";
import {
    RequestsAndLeadsStatisticsResource,
    RequestSourceStatisticsResource,
    RequestTypesStatisticsResource
} from "api/statistics/Statistics";

const apiPrefix = '/statistics'

export function getRequestsAndLeadsStatistics(countryCodes: string[], start: Date, end: Date): Promise<RequestsAndLeadsStatisticsResource[]> {
    const isoStart = encodeURIComponent(formatISO(start))
    const isoEnd = encodeURIComponent(formatISO(end))
    return getApiClient().then(client => client.get<RequestsAndLeadsStatisticsResource[]>(
        `${apiPrefix}/leads-and-requests?countryCodes=${countryCodes.join(',')}&start=${isoStart}&end=${isoEnd}`,
        { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data);
}

export function getRequestTypesStatistics(start: Date, end: Date): Promise<RequestTypesStatisticsResource[]> {
    const isoStart = encodeURIComponent(formatISO(start))
    const isoEnd = encodeURIComponent(formatISO(end))
    return getApiClient().then(client => client.get<RequestTypesStatisticsResource[]>(
        `${apiPrefix}/request-types?start=${isoStart}&end=${isoEnd}`,
        { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data);
}

export function getRequestSourcesStatistics(countryCodes: string[], start: Date, end: Date): Promise<RequestSourceStatisticsResource[]> {
    const isoStart = encodeURIComponent(formatISO(start))
    const isoEnd = encodeURIComponent(formatISO(end))
    return getApiClient().then(client => client.get<RequestSourceStatisticsResource[]>(
        `${apiPrefix}/request-sources?countryCodes=${countryCodes.join(',')}&start=${isoStart}&end=${isoEnd}`,
        { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data);
}

export function getPartnersStatistics(
    page: number = 0,
    size: number = 20,
    sortBy?: string,
    filters?: PartnerStatisticsRequestFilter,
): Promise<{ data: Page<PartnerStatisticsPage>, serverTime: Date }> {
    const sortByParam = sortBy ? `&sortBy=${sortBy}` : ''
    const hasActiveContractParam  = filters?.hasActiveContract ? `&hasActiveContract=${filters.hasActiveContract}` : ''
    const ownerDetailsParam = filters?.ownerDetails ? `&ownerDetails=${filters.ownerDetails}` : ''

    return getApiClient().then(client => client.get<Page<PartnerStatisticsPage>>(
        `${apiPrefix}/partners?page=${page}&size=${size}${sortByParam}${hasActiveContractParam}${ownerDetailsParam}`,
        { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => {
        const isoString = response.headers['x-server-time'].replace("[UTC]", "")
        return {
            data: response.data,
            serverTime: new Date(isoString)
        }
    });
}
