export enum ContractServiceType {
  RELOCATION_PREMIUM = 'RELOCATION_PREMIUM',
  RELOCATION_BASIC = 'RELOCATION_BASIC',
  RELOCATION_BASIC_CH = 'RELOCATION_BASIC_CH',
  RELOCATION_OTHER_LEAD_TYPES = 'RELOCATION_OTHER_LEAD_TYPES',
  RELOCATION_LEAD_SHOP = 'RELOCATION_LEAD_SHOP',
  UNKNOWN = 'UNKNOWN',
}

export type ContractResourcePage = {
  contracts: ContractResource[];
}

export type ContractResource = {
  cwid: string
  startDate?: string | null
  endDate?: string | null
  name: ContractServiceType
  contractStatus: ContractStatus
}

export enum ContractStatus {
  CREATED = "CREATED",
  ANNULLED = "ANNULLED",
  ENDED = "ENDED",
}
