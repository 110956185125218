import React, {useEffect, useRef} from "react";
import {useOnScreen} from "./util/UmzugEasyTableHooks";
import {TableRow} from "@mui/material";

type TableRowVisibilityDetectorProps = {
    onIntersection: () => void
}

const TableRowVisibilityDetector: React.FC<TableRowVisibilityDetectorProps> = ({onIntersection}) => {
    const ref = useRef<HTMLTableRowElement>(null);
    const {isIntersecting, setIntersecting} = useOnScreen(ref);

    useEffect(() => {
            if (isIntersecting) {
                onIntersection();
                setIntersecting(false)
            }
            // eslint-disable-next-line
        }, [isIntersecting]
    )
    /**
     * render table row 10px higher to fix issues with horizontal scrollbar that hides detection row
     */
    return <TableRow sx={{ position: 'relative', top: '-10px' }} ref={ref}></TableRow>
}
export default TableRowVisibilityDetector
