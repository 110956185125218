import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {PartnerDataProps} from "../../../MainData";
import {ShopPackageResource} from "../../../../../../api/shopPackage/ShopPackageResource";
import {getShopPackageByPartnerCwid} from "../../../../../../api/shopPackage/ShopPackageApi";
import BookingSection from "../../BookingSection";
import {Box} from "@mui/material";
import Label from "../../../../../../components/form/Label";
import FieldIcon from "../../../../../../components/form/FieldIcon";


const ShopPackage: React.FC<PartnerDataProps> = ({partner}: PartnerDataProps) => {
    const [shopPackage, setShopPackage] = useState<ShopPackageResource | null>(null);
    const {t} = useTranslation('bookings')

    useEffect(() => {
        if (partner?.cwid) {
            getShopPackageByPartnerCwid(partner.cwid).then(setShopPackage);
        }
    }, [partner?.cwid]);

    return (
        <BookingSection title={t('shopPackage.header.entry')} isModalSection={false}>
            {shopPackage ? (
                <>
                    <Box>
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 2fr',
                            paddingTop: '16px'
                        }}>
                            <Box>
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <Label label={t('shopPackage.startDate')}
                                           sx={{
                                               minWidth: '300px',
                                               textAlign: 'left',
                                               paddingBottom: '16px'
                                           }}/>
                                    <Label label={<>{shopPackage?.contractStartDate}</>} sx={{
                                        textAlign: 'left',
                                        minWidth: '200px',
                                        paddingRight: '34px',
                                        paddingBottom: '16px'
                                    }}/>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <Label label={t('shopPackage.monthlyBudget')}
                                           sx={{
                                               minWidth: '300px',
                                               textAlign: 'left'
                                           }}/>
                                    <Label label={<>{shopPackage?.totalBudget}</>} sx={{
                                        textAlign: 'left',
                                        minWidth: '2px',
                                        paddingRight: '5px'
                                    }}/>
                                    <FieldIcon type={'euro'}/>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <Label label={t('shopPackage.usedBudget')}
                                           sx={{
                                               minWidth: '300px',
                                               textAlign: 'left'
                                           }}/>
                                    <Label label={<>{shopPackage?.usedBudget}</>} sx={{
                                        textAlign: 'left',
                                        minWidth: '2px',
                                        paddingRight: '5px'
                                    }}/>
                                    <FieldIcon type={'euro'}/>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <Label label={t('shopPackage.remainingBudget')}
                                           sx={{
                                               minWidth: '300px',
                                               textAlign: 'left',
                                           }}/>
                                    <Label label={<>{shopPackage?.remainingBudget}</>} sx={{
                                        textAlign: 'left',
                                        minWidth: '2px',
                                        paddingRight: '5px',
                                    }}/>
                                    <FieldIcon type={'euro'}/>
                                </Box>
                            </Box>
                            <Box>
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <Label label={t('shopPackage.endDate')}
                                           sx={{
                                               minWidth: '250px',
                                               textAlign: 'left',
                                               paddingBottom: '16px'
                                           }}/>
                                    <Label label={<>{shopPackage?.contractEndDate}</>} sx={{
                                        textAlign: 'left',
                                        minWidth: '200px',
                                        paddingRight: '34px',
                                        paddingBottom: '16px'
                                    }}/>
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <Label label={t('shopPackage.boughtLeads')}
                                           sx={{
                                               minWidth: '250px',
                                               textAlign: 'left'
                                           }}/>
                                    <Label label={<>{shopPackage?.leadsBoughtNumber} </>} sx={{
                                        textAlign: 'left',
                                        minWidth: '200px',
                                    }}/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </>
            ) : (
                <Box>
                    <Label sx={{
                        textAlign: 'left',
                        fontWeight: 'bold',
                        fontStyle: 'normal'
                    }} label={t('shopPackage.noPackageMessage')}/>
                </Box>
            )}
        </BookingSection>
    );
};

export default ShopPackage;
