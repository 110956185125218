import React, {FC} from "react";
import {Box} from "@mui/material";

type ModalTitleProps = {
    text: string
    icon: JSX.Element
}
const ModalTitle: FC<ModalTitleProps> = ({text, icon}) => {
    return (
        <Box sx={{
            display: 'inlineFlex',
            backgroundColor: "#F2F4F4",
            padding: '40px'
        }}>
            {icon}
            <Box sx={{paddingLeft: '15px'}}>{text}</Box>
        </Box>
    )
}

export default ModalTitle