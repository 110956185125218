import {Box, FormControlLabel, Theme, styled, useTheme} from '@mui/material';
import {SxProps} from "@mui/system";
import {DateCalendar} from '@mui/x-date-pickers/DateCalendar';
import React, { useEffect, useState } from 'react';
import {getDayBefore, getNextDay} from '../../utils/DateUtil';
import Label from '../form/Label';
import {useTranslation} from 'react-i18next';
import Button from '../form/Button';
import Modal from '../form/Modal';
import ToggleSwitch from '../form/ToggleSwitch';

const StyledDateRangeCalendar = styled('div')({
    display: "flex",
    marginTop: "50px",
    marginBottom: "-50px",
    justifyContent: "space-between"
})

const SwitchWrapper = styled('div')({
    position: "relative",
    top: -25,
    left: -15,
    marginBottom: "20px"
})

const EndDateCalendarWrapper = styled('div')({
    position: "relative",
    top: -54
})

const startDateLabelStyles = {
    fontWeight: 600,
    fontSize: "14px"
}

const endDateLabelStyles = {
    fontWeight: 600,
    fontSize: "14px",
    position: "relative",
    top: "7px"
}

const toggleStyles = {
    marginLeft: "10px"
}

export type DateRangeCalendarModalProps = {
  sx?: SxProps<Theme>,
  disablePast: boolean,
  showModal: boolean,
  onSelect: (start: Date, end: Date) => void,
  onCancel: () => void,
  initialStartDate?: Date,
  initialEndDate?: Date
}

export type DateRange = {
    startDate: Date | null | undefined,
    endDate: Date | null | undefined
}

const DateRangeCalendarModal: React.FC<DateRangeCalendarModalProps> = ({
    disablePast, 
    showModal,
    initialStartDate,
    initialEndDate, 
    onSelect, 
    onCancel}) => {

    const {t} = useTranslation(['bookings', 'common'])
    const theme = useTheme<Theme>()


    const [startDate, updateStartDate] = 
    useState<Date | null | undefined>(new Date())
    const [endDate, updateEndDate] = 
    useState<Date | null | undefined>(getNextDay(startDate))

    const [isDateRange, updateIsDateRange] = useState<boolean>(
    (!!initialStartDate && !!initialEndDate)
     || false)

     const resetDates = () => {
        updateStartDate(new Date())
        updateEndDate(null)
        updateIsDateRange(false)
     }

     useEffect(() => {
        // Initialize for edit mode
        updateStartDate(initialStartDate ?? new Date())
        updateEndDate(initialEndDate)
        updateIsDateRange(!!initialStartDate && !!initialEndDate)
     }, [initialStartDate, initialEndDate])

     useEffect(() => {
        if (!isDateRange) {
            updateEndDate(null)
        }
     }, [isDateRange])

     useEffect(() => {
        if(!showModal) {
            resetDates()
        }
     }, [showModal])

     const dateCalendarStyles = {
        "& .MuiPickersDay-root": {
            "&.Mui-selected": {
                backgroundColor: theme.palette.warning.main
            },
        },
        "& .MuiPickersDay-root:focus": {
                "&.Mui-selected": {
                    backgroundColor: theme.palette.warning.main
            },
        },
        "& .MuiPickersYear-yearButton": {
            "&.Mui-selected": {
                backgroundColor: theme.palette.warning.main
            }
        },
        "& .MuiIconButton-edgeStart:not(.Mui-disabled)": {
            color: theme.palette.warning.main
        },
        "& .MuiIconButton-edgeEnd:not(.Mui-disabled)": {
            color: theme.palette.warning.main
        },
        "& .MuiPickersCalendarHeader-switchViewButton:not(.Mui-disabled)": {
            color: theme.palette.warning.main
        }
    };

    const endDateCalendarStyles = {
        "& .MuiPickersCalendarHeader-label": {
            opacity: isDateRange ? "inherit" : "0.3 !important",
        }
    }

    const formControlLabel = {
        "& .MuiFormControlLabel-label": {
            fontSize: "16px",
            fontWeight: 600
        }
    }

  const handleSelection = () => {
    startDate && onSelect(startDate, endDate ? endDate : startDate)
  }

  return (
    <Modal isOpen={showModal} title={t('booking.bookingsSettings.exclusionDates.dateRangePickerTitle')} width={'700px'}>
        <>
            <StyledDateRangeCalendar >
                <div>
                    <Label sx={startDateLabelStyles} label={t('dateRangePicker.startDate', {ns: "common"})}/>
                    <DateCalendar
                        sx={dateCalendarStyles}
                        maxDate={getDayBefore(endDate)}
                        value={startDate}
                        disablePast={disablePast}
                        onChange={(date) => updateStartDate(date)}/>
                </div>
                <div>
                <SwitchWrapper>
                    <FormControlLabel
                        control={<ToggleSwitch
                            sx={toggleStyles}
                            onChange={e => updateIsDateRange(e.target.checked)}
                            checked={isDateRange}/>}
                        label={t('dateRangePicker.selectDateRangeLabel', {ns: "common"})}
                        labelPlacement="start"
                        sx={formControlLabel}
                    />
                </SwitchWrapper>
                <EndDateCalendarWrapper>
                    <Label sx={{...endDateLabelStyles, opacity: isDateRange ? 1 : 0.3}} label={t('dateRangePicker.endDate', {ns: "common"})}/>
                    <DateCalendar
                        sx={{...dateCalendarStyles, ...endDateCalendarStyles}}
                        minDate={getNextDay(startDate)}
                        value={(isDateRange && (endDate ?? null)) || null}
                        disableHighlightToday
                        disabled={!isDateRange}
                        disableFuture={!isDateRange}
                        disablePast={!isDateRange}
                        defaultCalendarMonth={startDate}
                        onChange={(date) => updateEndDate(date)}/>
                </EndDateCalendarWrapper>
                </div>
            </StyledDateRangeCalendar>
            <Box sx={{display: 'flex', justifyContent: 'end', columnGap: '15px'}}>
                <Button
                    label={t('booking.edit.buttons.cancel')}
                    appearance={'dark'}
                    variant={'outlined'}
                    onClick={onCancel}/>
                <Button
                    label={t('booking.edit.buttons.save')}
                    appearance={'dark'}
                    onClick={handleSelection}/>
            </Box>
        </>
    </Modal>
  );
};

export default DateRangeCalendarModal;
