import {Booking, getPriceGroups} from "../../../../api/booking/BookingResource";
import React, {ReactElement, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {mapToCamelCasedString} from "../../../../utils/StringUtils";
import {Box, Theme} from "@mui/material";
import {useTheme} from "@mui/styles";

type BookingDetailsProps = {
    booking: Booking;
}

const BookingDetailRow = ({header, value, flexGrow, minWidth}: {
    header: string,
    value: string,
    flexGrow?: number,
    minWidth?: number
}) => {
    const theme = useTheme<Theme>()
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '54px',
            minWidth: minWidth ? `${minWidth}px` : 'unset',
            flexGrow: flexGrow || 0
        }}>
            <Box sx={{
                fontStyle: 'italic',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '20px',
                color: theme.palette.text.secondary
            }}>{header}</Box>
            <Box sx={{
                fontSize: '14px',
                lineHeight: '20px',
                color: theme.palette.text.primary
            }}>{value}</Box>
        </Box>
    )
}

const BookingDetails: React.FC<BookingDetailsProps> = ({booking}: BookingDetailsProps): ReactElement => {
    const {t} = useTranslation('bookings')
    const [details, setDetails] = useState({
        price: '0/0/0/0',
        squareMeters: '0 - 0',
        distance: '0 - 0',
        payment: '',
        relocationType: '',
        maxPartners: '-'
    })

    useEffect(() => {
        const prices = getPriceGroups(booking.pricesByApartmentArea)
        setDetails({
            price: prices.map(value => value.toFixed(2)).join('/'),
            squareMeters: `${booking.minSquareMeters} - ${booking.maxSquareMeters}`,
            distance: `${booking.minDistance} - ${booking.maxDistance}`,
            payment: booking.paymentTypes.map(item => t(`payment.${mapToCamelCasedString(item)}`)).join(', '),
            relocationType: booking.relocationTypes.map(item => t(`relocationType.${mapToCamelCasedString(item)}`)).join(', '),
            maxPartners: (booking.maxPartners.selected && booking.maxPartners.value) ? booking.maxPartners.value.toString() : '-'
        })
    }, [booking, setDetails, t]);

    return (
        <Box sx={{
            display: 'flex',
            height: '54px',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: '60px',
            gap: '20px'
        }}>
            <BookingDetailRow header={t('booking.details.price')} value={details.price} minWidth={170}/>
            <BookingDetailRow header={t('booking.details.squareMeters')} value={details.squareMeters} minWidth={80}/>
            <BookingDetailRow header={t('booking.details.distance')} value={details.distance} minWidth={80}/>
            <BookingDetailRow header={t('booking.details.payment')} value={details.payment} minWidth={160}/>
            <BookingDetailRow header={t('booking.details.relocationType')} value={details.relocationType} minWidth={120}/>
            <BookingDetailRow header={t('booking.details.maxPartners')} value={details.maxPartners} flexGrow={1}/>
        </Box>
    );
}
export default BookingDetails