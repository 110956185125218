import {Box} from "@mui/material";
import React, {FC} from "react";
import {useDefaultTheme} from "../../../../../themeConfig";

type NoHistoryContentProps = {
    text: string
}

const NoHistoryContent: FC<NoHistoryContentProps> = ({text}) => {
    const theme = useDefaultTheme()
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '14px',
            color: theme.palette.primary.dark,
            textAlign: 'center',
            paddingTop: '15px',
            paddingBottom: '15px',
            height: '30vh'
        }}>{text}</Box>
    )
}

export default NoHistoryContent
