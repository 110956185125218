export type PartnerLeadsFilters = {
    start: Date,
    end: Date,
}

export type PartnerResourcePage = {
    partners: PartnerResource[];
}

export type PartnerResource = {
    accountId?: string;
    cwid: string;
    scoutId?: number;
    name: string;
    email: string;
    phone: string;
    fax?: string;
    internationalBankAccountNumber?: string;
    billingAddress: BillingAddressResource;
    shippingAddress: ShippingAddressResource;
    contact?: ContactResource;
    companyDetails: CompanyDetailsResource;
    isActive: boolean;
    isActiveInCrm: boolean;
    isSelfPaused: boolean;
    isPausedByCustomerCare: boolean;
    groupOfPartnersId?: number;
    additionalInformation: string;
    leadsCount: 0,
    leadsCancelledCount: 0,
    lastInvoiceDate: Date,
    lastInvoiceAmount: number
}

export type CountryCodeIso2 = string;

export type AddressResource = {
    countryCodeIso2: CountryCodeIso2;
    city: string;
    state: string;
    postalCode: string;
    street: string;
    streetNumber: string;
}

export type BillingAddressResource = AddressResource & {
    invoicesEmail?: string;
};

export type ShippingAddressResource = AddressResource & {
    email: string;
}

export type ContactResource = {
    id: string;
    cwid: string;
    email: ContactEmailsResource;
    fax?: string;
    salutation: string;
    firstName: string;
    lastName: string;
    phone: string;
}

export type ContactEmailsResource = {
    fromSalesforce?: string;
}

export enum SelectedContactEmail {
    Salesforce = 'salesforce',
    Alternative = 'alternative'
}

export type CompanyDetailsResource = {
    owner: string;
    websiteUrl?: string;
}

