import React, {FC} from "react";
import {Box} from "@mui/material";
import Label from "../../../../../components/form/Label";
import DatePicker from "../../../../../components/form/DatePicker";
import Button from "../../../../../components/form/Button";
import {useTranslation} from "react-i18next";
import {useFeatureSwitchContext} from "utils/FeatureSwitchContext";

type NewScheduledPauseFormProps = {
    setStartDate: React.Dispatch<React.SetStateAction<Date | null | undefined>>
    setEndDate: React.Dispatch<React.SetStateAction<Date | null | undefined>>
    addScheduledPause: () => void
}
const AddScheduledPauseForm: FC<NewScheduledPauseFormProps> = ({setStartDate, setEndDate, addScheduledPause}) => {
    const {t} = useTranslation('dashboard')
    const {isEditScheduledPauseDisabled} = useFeatureSwitchContext()
    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Box sx={{display: 'flex', alignItems: 'center', maxWidth: '300px'}}>
                    <Label sx={{textAlign: 'right'}} label={t('pause.schedule.from')}/>
                    <DatePicker
                        initialValue={null}
                        placeholder={t('pause.schedule.startDate')}
                        onChange={(dateFrom) => setStartDate(dateFrom)}
                        disablePast={true}
                        disabled={isEditScheduledPauseDisabled}
                        clearable={true}
                    />
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center', maxWidth: '360px'}}>
                    <Label sx={{textAlign: 'right'}} label={t('pause.schedule.to')}/>
                    <DatePicker
                        initialValue={null}
                        placeholder={t('pause.schedule.endDate')}
                        onChange={(dateTo) => setEndDate(dateTo)}
                        disablePast={true}
                        disabled={isEditScheduledPauseDisabled}
                        clearable={true}
                    />
                </Box>
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Button label={t('pause.schedule.addButton')}
                        type={'button'}
                        onClick={addScheduledPause}
                        variant={'outlined'}
                        disabled={isEditScheduledPauseDisabled}
                />
            </Box>
        </Box>
    )
}

export default AddScheduledPauseForm
