import {Popover} from "@mui/material";
import React, {FC} from "react";
import {useInitBookingTestPopoverContext} from "./BookingTestPopoverContext";
import {useBookingTestContext} from "../BookingTestContext";
import {BookingTestPopoverContext} from "./BookingTestPopoverContext.model";
import PopoverTestContent from "./PopoverTestContent";
import {useFormikContext} from "formik";
import {BookingTestForm} from "../../FormFields";

const TourTestEditPopover: FC = () => {
    const ctx = useInitBookingTestPopoverContext()
    const {editedTour} = useBookingTestContext()
    const formik = useFormikContext<BookingTestForm>();

    const open = Boolean(editedTour?.element);
    const handleElementChanged = () => {
        if (editedTour?.editedRegionOrPostalCode.region != null) {
            ctx.popoverTestActions.saveRegion(editedTour?.originalRegionOrPostalCodeToEdit, editedTour?.editedRegionOrPostalCode.region)
            formik.submitForm()
        } else if (editedTour?.editedRegionOrPostalCode.postalCode != null) {
            ctx.popoverTestActions.savePostalCode(editedTour?.originalRegionOrPostalCodeToEdit, editedTour?.editedRegionOrPostalCode.postalCode)
            formik.submitForm()
        }
    }

    return (
        <BookingTestPopoverContext.Provider value={ctx}>
            <Popover
                open={open}
                onClose={() => handleElementChanged()}
                anchorEl={editedTour?.element}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{sx: {overflowY: 'hidden'}}}
            >
                {editedTour && (
                    <PopoverTestContent/>
                )}
            </Popover>
        </BookingTestPopoverContext.Provider>
    )
}

export default TourTestEditPopover
