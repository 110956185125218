import {Trans, useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import {Box, InputAdornment, Skeleton} from "@mui/material";
import React, {FC, useEffect, useState} from "react";
import Label from "./Label";
import {TextField} from "./TextField";
import ErrorMessage from "./ErrorMessage";
import {FieldInputProps} from "formik/dist/types";

export interface Shape {
    [fieldName: string]: any
}

type FormInputProps = {
    formField: string;
    translateKey: string,
    endAdornment?: JSX.Element,
    width?: string,
    minFormWidth?: string,
    type: 'number' | 'string' | 'file',
    translationNameSpace?: string,
    disabled?: boolean,
    valueAlign?: 'left' | 'right' | 'center',
    textAlign?: 'left' | 'right' | 'center',
    multiline?: boolean,
    rows?: number,
    onBlur?: () => void
}

const FormInput: FC<FormInputProps> = (props: FormInputProps) => {
    const [errors, setErrors] = useState<string | undefined>(undefined)
    const {t} = useTranslation(props.translationNameSpace)
    const SquareMeter = () => <span>&#x33A1;</span>;
    const Translation = () => (<Trans t={t} i18nKey={props.translateKey}
                                      components={{squareMeters: <SquareMeter/>}}/>)

    const formik = useFormikContext<Shape>();
    const fieldProps = formik.getFieldProps(props.formField)
    useEffect(() => {
        setErrors(formik.errors[props.formField] as string)
    }, [formik.errors, setErrors, props.formField])
    const textFieldOf = (fieldProps: FieldInputProps<number | string>) => (
        <TextField
            disabled={props.disabled}
            multiline={props.multiline}
            rows={props.rows}
            sx={{
                width: props.minFormWidth || '80px',
                margin: '5px 10px !important',
                border: (errors != null) ? '1px solid red' : 'transparent',
            }}
            onBlur={() => props.onBlur && props.onBlur()}
            id={props.formField}
            type={props.type}
            defaultValue={fieldProps.value}
            onChange={formik?.handleChange}
            inputProps={{
               style: {
                   textAlign: props.valueAlign || 'left'
               }
            }}
            InputProps={{
               endAdornment: (
                   <InputAdornment position="end">{props.endAdornment}</InputAdornment>
               )
            }}
        />
    )

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', width: (props.width || '260px')}}>

            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Label label={<Translation/>}
                       sx={{
                           margin: '5px 0',
                           textAlign: props.textAlign || 'right',
                           flexGrow: 1
                       }}/>
                { fieldProps.value === undefined ? (
                    <Skeleton>{textFieldOf(fieldProps)}</Skeleton>
                ) : textFieldOf(fieldProps) }
            </Box>
            <ErrorMessage text={t(errors || '')}
                          sx={{padding: '0 5px', textAlign: 'right'}}
                          show={errors != null}/>
        </Box>
    )
}
export default FormInput
