import React, {FC, ReactElement} from "react";
import {useTheme} from "@mui/styles";
import {Box, Theme} from "@mui/material";
import {useActiveRoute} from "../../routes/Routes";
import {MenuType} from "./components/NavigationLink";
import NavigationContent from "./components/NavigationContent";

type ContentNavigationProp = {
    value?: { [key: string]: string},
    children?: JSX.Element
}

const ContentNavigation: FC<ContentNavigationProp> = (props: ContentNavigationProp): ReactElement => {
    const theme = useTheme<Theme>()
    const activeRoute = useActiveRoute();
    return (
        <Box
            sx={{
                backgroundColor: theme.palette.primary.dark,
                width: "100%"
            }}
        >
            <Box sx={{
                width: "100%"
            }}>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: {xs: "none", md: "flex"},
                        height: "40px",
                        backgroundColor: theme.palette.background.default,
                        borderBottom: `1px solid ${theme.palette.primary.contrastText}`
                    }}
                >
                    <>
                        <NavigationContent
                            isSubMenuInContent={true}
                            menuType={MenuType.SUBMENU}
                            activeRoute={activeRoute.child}
                            subMenuPathValue={props.value}
                            routeList={activeRoute?.parent?.subRoutesInContent || []}
                        />
                        {props.children}
                    </>
                </Box>
            </Box>
        </Box>
    );
};
export default ContentNavigation
