import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import React, {PropsWithChildren} from "react";
import clickableButton from "../../styles/buttons/clickableButton";

export type FlatButtonProps = {
    onClick?: () => void;
} & PropsWithChildren<{}>;

const flatButtonStyles = makeStyles((theme: Theme) => ({
    backToPartnerListButton: {
        marginTop: "1rem",
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        color: theme.palette.primary.light,
        background: "none",
        border: "none",
        ...clickableButton
    }
}));

const FlatButton = ({ onClick, children }: FlatButtonProps): JSX.Element => {
    const classes = flatButtonStyles();

    return (
        <button className={classes.backToPartnerListButton} onClick={onClick}>
            {children}
        </button>
    );
};

export default FlatButton;
