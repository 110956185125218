import React, {ReactElement} from "react";
import ActionImg from "../../../../components/shared/ActionImg";
import {Box} from "@mui/material";

type DocumentsActionsProps = {
    onRemove: () => void;
    onEdit: () => void;
}

const DocumentsActions: React.FC<DocumentsActionsProps> = ({onRemove, onEdit}: DocumentsActionsProps): ReactElement => {
    return (
        <Box sx={{width: '100%', display: "flex", justifyContent: "flex-end"}}>
            <ActionImg icon={'/icons/trash.svg'} iconStyle={{marginLeft: '25px'}}
                       onClick={(event) => {
                           event.stopPropagation();
                           onRemove();
                       }}/>
            <ActionImg icon={'/icons/pencil.svg'} iconStyle={{marginLeft: '25px'}}
                       onClick={(event) => {
                           event.stopPropagation();
                           onEdit()
                       }}
            />
        </Box>
    )
}
export default DocumentsActions;