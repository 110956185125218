import {getRequestSourcesStatistics} from "api/statistics/StatisticsApi";
import {
    AggregatedStatistics,
    AverageStatistics,
    RequestSourceStatisticsResource,
    Statistics, SumStatistics
} from "api/statistics/Statistics";

export type CountryRequestSourceStatistics = Statistics & AverageStatistics & {
    countryCode: string
}

export const getRequestSourceStatisticsGroupedBySource = (countryCodes: string[], start: Date, end: Date): Promise<Record<string, CountryRequestSourceStatistics[]>> => {
    return getRequestSourcesStatistics(countryCodes, start, end).then(countrySourceStatisticsOf)
}

export const getAggregatedStatistics = (statisticsResources: Statistics[]): AggregatedStatistics => {
    const {totalRequests, totalLeads, totalRevenue} = statisticsResources
        .map(({requestCount, leadCount, revenue}: Statistics) => sumStatisticsOf(requestCount, leadCount, revenue))
        .reduce((acc, currentValue) => ({
            totalRequests: acc.totalRequests + currentValue.totalRequests,
            totalLeads: acc.totalLeads + currentValue.totalLeads,
            totalRevenue: acc.totalRevenue + currentValue.totalRevenue,
        }), sumStatisticsOf(0, 0, 0))
        return {
            totalRequests,
            totalLeads,
            totalRevenue,
            revenuePerRequest: totalRevenue / totalRequests,
            revenuePerLead: totalRevenue / totalLeads,
            multiplier: totalLeads / totalRequests
        }
}

const sumStatisticsOf = (totalRequests: number, totalLeads: number, totalRevenue: number): SumStatistics => ({
    totalRequests,
    totalLeads,
    totalRevenue
})

const countrySourceStatisticsOf = (
    statistics: RequestSourceStatisticsResource[]
): Record<string, CountryRequestSourceStatistics[]> => {
    return statistics.reduce<Record<string, CountryRequestSourceStatistics[]>>((acc, curr) => {
        const { source, countryCode, requestCount, leadCount, revenue } = curr;

        if (!acc[source]) {
            acc[source] = [];
        }

        acc[source].push({
            countryCode,
            requestCount,
            revenuePerRequest: revenue / requestCount,
            leadCount,
            revenuePerLead: revenue / leadCount,
            multiplier: leadCount / requestCount,
            revenue
        });

        return acc;
    }, {});
}
