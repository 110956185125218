import React, {FC} from "react";
import {Box, Theme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/styles";
import {TextField} from "../../../../../../../components/form/TextField";
import RadioButton from "../../../../../../../components/form/RadioButton";
import Label from "../../../../../../../components/form/Label";
import ActionImg from "../../../../../../../components/shared/ActionImg";
import {useBookingTestPopoverContext} from "./BookingTestPopoverContext";
import RegionAndCountryTestSelect from "../../RegionAndCountryTestSelect";
import {useBookingTestContext} from "../BookingTestContext";


const PopoverTestContent: FC = () => {
    const {editedTour} = useBookingTestContext()
    const {popoverTestActions, popoverTestContent} = useBookingTestPopoverContext()
    const {t} = useTranslation('bookingTest')
    const theme = useTheme<Theme>()

    return (
        <Box sx={{display: 'flex', alignItems: 'center', padding: '0 15px', columnGap: '10px'}}>
            <Box>
                <RegionAndCountryTestSelect/>
            </Box>
            {popoverTestContent?.country != null && (
                <Box>
                    <TextField sx={{
                        width: '90px',
                        border: `1px solid ${theme.palette.background.default}`
                    }}
                               defaultValue={popoverTestContent.postalCode}
                               onChange={(event) =>
                                   popoverTestActions.changePopoverPostalCode(event.target.value)}
                               placeholder={t('form.popover.selectCountryOrRegion.postalCode')}/>
                </Box>
            )}
            <Box sx={{display: 'flex', columnGap: '12px'}}>
                <RadioButton onChange={() => popoverTestActions.changeExclusion(false)}
                             checked={!popoverTestContent?.isExcluded}/>
                <Label label={t('form.popover.selectCountryOrRegion.included')}
                       sx={{marginRight: '8px'}}/>
                <RadioButton onChange={() => popoverTestActions.changeExclusion(true)}
                             checked={!!popoverTestContent?.isExcluded}/>
                <Label label={t('form.popover.selectCountryOrRegion.excluded')}/>
            </Box>
            <Box sx={{paddingLeft: '20px'}}>
                <ActionImg icon={'/icons/trash.svg'}
                           onClick={() => popoverTestActions.remove(editedTour?.originalRegionOrPostalCodeToEdit)}/>
            </Box>
        </Box>
    )
}
export default PopoverTestContent
