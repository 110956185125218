import {UmzugEasyTableProps} from "../../UmzugEasyTable";
import {getUmzugEasyTableProps} from "../SchemaUtil";
import {TableSchema} from "../SchemaModel";
import {useEffect, useState} from "react";
import {BookingTour} from "../../../../api/booking/BookingResource";
import {BookingDirection, BookingTourRenderer} from "../../util/UmzugEasyCellRenderers";
import {Region} from "../../../../api/region/RegionResource";
import {TFunction} from "react-i18next";

type ActionType =  (row: BookingTour, t: TFunction) => JSX.Element;

export const useBookingToursLoader = (regions: Region[], action: ActionType) => {
    const [bookingTours, setBookingTours] = useState<BookingTour[]>([])
    const [tableProps, setTableProps] = useState<UmzugEasyTableProps<BookingTour>>(getBookingTourTableSchema([], regions, action));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setTableProps(getBookingTourTableSchema(bookingTours, regions, action)), [bookingTours])

    return {setBookingTours, tableProps}
}
export const getBookingTourTableSchema = (tours: BookingTour[], regions: Region[], action: ActionType): UmzugEasyTableProps<BookingTour> => {
    return {
        table: getUmzugEasyTableProps<BookingTour>({
            schema: getSchema(regions, action),
            rows: tours,
            translationPrefix: 'booking.tours.table',
            translationFile: 'bookings'
        }),
        noDataMessageKey: 'bookings:booking.tours.table.noData'
    }
}

const getSchema = (regions: Region[], action: ActionType): TableSchema<BookingTour>[] => [
    {key: 'from', cellRenderer: (row) => BookingTourRenderer(row, true, regions), order: 1},
    {key: 'direction', cellRenderer: (row) => BookingDirection(row.direction === 'TWO_WAY'), order: 2},
    {key: 'to', cellRenderer: (row) => BookingTourRenderer(row, false, regions), order: 3},
    {key: 'action', cellRenderer: action, order: 4},
]
