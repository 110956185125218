export function formatToTextWithTwoDecimalPlaces(value: number): string {
    return (value || 0).toFixed(2).replace('.', ',');
}
export const getListOfRange = (startNumber: number, endNumber: number) => {
    return Array.from({ length: endNumber - startNumber + 1 }, (_, index) => index + startNumber);
}

export const calculatePercentage = (part: number, total: number, decimalPlaces: number = 1): number => {
    if (total === 0 || part === 0) {
        return 0;
    }
    const percentage = (part / total) * 100;
    return parseFloat(percentage.toFixed(decimalPlaces));
}

export const formatToGermanCurrency = (value: number, withDecimals: boolean = true): string => {
    return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: withDecimals ? 2 : 0,
        maximumFractionDigits: withDecimals ? 2 : 0,
    }).format(value);
};