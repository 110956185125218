import {
    Floor,
    RealEstateType,
    RelocationRequestDetailObjectResponse,
    Rooms
} from "../../../../../api/relocationRequest/RelocationRequestDetail";
import {FC, ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import {mapToCamelCasedString} from "../../../../../utils/StringUtils";

export type RealEstateDetailsSubsectionProps = {
    relocationRequestDetails: RelocationRequestDetailObjectResponse
}

const RealEstateDetailsSubsection: FC<RealEstateDetailsSubsectionProps> = ({relocationRequestDetails} : RealEstateDetailsSubsectionProps): ReactElement => {
    const {realEstateType, rooms, persons, floor, livingSpaceAreaSqm} = relocationRequestDetails
    const {t} = useTranslation('relocationRequests')
    const translationPathPrefix = 'requestDetails.detailsTab.sections.fromTo'

    const translationKeyOf = (postix: string): string => `${translationPathPrefix}.${postix}`
    const estateTypeTranslationOf = (estateType: RealEstateType): string =>
        t(`${translationPathPrefix}.estateType.${mapToCamelCasedString(estateType.toString())}`)
    const floorTranslationOf = (floor: Floor): string =>
        t(`${translationPathPrefix}.floor.${mapToCamelCasedString(floor.toString())}`)
    const roomsTranslationOf = (rooms: Rooms): string =>
        t(`${translationPathPrefix}.rooms.${mapToCamelCasedString(rooms.toString())}`)

    return (
        <>
            <Box>{ realEstateType && t(translationKeyOf('estateTypeRow'), {type: estateTypeTranslationOf(realEstateType)}) }</Box>
            <Box>{ floor && t(translationKeyOf('floorRow'), {floor: floorTranslationOf(floor)}) }</Box>
            <Box>{ rooms && t(translationKeyOf('roomsRow'), {rooms: roomsTranslationOf(rooms)}) }</Box>
            <Box>{ livingSpaceAreaSqm && t(translationKeyOf('livingSpaceRow'), {space: livingSpaceAreaSqm}) }</Box>
            <Box>{ persons && t(translationKeyOf('personsRow'), {persons}) }</Box>
        </>
    )
}

export default RealEstateDetailsSubsection
