export enum UmzugEasyRole {
    Admin = "UMZUG_EASY_ADMIN_ROLE",
    CustomerSupport = "UMZUG_EASY_CUSTOMER_SUPPORT_ROLE",
    User = "UMZUG_EASY_USER_ROLE",
}

export function mapToSystemRole(role: string): UmzugEasyRole | undefined {
    return Object.values(UmzugEasyRole).find((value) => value === role);
}

export function getUserHighestPrivileges(roles: UmzugEasyRole[]): UmzugEasyRole {
    // It will create an array of enum values ordered as they are created in the enum, i.e. 
    // [UMZUG_EASY_ADMIN_ROLE, UMZUG_EASY_CUSTOMER_SUPPORT_ROLE, UMZUG_EASY_USER_ROLE]
    // Then it will try to find first match which will be the highest privilege user has.
    // Currently only used to display user role in the header.
    // Could and should be refactored for access control in the future.
    return Object.values(UmzugEasyRole).find((systemRole) => roles.includes(systemRole))  || UmzugEasyRole.User;
}