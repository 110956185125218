import {Grid, Theme, useTheme} from "@mui/material";
import React, {useState} from "react";
import {InvoiceSummaryResource} from "../../../../api/partner/InvoicesStatisticsResource";
import {SxProps} from "@mui/system";
import {useTranslation} from "react-i18next";
import {formatToIsoTextWithDate} from "../../../../utils/DateUtil";
import ActionImg from "../../../../components/shared/ActionImg";
import {formatToTextWithTwoDecimalPlaces} from "../../../../utils/NumbersUtil";
import {getInvoicePdf} from "../../../../api/invoice/InvoiceApi";
import Alert from "../../../../components/feedback/Alert";

export type InvoiceRowProps = {
    invoice: InvoiceSummaryResource
}

const rowStyles = (theme: Theme, status: "paid" | 'unpaid'): SxProps<Theme> => ({
    background: (theme) => theme.palette.secondary.contrastText,
    marginTop: '2px',
    padding: '1px',
    color: theme.palette.text.primary,
    fontSize: '14px',
    height: '28px',
    alignItems: 'center'
});

const invoiceStatusStyles = (theme: Theme, openPayments: number): SxProps<Theme> => ({
    height: '100%',
    borderLeft: `4px solid ${openPayments === 0 ? theme.palette.success.main : theme.palette.error.main}`
});

const InvoiceRow: React.FC<InvoiceRowProps> = ({invoice}: InvoiceRowProps) => {
    const {t} = useTranslation('partner');
    const theme = useTheme();
    const [invoiceNotFoundError, setInvoiceNotFoundError] = useState(false)

    const downloadInvoice = () => getInvoicePdf(invoice.documentNumber).then((file) => {
        const fileUrl = URL.createObjectURL(file);
        window.open(fileUrl, '_blank');
    }).catch(() => setInvoiceNotFoundError(true))

    return (
        <Grid container sx={rowStyles(theme, invoice.openPayments === invoice.netAmount ? "paid" : "unpaid")}>
            <Grid item xs={0.2} sx={invoiceStatusStyles(theme, invoice.openPayments)}></Grid>
            <Grid item xs={2.8} md={2.3} sx={{fontWeight: '600'}}>{invoice.documentNumber}</Grid>
            <Grid item xs={3} md={2.5}>{t(`bills.documentTypes.${invoice.documentType.toLowerCase()}`)}</Grid>
            <Grid item xs={3} md={2.5}>{formatToIsoTextWithDate(invoice.documentDate)}</Grid>
            <Grid item xs={2} md={4}>
                {invoice.openPayments != null ? `${formatToTextWithTwoDecimalPlaces(invoice.openPayments)} von ${formatToTextWithTwoDecimalPlaces(invoice.grossAmount)} €` : ''}
            </Grid>
            <Grid item xs={1} md={0.5} sx={{paddingRight: '10px'}}>
                <ActionImg
                    icon={'/icons/preview.svg'}
                    onClick={downloadInvoice}
                    tooltip={t('bills.invoicePreviewTooltip')}
                    justifyContent={'flex-end'}/>
            </Grid>
            <Alert text={t('bills.errors.invoiceNotFound')} open={invoiceNotFoundError} handleClose={() => setInvoiceNotFoundError(false)}/>
        </Grid>
    );
}

export default InvoiceRow
