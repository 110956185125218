import {number, object} from 'yup';
import {BookingTestForm} from "../FormFields";


const errorKeys = {
    isRequired: 'errors.isRequired',
    distanceRange: 'errors.distanceRange',
    squareMetersRange: 'errors.squareMetersRange',
}
export const bookingTestSchema = object<BookingTestForm>().shape({
    minDistance: number().min(0, errorKeys.distanceRange).max(9999, errorKeys.distanceRange).defined(errorKeys.isRequired),
    maxDistance: number().min(0, errorKeys.distanceRange).max(9999, errorKeys.distanceRange).defined(errorKeys.isRequired),
    minSquareMeters: number().min(0, errorKeys.squareMetersRange).max(9999, errorKeys.squareMetersRange).defined(errorKeys.isRequired),
    maxSquareMeters: number().min(0, errorKeys.squareMetersRange).max(9999, errorKeys.squareMetersRange).defined(errorKeys.isRequired),
});
