import React, {ReactElement} from "react";
import {makeStyles} from "@mui/styles";
import {Grid, Theme} from "@mui/material";
import Label from "./Label";

export type LabeledFieldProps = {
    label: string | JSX.Element;
    children: ReactElement | string;
}

const labeledFieldStyle = makeStyles((theme: Theme) => ({
    container: {
        alignItems: "center"
    },
    children: {
        color: theme.palette.text.primary,
        fontSize: "14px",
        lineHeight: "40px",
        fontWeight: "bold"
    }
}))

const LabeledField: React.FC<LabeledFieldProps> = ({ label, children }: LabeledFieldProps) => {
    const classes = labeledFieldStyle();

    return (
        <Grid container className={classes.container}>
            <Grid item xs={4} md={3}><Label label={label} sx={{paddingRight: "1rem"}}/></Grid>
            <Grid container item className={classes.children} xs={8} md={9}>{children}</Grid>
        </Grid>
    );
};

export  default LabeledField;
