import React, {FC, ReactElement} from "react";
import {Grid} from "@mui/material";
import General from "./General";
import Pause from "./Pause";
import ToggleHistory from "./ToggleHistory";
import DunningState from "./DunningState";

const Dashboard: FC = (): ReactElement => {

    return (
        <Grid container data-testid={'dashboard'}>
            <General />
            <DunningState />
            <Pause />
            <ToggleHistory />
        </Grid>
    );
};

export default Dashboard;
