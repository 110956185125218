import {useContext, useEffect, useState} from "react";
import {
    Booking,
    BookingDirection,
    BookingTour,
    BookingTourPostalCode,
    BookingTourType
} from "../../../../../api/booking/BookingResource";
import {useBookingContext} from "../BookingContext";
import {FormikContextType} from "formik";
import {getEmptyTour} from "./BookingModal.utils";
import {
    BookingModalActionsProps,
    BookingModalContext,
    BookingModalContextProps,
    BookingModalEditTourProps,
    RegionAndPostalCode
} from "./BookingModalContext.model";
import {BookingModalForm, emptyBooking, formToBooking} from "../BookingContext.model";
import {useBookingsSettingsContext} from "../bookingsSettings/BookingsSettingsContext";


export function useBookingModalContext(): BookingModalContextProps {
    return useContext(BookingModalContext)
}

export function useInitBookingModalContext(formik: FormikContextType<BookingModalForm>): BookingModalContextProps {
    const [editedTour, setEditedTour] = useState<BookingModalEditTourProps | undefined>(undefined);
    const [booking, setBooking] = useState<Booking | undefined>(undefined)
    const [isValid, setValid] = useState<boolean>(false)

    const {modal, bookings, actions, partnerCwid} = useBookingContext()
    const {bookingsSettings} = useBookingsSettingsContext()

    useEffect(() => {
        if (modal?.bookingId != null) {
            setBooking(bookings.find(booking => booking.id === modal?.bookingId));
        } else {
            setBooking(undefined)
        }
    }, [modal?.bookingId, setBooking, bookings])

    useEffect(() => setValid(formik.isValid), [formik.isValid])

    const setTours = (converter: (tours: BookingTour[]) => BookingTour[]) => {
        const tourListProps = formik.getFieldProps<BookingTour[]>('bookingTours')
        formik.setFieldValue(tourListProps.name, converter(tourListProps.value))
    }

    const setDate = (startDate?: Date, endDate?: Date) => {
        formik.setFieldValue("activationDate", startDate);
        formik.setFieldValue("deactivationDate", endDate);
    }

    const modalActions: BookingModalActionsProps = {
        addTour: () => setTours((tours) => [...tours, getEmptyTour()]),
        removeTour: (tourUuid) => setTours((tours) => tours.filter(item => (item.uuid !== tourUuid))),
        setBookingDates: (newActivationDate?: Date, newDeactivationDate?: Date) => setDate(newActivationDate, newDeactivationDate),
        editPostalCode: (tour, postalCode, element) => {
            const newValueToEdit: RegionAndPostalCode = {postalCode, region: undefined};
            setEditedTour({editedRegionOrPostalCode: newValueToEdit, originalRegionOrPostalCodeToEdit: newValueToEdit, element, tour})
        },
        editRegion: (tour, region, element) => {
            const newValueToEdit: RegionAndPostalCode = {postalCode: undefined, region}
            setEditedTour({editedRegionOrPostalCode: newValueToEdit, originalRegionOrPostalCodeToEdit: newValueToEdit, element, tour})
        },
        newPostalCode: (tour, element, isFrom) => {
            const newPostalCode: BookingTourPostalCode = {
                type: isFrom ? BookingTourType.FROM : BookingTourType.TO,
                country: 'DE'
            }
            const newValueToEdit: RegionAndPostalCode = {postalCode: newPostalCode, region: undefined}
            setEditedTour({originalRegionOrPostalCodeToEdit: newValueToEdit, editedRegionOrPostalCode: newValueToEdit, element, tour})
        },
        changeTourDirection: (tour: BookingTour) => {
            const newTour: BookingTour = {
                ...tour,
                direction: tour.direction === 'TWO_WAY' ? BookingDirection.ONE_WAY : BookingDirection.TWO_WAY
            }
            setTours(tours => tours.map(item => (item.uuid === newTour.uuid) ? newTour : item))
        },
        save: () => {
            const shouldUpdate = modal?.type === 'tour' || modal?.action === 'edit'
            if (shouldUpdate && booking != null && modal?.bookingId != null) {
                actions.updateBooking(modal.bookingId, formToBooking(booking, formik))
            }
            if (modal?.type === 'booking' && modal?.action === 'add' && partnerCwid != null) {
                actions.createBooking(formToBooking(emptyBooking(partnerCwid, bookingsSettings?.shopPricesByApartmentArea), formik))
            }
        },
        clearEditedTour: () => setEditedTour(undefined),
        updateEditedTour: (regionOrPostalCode) => setEditedTour(prevState => {
            if (prevState == null) {
                return undefined
            }
            return {...prevState, editedRegionOrPostalCode: regionOrPostalCode}
        }),
        setTours: setTours
    }

    return {editedTour, booking, modalActions, isValid}
}
