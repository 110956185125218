import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/material/styles';
import {Box, tooltipClasses, TooltipProps} from '@mui/material';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }}
             TransitionComponent={Zoom}
             children={props.children}/>
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        textAlign: 'center',
        fontWeight: '600',
        fontSize: '14px',
    },
}));

export type TooltipWrapperProps = {
    tooltipText: string;
    placement: "bottom" | "left" | "right" | "top" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start" | undefined
    children: React.ReactNode;
}

const StyledTooltipWrapper: React.FC<TooltipWrapperProps> = ({ tooltipText, placement, children }) => (
    <StyledTooltip title={tooltipText} placement={placement}>
        <Box>
            {children}
        </Box>
    </StyledTooltip>
);

export default StyledTooltipWrapper;
