import React, { useEffect } from "react";
import { Grid, SelectChangeEvent, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as api from '../../../../api/groupsOfPartners/GroupOfPartnersApi';
import { SelectField, SelectFieldOption } from '../../../../components/form/SelectField';
import Label from '../../../../components/form/Label';
import { makeStyles } from '@mui/styles';
import { usePartnerContext } from '../../shared/PartnerContext';
import { NewGroupModal, NewGroupModalProps } from './NewGroupModal';
import Button from '../../../../components/form/Button';
import Alert from '../../../../components/feedback/Alert';
import { closeModal, openModal } from '../../../../components/form/Modal';

const labeledFieldStyle = makeStyles(() => ({
    container: {
        alignItems: "center"
    }
}))

const LinkedAccountsData: React.FC = () => {
    const { partner, assignPartnerToGroup} = usePartnerContext()
    const classes = labeledFieldStyle();
    const {t} = useTranslation('partner');
    const [errorMessage, setErrorMessage] = React.useState<string>('')
    const [options, setOptions] = React.useState<SelectFieldOption[]>([])
    const [loading, setLoading] = React.useState<boolean>(true)
    const [groupOfPartnersId, setGroupOfPartnersId] = React.useState<string>('notInGroup')
    const [newGroupModalProps, setNewGroupModalProps] = React.useState<NewGroupModalProps>({
        open: false,
        cancel: () => closeModal(setNewGroupModalProps),
        create: (groupName: String) => {
            closeModal(setNewGroupModalProps)
            api.createGroupOfPartners(groupName, partner!!.cwid)
                .then(response => {
                    setOptions(prevState => {
                        return [
                            ...prevState,
                            {
                                key: response.id.toString(),
                                value: response.id.toString(),
                                text: response.name
                            }
                        ]
                    })

                    assignPartnerToGroup(response.id)
                })
                .catch(error => {
                    console.log(error)
                    if (error.response.status === 409) {
                        setErrorMessage(t('mainData.groups.errors.groupAlreadyExists'))
                    } else {
                        setErrorMessage(error.message)
                    }
                })
        }
    })

    useEffect(() => {
        setLoading(true)
        api.getGroups().then(groups => {
            let options = groups.map(group => ({
                key: group.id.toString(),
                value: group.id.toString(),
                text: group.name
            }))

            setOptions(options)
            setGroupOfPartnersId(partner?.groupOfPartnersId?.toString() || 'notInGroup')
        }).finally(() => setLoading(false))
    }, [partner?.groupOfPartnersId])

    const updateGroupAssigment = (changeEvent: SelectChangeEvent) => {
        changeEvent.preventDefault()
        const newGroupId = changeEvent.target.value
        switch (newGroupId) {
            case null:
            case undefined:
                return
            case 'notInGroup':
                removeFromGroup();
                break;
            default:
                changeGroup(parseInt(newGroupId))
        }
    }

    const removeFromGroup = () => {
        if (!partner?.groupOfPartnersId) {
            return
        }
        api.removePartnerFromGroup(partner!!.groupOfPartnersId!!, partner!!.cwid)
            .then(() => assignPartnerToGroup(undefined))
    }

    const changeGroup = (newGroupId: number) => {
        if (partner?.groupOfPartnersId === newGroupId) {
            return
        }

        api.assignPartnerToGroup(newGroupId, partner!!.cwid)
            .then(() => assignPartnerToGroup(newGroupId))
    }

    const createNewGroup = () => openModal(setNewGroupModalProps)

    let selectArea = (
        <>
            <Skeleton variant="rectangular" width={240} height={40} sx={{ m: 1 }}/>
            <Skeleton variant="rectangular" width={140} height={40} sx={{ m: 1 }}/>
        </>
    )

    if (!loading) {
        selectArea = (
           <>
               <SelectField
                   id = 'groupOfPartners'
                   initialValue={ groupOfPartnersId }
                   onChange={updateGroupAssigment}
                   sx={{minWidth: '240px'}}
                   options={[
                       {
                           key: 'notInGroup',
                           value: 'notInGroup',
                           text: t('mainData.groups.notInGroup'),
                       },
                       ...options
                   ]}
               />
               <Button
                   onClick={createNewGroup}
                   label={t('mainData.groups.createGroup')}
               />
          </>
        )
    }

    return (
        <Grid container item xs={12} md={6} className={classes.container}>
            <Label label={t('mainData.labels.group')} sx={{paddingRight: "1rem"}}/>
            { selectArea }
            <NewGroupModal {...newGroupModalProps} />
            <Alert
                text={ errorMessage }
                open={ !!errorMessage }
                horizontal={'center'}
                handleClose={ () => setErrorMessage('') }
                autoHideDuration={60000}/>
        </Grid>
    );
}

export default LinkedAccountsData;
