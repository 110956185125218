import React, {FC} from "react";
import {Box, Theme} from "@mui/material";
import RegionAndCountrySelect from "./RegionAndCountrySelect";
import {TextField} from "../../../../../../../components/form/TextField";
import RadioButton from "../../../../../../../components/form/RadioButton";
import Label from "../../../../../../../components/form/Label";
import ActionImg from "../../../../../../../components/shared/ActionImg";
import {useBookingModalContext} from "../../BookingModalContext";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/styles";
import {useBookingPopoverContext} from "./BookingPopoverContext";


const PopoverContent: FC = () => {
    const {editedTour} = useBookingModalContext()
    const {popoverActions, popoverContent} = useBookingPopoverContext()
    const {t} = useTranslation('bookings')
    const theme = useTheme<Theme>()

    return (
        <Box sx={{display: 'flex', alignItems: 'center', padding: '0 15px', columnGap: '10px'}}>
            <Box>
                <RegionAndCountrySelect/>
            </Box>
            {popoverContent?.country != null && (
                <Box>
                    <TextField sx={{
                        width: '90px',
                        border: `1px solid ${theme.palette.background.default}`
                    }}
                               defaultValue={popoverContent.postalCode}
                               onChange={(event) =>
                                   popoverActions.changePopoverPostalCode(event.target.value)}
                               placeholder={t('booking.edit.popover.selectCountryOrRegion.postalCode')}/>
                </Box>
            )}
            <Box sx={{display: 'flex', columnGap: '12px'}}>
                <RadioButton onChange={() => popoverActions.changeExclusion(false)}
                             checked={!popoverContent?.isExcluded}/>
                <Label label={t('booking.edit.popover.selectCountryOrRegion.included')}
                       sx={{marginRight: '8px'}}/>
                <RadioButton onChange={() => popoverActions.changeExclusion(true)}
                             checked={!!popoverContent?.isExcluded}/>
                <Label label={t('booking.edit.popover.selectCountryOrRegion.excluded')}/>
            </Box>
            <Box sx={{paddingLeft: '20px'}}>
                <ActionImg icon={'/icons/trash.svg'}
                           onClick={() => popoverActions.remove(editedTour?.originalRegionOrPostalCodeToEdit)}/>
            </Box>
        </Box>
    )
}
export default PopoverContent