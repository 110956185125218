import {useTheme} from "@mui/styles";
import {Box, Theme} from "@mui/material";
import {SxProps} from "@mui/system";
import React, {FC} from "react";
import ActionImg from "../shared/ActionImg";

type IconType = 'euro' | 'km' | 'm2' | '%'
type FieldIconProps = {
    type: IconType
}
const FieldIcon: FC<FieldIconProps> = ({type}: FieldIconProps) => {
    const theme = useTheme<Theme>()
    if (type === 'euro') {
        return (<ActionImg icon={'/icons/euro-simple.svg'}/>)
    }
    const EndAdornment = ({children, sx}: { children: JSX.Element, sx?: SxProps<Theme> }) =>
        <Box sx={{fontSize: '14px', color: theme.palette.text.secondary, ...(sx || {})}}>{children}</Box>

    return (
        <EndAdornment>
            {(type === 'm2') ? (<>&#x33A1;</>) : (<>{type}</>)}
        </EndAdornment>
    )
}
export default FieldIcon
