import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Box, Collapse, tableCellClasses, Theme, useTheme} from "@mui/material";
import {styled} from '@mui/material/styles';
import React from "react";
import TableRowVisibilityDetector from "../table/TableRowVisibilityDetector";

export type CollapsibleTableProps<ID> = {
    header: CollapsibleTableCellProps[];
    rows: CollapsibleTableRowProps<ID>[];
    open: ID | undefined;
    onRowClick: (id: ID) => void;
    onLastRowRendered?: () => void,
};

export type CollapsibleTableCellProps = {
    data: any;
    style?: any;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

export type CollapsibleTableRowProps<ID> = {
    id: ID;
    cells: CollapsibleTableCellProps[];
    details: () => CollapsibleTableCellProps;
}

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.root}`]: {
        color: theme.umzugEasy.palette.darkGray,
        fontSize: "14px",
        lineHeight: '40px',
        fontWeight: '400',
        padding: '5px',
        paddingLeft: '0px',
    },
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.text.secondary,
        lineHeight: '22px',
        fontStyle: "italic",
        paddingLeft: "0px",
    },
}));

const UmzugEasyCollapsibleTable = <ID extends unknown>(props: CollapsibleTableProps<ID>) => {
    const theme = useTheme<Theme>()

    return (
        <TableContainer component={Box}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow key={'header'}>
                        {props.header.map((header, headerIndex) => (
                            <StyledTableCell
                                key={headerIndex}
                                align={header.align}
                                style={header.style}>
                                {header.data}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rows.map((row, rowIndex) => (
                        <React.Fragment key={`fragment-${rowIndex}`}>
                            <TableRow
                                key={`row-${rowIndex}`}
                                sx={{cursor: 'pointer', borderBottom: 'none', borderTop: 'none'}}
                                onClick={() => props.onRowClick(row.id)}
                            >
                                {row.cells.map((cell, cellIndex) => (
                                    <StyledTableCell
                                        key={cellIndex}
                                        align={cell.align}
                                        style={cell.style}
                                        sx={{borderBottom: 'none'}}
                                    >
                                        {cell.data}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                            <TableRow
                                key={`details-${rowIndex}`}
                                sx={{borderTop: 'none', padding: '0px', backgroundColor: theme.palette.background.paper }}
                            >
                                <TableCell
                                    key={`details-cell-${rowIndex}`}
                                    colSpan={props.header.length}
                                    sx={{padding: '0px', paddingLeft: '11px'}}
                                >
                                    <Collapse in={props.open === row.id} timeout="auto">
                                        {(props.open === row.id) && row.details().data}
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                         </React.Fragment>
                    ))}
                    { props.onLastRowRendered && <TableRowVisibilityDetector onIntersection={props.onLastRowRendered}/> }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UmzugEasyCollapsibleTable;
