import {PartnerResource} from "api/partner/PartnerResource";
import React, {FC, useEffect, useState} from "react";
import {ContractResource} from "api/contract/ContractResourcePage";
import {PartnerSettingsResource} from "api/partnerSettings/PartnerSettingsResource";
import {getToggleHistory} from "api/toggleHistory/ToggleHistoryApi";
import {ToggleHistoryResource} from "api/toggleHistory/ToggleHistoryResource";
import {SelfPauseHistoryResource} from "api/selfPauseHistory/SelfPauseHistoryResource";
import {getSelfPauseHistory} from "api/selfPauseHistory/SelfPauseHistoryApi";
import {Skeleton} from "@mui/material";
import {debouncedPromise} from "utils/PromiseUtils";
import OnlineReason from "pages/partner/components/dashboard/onlineStatus/OnlineReason";
import OfflineReason from "pages/partner/components/dashboard/onlineStatus/OfflineReason";

type OnlineStatusLabelProps = {
    partner: PartnerResource,
    partnerSettings: PartnerSettingsResource,
    validContracts: ContractResource[],
    isPartnerOnline: boolean
}

const OnlineStatusReasonLabel: FC<OnlineStatusLabelProps> = ({
                                                                 partner,
                                                                 partnerSettings,
                                                                 validContracts,
                                                                 isPartnerOnline
                                                             }) => {
    const [toggleHistory, setToggleHistory] = useState<ToggleHistoryResource[]>([])
    const [selfPauseHistory, setSelfPauseHistory] = useState<SelfPauseHistoryResource[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const renderReason = () => {
        if (isPartnerOnline) {
            return <OnlineReason toggleHistory={toggleHistory} selfPauseHistory={selfPauseHistory}/>
        }
        return <OfflineReason partner={partner} partnerSettings={partnerSettings} validContracts={validContracts}
                              toggleHistory={toggleHistory} selfPauseHistory={selfPauseHistory}/>
    }

    useEffect(() => {
        setIsLoading(true)
        const toggleHistoryPromise = debouncedPromise(() => getToggleHistory(partner.cwid), 1000)
        const selfPauseHistoryPromise = getSelfPauseHistory(partner.cwid);

        toggleHistoryPromise.then(toggleHistory => {
            setToggleHistory(toggleHistory)
        })
        selfPauseHistoryPromise.then(selfPauseHistory => {
            setSelfPauseHistory(selfPauseHistory)
        })

        Promise.all([toggleHistoryPromise, selfPauseHistoryPromise]).then(() => setIsLoading(false))
    }, [partner.cwid, isPartnerOnline]);

    return isLoading ? <Skeleton>{renderReason()}</Skeleton> : renderReason()
}

export default OnlineStatusReasonLabel
