import {BookingTestDataRequest} from "./BookingTestDataRequest";
import {BookingTestDataResponse} from "./BookingTestDataResponse";
import {apiVersion, getApiClient} from "../ApiUtils";


const BOOKINGS_TEST_API_PREFIX = '/test/bookings'

export function bookingTestApi(request: BookingTestDataRequest): Promise<BookingTestDataResponse> {
    return getApiClient().then(client => client.post<BookingTestDataResponse>(
        BOOKINGS_TEST_API_PREFIX,
        request,
        {baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data);
}
