import {SxProps} from "@mui/system";
import {Theme, Typography} from "@mui/material";
import {useTheme} from "@mui/styles";
import React from "react";

export type LabelProps = {
    label: string | JSX.Element;
    dataTestid?: string;
    sx?: SxProps<Theme>
}

const Label = ({label, sx, dataTestid}: LabelProps) => {
    const theme = useTheme<Theme>()
    return (
        <Typography sx={{
            fontStyle: 'italic',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '40px',
            color: theme.palette.text.secondary,
            ...(sx || {})
        }} data-testid={dataTestid}>{label}</Typography>
    )
}
export default Label
