import React, {FC, ReactElement} from "react";
import {Box} from "@mui/material";
import {UndistributedRequestsContext} from "./undistributedRequests/UndistributedRequestsContext.model";
import {usePartnerContext} from "../shared/PartnerContext";
import {useInitUndistributedRequestsContext} from "./undistributedRequests/UndistributedRequestsContext";
import Alert from "../../../components/feedback/Alert";
import UndistributedRequestsSections from "./undistributedRequests/UndistributedRequestsSections";

const UndistributedRequests: FC<any> = (): ReactElement => {
    const {partner} = usePartnerContext()

    const ctx = useInitUndistributedRequestsContext(partner?.cwid)

    return (
        <Box sx={{width: '100%'}}>
            <UndistributedRequestsContext.Provider value={ctx}>
                <UndistributedRequestsSections/>
                <Alert text={''} {...ctx.alert}/>
            </UndistributedRequestsContext.Provider>
        </Box>
    );
};

export default UndistributedRequests;
