import React from "react";
import {Grid} from "@mui/material";
import LabeledField from "../../../../components/form/LabeledField";
import {useTranslation} from "react-i18next";
import {usePartnerContext} from "../../shared/PartnerContext";
import FormEditableTextField from "../../../../components/form/FormEditableTextField";

const CompanyData: React.FC = () => {
    const {t} = useTranslation('partner');
    const {partner, partnerSettings} = usePartnerContext()

    return (
        <Grid container>
            <Grid container item xs={12} md={6}>
                <LabeledField label={t('mainData.labels.companyName')} children={<>{partner?.name}</>}/>
                <LabeledField label={t('mainData.labels.additionalInformation')}>
                    <FormEditableTextField value={partnerSettings?.additionalInformation}
                                           formikName={'additionalInformation'} submitOnChange={true}/>
                </LabeledField>
                <LabeledField
                    label={t('mainData.labels.street')}
                    children={<>{partner?.billingAddress.street} {partner?.billingAddress.streetNumber}</>}
                />
                <LabeledField
                    label={t('mainData.labels.location')}
                    children={<>{partner?.billingAddress.countryCodeIso2} {partner?.billingAddress.postalCode} {partner?.billingAddress.city}</>}
                />
            </Grid>

            <Grid container item xs={12} md={6}>
                <LabeledField label={t('mainData.labels.salutation')} children={<>{partner?.contact?.salutation}</>}/>
                <LabeledField label={t('mainData.labels.firstName')} children={<>{partner?.contact?.firstName}</>}/>
                <LabeledField label={t('mainData.labels.lastName')} children={<>{partner?.contact?.lastName}</>}/>
                <LabeledField label={t('mainData.labels.phoneNumber')} children={<>{partner?.phone}</>}/>
            </Grid>
        </Grid>
    );
}

export default CompanyData;
