import {AlgotestPartner, AlgotestResponse} from "../../../../api/algotest/AlgotestResponse";
import {useEffect, useState} from "react";
import {AlgotestRelocationRequest} from "../../../../api/algotest/AlgotestRelocationRequest";
import {TableSchema} from "../SchemaModel";
import {UmzugEasyTableProps} from "../../UmzugEasyTable";
import {getUmzugEasyTableProps} from "../SchemaUtil";
import {leadGenerationTest} from "../../../../api/algotest/AlgotestApi";
import {LabelWithMarkRenderer} from "../../util/UmzugEasyCellRenderers";

export interface ResultsSummary {
    numberOfPartners: number,
    numberOfAcceptedPartners: number,
    queryExecutionTimeInMs: number
}
export const useAlgotestResponseLoader = () => {
    const [relocationRequest, setRelocationRequest] = useState<AlgotestRelocationRequest | undefined>(undefined)
    const [algotestPartners, setAlgotestPartners] = useState<AlgotestPartner[]>([])
    const [resultsSummary, setResultsSummary] = useState<ResultsSummary | undefined>()
    const [tableProps, setTableProps] = useState<UmzugEasyTableProps<AlgotestPartner>>(getAlgotestPartnerTableSchema());
    const [loading, setLoading] = useState(false)

    const getResultsSummary = (algotestResponse: AlgotestResponse): ResultsSummary => {
        return {
            numberOfPartners: algotestResponse.result.length,
            numberOfAcceptedPartners: algotestResponse.result.filter((partner) => !isRejected(partner)).length,
            queryExecutionTimeInMs: algotestResponse.duration
        }
    }

    useEffect(() => {
        if (relocationRequest != null) {
            setLoading(true)
            leadGenerationTest(relocationRequest)
                .then((algotestResponse) => {
                    setAlgotestPartners(algotestResponse.result)
                    setLoading(false)
                    setResultsSummary(getResultsSummary(algotestResponse))
                }).catch(() => {
                    setAlgotestPartners([])
                    setLoading(false)
                    setResultsSummary(undefined)
                })
        } else {
            setAlgotestPartners([])
            setResultsSummary(undefined)
        }
    }, [relocationRequest])

    useEffect(() => {
        setTableProps(getAlgotestPartnerTableSchema(algotestPartners))
    }, [algotestPartners])

    return {setRelocationRequest, tableProps, loading, resultsSummary}
}
const isRejected = (partner: AlgotestPartner) => partner.rejection !== 'NOT_REJECTED'
const camelCase = (text: string) => text.toLowerCase().replace(/_./g, x => x[1].toUpperCase())
const getTranslationKey = (prefix: string, value?: string) => value ? `${prefix}.${camelCase(value)}` : ''

const schema: TableSchema<AlgotestPartner>[] = [
    {
        key: 'partnerName',
        cellRenderer: (row) => LabelWithMarkRenderer(isRejected(row) ? 'red' : 'green', row.partnerName),
        order: 1
    },
    {key: 'customerId', value: (row) => row.customerId || '', order: 2},
    {key: 'billingAddressCountry', value: (row) => row.billingAddressCountry || '', order: 3},
    {key: 'price', value: (row) => `${row.price || ''}`, order: 4},
    {
        key: 'rejection',
        value: (row, t) => t(getTranslationKey('list.table.rejectionReason', row.rejection)),
        order: 5
    },
    {key: 'livingSpaceAreaSqmMin', value: (row) => `${row.livingSpaceAreaSqm?.min || ''}`, order: 6},
    {key: 'livingSpaceAreaSqmMax', value: (row) => `${row.livingSpaceAreaSqm?.max || ''}`, order: 7},
    {key: 'salesPerson', value: (row) => row.ownerDetails || '', order: 8},
]
export const getAlgotestPartnerTableSchema = (partners?: AlgotestPartner[]): UmzugEasyTableProps<AlgotestPartner> => {
    return {
        table: getUmzugEasyTableProps<AlgotestPartner>({
            schema: schema,
            rows: partners,
            translationPrefix: 'list.table.header',
            translationFile: 'algotest'
        }),
        noDataMessageKey: 'partner:list.table.noData',
    }
}
