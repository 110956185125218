import React, {FC, useEffect, useState} from "react";
import {useFormikContext} from "formik";
import {Box} from "@mui/material";
import BookingTypeIcon from "../../BookingTypeIcon";
import {TextField} from "../../../../../../components/form/TextField";
import ActionImg from "../../../../../../components/shared/ActionImg";
import {BookingType} from "../../../../../../api/booking/BookingResource";

const BookingModalTitle: FC = () => {
    const [showForm, setShowForm] = useState(false)

    const formik = useFormikContext();
    const nameFieldProps = formik.getFieldProps('name')
    const bookingTypeFieldProps = formik.getFieldProps('bookingType')

    const isEmpty = () => (nameFieldProps.value || '').length === 0;
    useEffect(() => {
        if (isEmpty()) {
            setShowForm(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameFieldProps.value, setShowForm])

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            '&:hover > .edit-icon': {display: 'block !important'}
        }}>
            <BookingTypeIcon type={bookingTypeFieldProps.value || BookingType.FILTER} size={'big'}/>
            <Box sx={{flexGrow: 1}}>
                {!showForm && (<>{nameFieldProps.value}</>)}
                {showForm && (
                    <TextField id="name"
                               sx={{width: '100%'}}
                               defaultValue={nameFieldProps.value}
                               onChange={formik?.handleChange}/>
                )}
            </Box>
            {!showForm && (
                <Box className={'edit-icon'} style={{display: 'none'}}>
                    <ActionImg icon={'/icons/pencil.svg'} iconStyle={{marginLeft: '25px'}}
                               onClick={(event) => {
                                   event.stopPropagation();
                                   setShowForm(true)
                               }}/>
                </Box>
            )}
            {showForm && (
                <Box>
                    <ActionImg icon={'/icons/mark.svg'} iconStyle={{marginLeft: '25px'}}
                               onClick={(event) => {
                                   event.stopPropagation();
                                   if (!isEmpty()) {
                                       setShowForm(false)
                                   }
                               }}/>
                </Box>
            )}
        </Box>
    )
}
export default BookingModalTitle