import React, {FC} from "react";
import {Box} from "@mui/material";
import TourList from "./TourList";
import Header from "./Header";
import TourEditPopover from "./popover/TourEditPopover";

const TourForm: FC = () => (
    <Box sx={{maxHeight: '70vh', display: 'flex', flexDirection: 'column'}}>
        <Header/>
        <TourList/>
        <TourEditPopover/>
    </Box>
)
export default TourForm