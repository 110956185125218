import {PartnerLeadsFilters} from "../../../../api/partner/PartnerResource";
import {AlertProps} from "../../../../components/feedback/Alert";
import React from "react";
import {Lead, PartnerLead} from "../../../../api/lead/LeadResources";
import {DeletionConfirmationModalProps} from "../../../../components/form/DeletionConfirmationModal";

export type RequestsActionsProps = {
    onOpenPartnerLeadDetails: (requestId: string) => void
    onLoadNextPage: () => void
    onFilterChange: (filter: PartnerLeadsFilters) => void
    onAnonymizeRelocationRequest: () => void,
}

export type RequestsContextProps = {
    partnerLeads: PartnerLead[]
    leadsByRequestId: Map<string, Lead[]>
    openLeadDetailsId: string | undefined
    loading: boolean
    page: number
    totalPages: number
    totalElements: number
    totalCancelled: number
    anonymizationConfirmationModal: DeletionConfirmationModalProps | undefined,
    isAnonymizingRelocationRequest: boolean,
    alert?: AlertProps
    actions: RequestsActionsProps
}

export const RequestContext = React.createContext<RequestsContextProps>({
    partnerLeads: [],
    leadsByRequestId: new Map(),
    openLeadDetailsId: undefined,
    loading: false,
    page: 0,
    totalPages: 0,
    totalElements: 0,
    totalCancelled: 0,
    anonymizationConfirmationModal: undefined,
    isAnonymizingRelocationRequest: false,
    actions: {
        onOpenPartnerLeadDetails: () => {},
        onLoadNextPage: () => {},
        onFilterChange: (filter: PartnerLeadsFilters) => {},
        onAnonymizeRelocationRequest: () => {},
    }
})