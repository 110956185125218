import {FC, ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import Section from "../../../../components/form/Section";
import UndistributedLeadsList from "./UndistributedLeadsList";

const sectionStyle = {
    container: {
        padding: "0px",
        paddingTop: "22px"
    }
}

const UndistributedRequestsSections: FC = (): ReactElement => {
    const {t} = useTranslation('undistributedRequests')

    return (
        <Box>
            <Section
                title={t('rejectedLeads.table.lastUndistributedRequests')}
                styles={sectionStyle}
            >
                <UndistributedLeadsList/>
            </Section>
        </Box>
    )
}

export default UndistributedRequestsSections;