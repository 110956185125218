import React, {FC, ReactElement, useEffect, useState} from "react";
import {Box} from "@mui/material";
import {DocumentsContext} from "./documents/DocumentsContext.model";
import {useInitDocumentsContext} from "./documents/DocumentsContext";
import {usePartnerContext} from "../shared/PartnerContext";
import StandardDocumentsList from "./documents/StandardDocumentsList";
import DocumentsDeletionConfirmationModal from "./documents/DocumentsDeletionConfirmationModal";
import Alert from "../../../components/feedback/Alert";
import OtherDocumentsList from "./documents/OtherDocumentsList";
import {SectionStyles} from "../../../components/form/Section";

export type DocumentsProps = {
    documentsColumnsWidth: DocumentsColumnsWidth,
    tableSectionStyles: SectionStyles,
};

export type DocumentsColumnsWidth = {
    available: string;
    document: string;
    filename: string;
    confirmedBy: string;
    expiryDate: string;
};

const Documents: FC = (): ReactElement => {
    const [partnerCwid, setPartnerCwid] = useState<string | undefined>(undefined)

    const {partner} = usePartnerContext()
    useEffect(() => setPartnerCwid(partner?.cwid), [partner?.cwid])

    const ctx = useInitDocumentsContext(partnerCwid)

    const documentsProps: DocumentsColumnsWidth = {
        available: '100px',
        document: '250px',
        filename: '250px',
        confirmedBy: '200px',
        expiryDate: '150px',
    }

    const tableSectionStyles: SectionStyles = {
        container: {
            padding: "0px",
            paddingTop: "22px"
        }
    }

    return (
        <Box sx={{width: '100%'}}>
            <DocumentsContext.Provider value={ctx}>
                <StandardDocumentsList documentsColumnsWidth={documentsProps} tableSectionStyles={tableSectionStyles}/>
                <OtherDocumentsList documentsColumnsWidth={documentsProps} tableSectionStyles={tableSectionStyles}/>
                <DocumentsDeletionConfirmationModal/>
                <Alert text={''} {...ctx.alert}/>
            </DocumentsContext.Provider>
        </Box>
    );
};

export default Documents;
