import React, {FC, useEffect, useState} from "react";
import {getCountryCodes, getPopularCountryCodes} from "../../../../../../../utils/CountryUtil";
import {useBookingContext} from "../../../BookingContext";
import {SelectFieldIcon, StyledMenuItem, StyledSelect} from "../../../../../../../components/form/SelectField";
import ActionImg from "../../../../../../../components/shared/ActionImg";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {Typography} from "@mui/material";
import {useBookingModalContext} from "../../BookingModalContext";
import {useTranslation} from "react-i18next";
import {useBookingPopoverContext} from "./BookingPopoverContext";

type SectionTitleProps = {
    children: string | JSX.Element,
    sx?: SxProps<Theme>,
    onClick?: () => void,
    isFirstScreen: boolean,
}
export const SectionTitle = ({children, sx, onClick, isFirstScreen}: SectionTitleProps) => (
    <>{
        isFirstScreen && (
            <Typography onClick={() => onClick && onClick()} sx={{
                padding: '8px 16px',
                fontWeight: 600,
                fontSize: '14px',
                fontStyle: 'normal',
                minHeight: '24px',
                ...(sx || {})
            }}><>{children}</>
            </Typography>
        )}
    </>
)
const RegionAndCountrySelect: FC = () => {
    const [isFirstScreen, setFirstScreen] = useState(true);
    const popularCountryCodes = getPopularCountryCodes()
    const countryCodes = getCountryCodes()
    const {regions} = useBookingContext()
    const {t} = useTranslation('bookings')
    const {editedTour} = useBookingModalContext()
    const {popoverActions} = useBookingPopoverContext()
    const [value, setValue] = useState('DE')

    useEffect(() => {
        const defaultValue = editedTour?.originalRegionOrPostalCodeToEdit.region?.regionId || editedTour?.originalRegionOrPostalCodeToEdit.postalCode?.country
        if (defaultValue != null) {
            const isRegionExistOnFirstScreen = (typeof defaultValue === 'number' && regions.find(item => item.id === defaultValue) != null);
            const isCountryExistInPopularCountries = (typeof defaultValue === 'string' && popularCountryCodes.find(item => item.code === defaultValue.toUpperCase()) != null);
            setFirstScreen(isRegionExistOnFirstScreen || isCountryExistInPopularCountries)
            const newValue = `${defaultValue || ''}`.toUpperCase();
            setValue(newValue)
            popoverActions.changeSelectedCountryOrRegion(newValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedTour?.originalRegionOrPostalCodeToEdit.region?.regionId, editedTour?.originalRegionOrPostalCodeToEdit.postalCode?.country])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => popoverActions.changeSelectedCountryOrRegion(value), [value])

    return (
        <StyledSelect
            sx={{minWidth: '120px'}}
            IconComponent={SelectFieldIcon}
            value={value}
            onOpen={() => {
                const shouldResetValue = !isFirstScreen;
                if (shouldResetValue) {
                    setValue('')
                }
                setFirstScreen(true)
            }}
            onChange={(change) => setValue(change.target.value)}
        >

            <SectionTitle isFirstScreen={isFirstScreen}>{t('booking.edit.popover.selectCountryOrRegion.countries')}</SectionTitle>
            {
                isFirstScreen && popularCountryCodes.map((item) =>
                    (<StyledMenuItem key={item.code} value={item.code}>{item.name}</StyledMenuItem>))
            }
            <SectionTitle isFirstScreen={isFirstScreen}>{t('booking.edit.popover.selectCountryOrRegion.regions')}</SectionTitle>
            {
                isFirstScreen && regions.map((item) =>
                    (<StyledMenuItem key={item.id} value={item.id}>{t(`booking.edit.regions.${item.name.toLowerCase()}`)}</StyledMenuItem>))
            }
            <SectionTitle isFirstScreen={isFirstScreen} sx={{cursor: 'pointer', display: 'flex', columnGap: '15px'}}
                          onClick={() => setFirstScreen(false)}><>{t('booking.edit.popover.selectCountryOrRegion.moreCountries')} <ActionImg icon={'/icons/chevron.svg'}
                                                                                            iconStyle={{transform: 'rotate(-90deg)'}}/></>
            </SectionTitle>
            {
                !isFirstScreen && countryCodes.map((item) =>
                    (<StyledMenuItem key={item.code} value={item.code}>{item.name}</StyledMenuItem>))
            }
        </StyledSelect>
    )
}
export default RegionAndCountrySelect