import React, {FC} from "react";
import BookingSection from "../BookingSection";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import FormInput from "../../../../../components/form/FormInput";
import FieldIcon from "../../../../../components/form/FieldIcon";
import Label from "../../../../../components/form/Label";
import {useFormikContext} from "formik";
import {BookingsSettingsForm} from "./BookingsSettings";

const GeneralSettings: FC = () => {
    const translationNameSpace = 'bookings'
    const {t} = useTranslation(translationNameSpace)
    const translateKeyPrefix = 'booking.bookingsSettings.general.inputs.'
    const textFieldMinWidth = '100px'
    const valueAlign = 'right'
    const formik = useFormikContext<BookingsSettingsForm>();
    const allFreeLeadsProps = formik.getFieldProps('freeLeadsReceived')

    return (
        <BookingSection title={t('booking.bookingsSettings.general.title')} isModalSection={false} >
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'min-content min-content',
                justifyItems: 'end',
                columnGap: '140px',
                marginLeft: '48px',
                paddingTop: '16px'
            }}>
                <FormInput translateKey={`${translateKeyPrefix}freeLeadsLimit`}
                           translationNameSpace={translationNameSpace}
                           formField={'freeLeadsLimit'} width={'320px'}
                           minFormWidth={textFieldMinWidth}
                           type={'number'}
                           onBlur={() => formik.submitForm()}
                           valueAlign={valueAlign}
                           endAdornment={<></>}/>
                <FormInput translateKey={`${translateKeyPrefix}minRelocationDelay`}
                           translationNameSpace={translationNameSpace}
                           formField={'minRelocationDelay'} width={'370px'}
                           minFormWidth={textFieldMinWidth}
                           type={'number'}
                           onBlur={() => formik.submitForm()}
                           valueAlign={valueAlign}
                           endAdornment={<></>}/>
                <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                    <Label label={t(`${translateKeyPrefix}freeLeadsReceived`)}
                           sx={{
                               margin: '5px 0',
                               textAlign: 'right'
                           }}/>
                    <Label label={allFreeLeadsProps.value} sx={{
                        margin: '5px 0',
                        textAlign: 'right',
                        minWidth: '120px',
                        paddingRight: '34px'
                    }}/>
                </Box>
                <FormInput translateKey={`${translateKeyPrefix}maxRelocationDelay`}
                           translationNameSpace={translationNameSpace}
                           formField={'maxRelocationDelay'} width={'370px'}
                           minFormWidth={textFieldMinWidth}
                           type={'number'}
                           onBlur={() => formik.submitForm()}
                           valueAlign={valueAlign}
                           endAdornment={<></>}/>
                <FormInput translateKey={`${translateKeyPrefix}leadThrottling`}
                           translationNameSpace={translationNameSpace}
                           formField={'leadThrottling'} width={'320px'}
                           minFormWidth={textFieldMinWidth}
                           type={'number'}
                           onBlur={() => formik.submitForm()}
                           valueAlign={valueAlign}
                           endAdornment={<FieldIcon type={'%'}/>}/>
            </Box>
        </BookingSection>
    )
}

export default GeneralSettings
