import {
    Leads,
    PartnerLeadsPage, PartnerLeadsStatistics,
    LeadGenerationResultsPage,
    LeadGenerationResultType
} from "./LeadResources";
import {Page} from "../Page";
import {PartnerLeadsFilters} from "../partner/PartnerResource";
import {apiVersion, getApiClient} from "../ApiUtils";

export function getLeads(requestId: string): Promise<Leads> {
    return getApiClient().then(client => client.get<Leads>(
        `/leads/${requestId}`,
        {baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data);
}

export function getPartnerLeads(cwid: string, page: number = 0, size: number = 20, filters: PartnerLeadsFilters): Promise<Page<PartnerLeadsPage>> {
    return getApiClient().then(client => client.get<Page<PartnerLeadsPage>>(
        `/partners/${cwid}/leads?page=${page}&size=${size}&start=${filters.start.toISOString()}&end=${filters.end.toISOString()}`,
        {baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data);
}

export function getPartnerLeadsStatistics(cwid: string, filters: PartnerLeadsFilters): Promise<PartnerLeadsStatistics> {
    return getApiClient().then(client => client.get<PartnerLeadsStatistics>(
        `/partners/${cwid}/leads/statistics?start=${filters.start.toISOString()}&end=${filters.end.toISOString()}`,
        {baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data);
}

export function getLeadGenerationResults(partnerCwid: string, type: LeadGenerationResultType, page: number = 0, size: number = 20): Promise<Page<LeadGenerationResultsPage>> {
    return getApiClient().then(client => client.get<Page<LeadGenerationResultsPage>>(
        `/partners/${partnerCwid}/leads/generation/results/${type}?page=${page}&size=${size}`,
        {baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data);
}
