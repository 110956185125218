import {FC, ReactElement} from "react";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {PartnerRoutes} from "routes/Routes";
import {formatToLocaleTextWithDate} from "utils/DateUtil";
import {formatNumberToEuroCurrency} from "utils/CurrencyUtil";
import {CancellationStatus, Lead} from "api/lead/LeadResources";

export type RelocationFromSubsectionProps = {
    leads: Lead[]
}
const fromToSectionKeyOf = (postfix: string): string => `requestDetails.detailsTab.sections.leads.${postfix}`;

const linkToPartner = (lead: Lead): ReactElement => (
    <Link to={PartnerRoutes.MAIN_DATA.replace(":cwid", lead.partner.cwid)}>
        {lead.partner.scoutId} / {lead.partner.name}
    </Link>);

const RelocationLeadsSubsection: FC<RelocationFromSubsectionProps> = ({leads}: RelocationFromSubsectionProps): ReactElement => {
    const {t} = useTranslation('relocationRequests')
    const displayStatus = (lead: Lead): string => {
        const status =
            lead.cancellationStatus === CancellationStatus.CANCELLED ? t(fromToSectionKeyOf('canceled'))
                : lead.cancellationStatus === CancellationStatus.REJECTED ? t(fromToSectionKeyOf('rejected'))
                    : '';
        return `${status} ${formatToLocaleTextWithDate(lead.cancellationClaimDate)}`
    }

    const displayLeads = leads && leads.map(lead => (
            <Box key={lead.partner.cwid}>
                {linkToPartner(lead)} ({formatNumberToEuroCurrency(lead.price)})
                {(lead.cancellationStatus === CancellationStatus.CANCELLED || lead.cancellationStatus === CancellationStatus.REJECTED) && ` - ${displayStatus(lead)}`}
            </Box>

    ));

    return (
        <Box>
            {leads && leads.length > 0 ? (
                <>
                    <Box>{t(fromToSectionKeyOf('information'))}</Box>
                    {displayLeads}
                </>
            ) : (
                <Box>{t(fromToSectionKeyOf('noLeads'))}</Box>
            )}
        </Box>
    );
}

export default RelocationLeadsSubsection
