import React from 'react';
import {Box} from '@mui/material';
import {useTranslation} from "react-i18next";
import BookingSection from "../../../../partner/components/bookings/BookingSection";
import ResultData from "./ResultData";

interface BookingTestSummary {
    numberOfRequests: number;
    numberOfUndistributedRequests: number;
    numberOfUniquePartners: number;
    averageLeadsPerRequest: number;
}

type BookingTestSummaryProps = {
    summary: BookingTestSummary;
};

const ResultSettings: React.FC<BookingTestSummaryProps> = ({summary}) => {
    const {t} = useTranslation('bookingTest');

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            maxWidth: '1021px',
            padding: '0 1rem',
        }}>
            <BookingSection title={t('result.title')} isModalSection={false}>
                <Box>
                    <ResultData summary={summary}/>
                </Box>
            </BookingSection>
        </Box>
    );
};

export default ResultSettings;
