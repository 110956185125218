import React, {FC, ReactElement, useState} from "react";
import {Box, Skeleton} from "@mui/material";
import {PhotoInventory, RelocationRequestInventory} from "api/relocationRequest/RelocationRequestDetail";
import {useTranslation} from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';

type PhotoInventoryDetailsProps = {
    inventory?: RelocationRequestInventory
    isLoading?: boolean
}
type PhotoPreviewProps = {
    photo?: PhotoInventory,
    close: () => void
}

const PhotoPreview: FC<PhotoPreviewProps> = ({photo, close}) => {
    if (photo == null) {
        return <></>
    }
    return (
        <Box onClick={close}
             sx={{
                 position: "fixed",
                 left: 0,
                 top: 0,
                 height: '100vh',
                 width: '100vw',
                 zIndex: '9000',
                 background: 'whitesmoke',
                 '& > img': {
                     width: '100%',
                     height: '100%',
                     objectFit: 'contain',
                     cursor: 'pointer'
                 },
                 '& > svg': {
                     position: 'absolute',
                     right: '25px',
                     top: '15px',
                     cursor: 'pointer'
                 }
             }}>
            <CloseIcon data-testid={'photo-inventory-preview-close'}/>
            <img data-testid={'photo-inventory-preview-img'} src={photo.uri} alt={photo.title}/>
        </Box>
    )

}

const PhotoInventoryDetailsSkeleton: FC = (): ReactElement => {
    return (
        <>
            <Skeleton data-testid={'photo-inventory-skeleton-title'} variant="text" width="100%" height={33} sx={{marginTop: '12px'}}/>
            <Skeleton data-testid={'photo-inventory-skeleton-comment'} variant="text" width="100%" height={128} sx={{height: '128px'}}/>
            <Box sx={{
                display: "flex",
                justifyContent: "start",
                flexWrap: "wrap",
                columnGap: "24px",
                alignItems: "start",
                marginBottom: '64px'
            }}>
                {
                    Array.from(Array(4)).map((_, index) => index)
                        .map((index) => <Skeleton data-testid={`photo-inventory-skeleton-img-${index}`} key={index} variant="text" sx={{
                            width: '255px',
                            height: '180px',
                            transform: 'unset'
                        }}/>)
                }
            </Box>
        </>
    )
}

const PhotoInventoryDetails: FC<PhotoInventoryDetailsProps> = ({inventory, isLoading}): ReactElement => {
    const {t} = useTranslation('inventory')
    const [previewImg, setPreviewImg] = useState<PhotoInventory | undefined>(undefined)
    if (isLoading) {
        return (
            <PhotoInventoryDetailsSkeleton/>
        )
    }
    if (inventory == null) {
        return <></>
    }
    return (
        <>
            <Box
            data-testid={'photo-inventory-details-container'}
                sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                fontSize: "14px",
                '& > div': {
                    padding: "5px",
                },
                '& > div:first-child': {
                    width: "50%",
                    fontWeight: 600
                }
            }}
            >
                <div>{t('photoInventory')}</div>
                <div>{inventory.photoInventoryListComment}</div>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "start",
                        fontSize: "14px",
                        flexWrap: "wrap",
                        '& > img': {
                            padding: "5px",
                            width: '225px',
                            height: '225px',
                            objectFit: 'cover',
                            cursor: 'pointer'
                        }
                    }}
                >
                    {inventory.photoInventory?.map((photo, index) => (
                        <img data-testid={`photo-inventory-${index + 1}`} src={photo.uri} alt={photo.title} key={photo.title}
                             onClick={() => setPreviewImg(photo)}/>))
                    }
                </Box>
            </Box>
            <PhotoPreview photo={previewImg} close={() => setPreviewImg(undefined)}/>
        </>
    );
};

export default PhotoInventoryDetails;