import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getCountryCodes, getPopularCountryCodes} from "../../../../../utils/CountryUtil";
import {useBookingContext} from "../../../../partner/components/bookings/BookingContext";
import {SelectFieldIcon, StyledMenuItem, StyledSelect} from "../../../../../components/form/SelectField";
import ActionImg from "../../../../../components/shared/ActionImg";
import {useBookingTestContext} from "./tours/BookingTestContext";
import {useBookingTestPopoverContext} from "./tours/popover/BookingTestPopoverContext";
import {SectionTitle} from "../../../../partner/components/bookings/modal/tours/popover/RegionAndCountrySelect";

const RegionAndCountryTestSelect: FC = () => {
    const [isFirstScreen, setFirstScreen] = useState(true);
    const popularCountryCodes = getPopularCountryCodes()
    const countryCodes = getCountryCodes()
    const {regions} = useBookingContext()
    const {t} = useTranslation('bookingTest')
    const {editedTour} = useBookingTestContext()
    const {popoverTestActions} = useBookingTestPopoverContext()
    const [value, setValue] = useState('DE')

    useEffect(() => {
        const defaultValue = editedTour?.originalRegionOrPostalCodeToEdit.region?.regionId || editedTour?.originalRegionOrPostalCodeToEdit.postalCode?.country
        if (defaultValue != null) {
            const isRegionOnFirstScreen = (typeof defaultValue === 'number' && regions.find(item => item.id === defaultValue) != null);
            const isCountryInPopularCountries = (typeof defaultValue === 'string' && popularCountryCodes.find(item => item.code === defaultValue.toUpperCase()) != null);
            setFirstScreen(isRegionOnFirstScreen || isCountryInPopularCountries)
            const newValue = `${defaultValue || ''}`.toUpperCase();
            setValue(newValue)
            popoverTestActions.changeSelectedCountryOrRegion(newValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedTour?.originalRegionOrPostalCodeToEdit.region?.regionId, editedTour?.originalRegionOrPostalCodeToEdit.postalCode?.country])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => popoverTestActions.changeSelectedCountryOrRegion(value), [value])

    return (
        <StyledSelect
            sx={{minWidth: '120px'}}
            IconComponent={SelectFieldIcon}
            value={value}
            onOpen={() => {
                const shouldResetValue = !isFirstScreen;
                if (shouldResetValue) {
                    setValue('')
                }
                setFirstScreen(true)
            }}
            onChange={(change) => setValue(change.target.value)}
        >
            <SectionTitle
                isFirstScreen={isFirstScreen}>{t('form.popover.selectCountryOrRegion.countries')}</SectionTitle>
            {
                isFirstScreen && popularCountryCodes.map((item) =>
                    (<StyledMenuItem key={item.code} value={item.code}>{item.name}</StyledMenuItem>))
            }
            <SectionTitle
                isFirstScreen={isFirstScreen}>{t('form.popover.selectCountryOrRegion.regions')}</SectionTitle>
            {
                isFirstScreen && regions.map((item) =>
                    (<StyledMenuItem key={item.id}
                                     value={item.id}>{t(`form.regions.${item.name.toLowerCase()}`)}</StyledMenuItem>))
            }
            <SectionTitle isFirstScreen={isFirstScreen} sx={{cursor: 'pointer', display: 'flex', columnGap: '15px'}}
                          onClick={() => setFirstScreen(false)}><>{t('form.popover.selectCountryOrRegion.moreCountries')}
                <ActionImg icon={'/icons/chevron.svg'}
                           iconStyle={{transform: 'rotate(-90deg)'}}/></>
            </SectionTitle>
            {
                !isFirstScreen && countryCodes.map((item) =>
                    (<StyledMenuItem key={item.code} value={item.code}>{item.name}</StyledMenuItem>))
            }
        </StyledSelect>
    )
}
export default RegionAndCountryTestSelect
