import {Trans, useTranslation} from "react-i18next";
import React from "react";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";

export type UmzugEasyTableFooterProps = {
    count: number;
    translateFile: string,
    search?: string;
    translation: {
        withSearch: string;
        withoutSearch: string;
    }

}

const useDescriptionStyles = makeStyles((theme: Theme) => ({
    title: {
        fontSize: "14px",
        paddingLeft: "1rem",
        fontWeight: "400",
        height: "40px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        whiteSpace: "pre-wrap",
        color: theme.palette.common.black,
    }
}));

const UmzugEasyTableFooter = (props: UmzugEasyTableFooterProps): JSX.Element => {
    const classes = useDescriptionStyles();
    const {t} = useTranslation(props.translateFile);
    const isFilterActive = (props.search?.length || 0) > 0;
    const {withSearch, withoutSearch} = props.translation;
    return (
        <div className={classes.title}>
            <Trans
                t={t}
                i18nKey={(isFilterActive) ? withSearch : withoutSearch}
                values={{count: props.count, search: props.search}}
                components={{bold: <strong/>}}
            />
        </div>
    )
}

export default UmzugEasyTableFooter
