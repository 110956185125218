import {Route, useRoutePath} from "../../../routes/Routes";
import {Link, MenuItem, menuItemClasses, styled, Theme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/styles";
import {NavLink} from "react-router-dom";
import React from "react";
import UpperCaseHeader from "../../shared/UpperCaseHeader";

export enum MenuType {
    ROOT = 'ROOT',
    SUBMENU = 'SUBMENU'
}

export type NavigationContentProps = {
    menuType: MenuType,
    activeRoute: Route | undefined,
    routeList: Route[],
    children?: JSX.Element,
    isSubMenuInContent?: boolean,
    subMenuPathValue?: {[key: string]: string},
}

export type NavigationLinkProps = NavigationContentProps & {
    page: Route,
    isMobile: boolean,
    onMenuItemClicked?: () => void,
    isContentItem?: boolean
}

const StyledMenuItem = styled(MenuItem)(({theme}) => ({
    [`&.${menuItemClasses.root}`]: {
        marginBottom: "4px",
    },
    [`&.${menuItemClasses.root}.is-content`]: {
        color: theme.palette.primary.dark,
        paddingLeft: '2px',
        paddingRight: '2px',
        marginRight: '40px'
    },
    [`&.${menuItemClasses.root}.is-content .menu-in-content-item`]: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '19px',
        color: theme.palette.primary.light
    },
    [`&.${menuItemClasses.selected}.is-content  .menu-in-content-item`]: {
        color: theme.palette.secondary.light
    },
    [`&.${menuItemClasses.selected}`]: {
        backgroundColor: "transparent",
        borderBottom: `4px solid ${theme.palette.warning.main}`,
        color: theme.palette.warning.main,
        marginBottom: "0"
    },
    [`&.${menuItemClasses.selected}.is-content`]: {
        borderBottom: `4px solid ${theme.palette.secondary.light}`,
        color: theme.palette.secondary.light
    },
    [`&.${menuItemClasses.root}.is-content:hover`]: {
        backgroundColor: "transparent",
    },
    [`&.submenu`]: {
        opacity: ".6"
    },
    [`&.submenu.is-mobile`]: {
        opacity: ".6",
        paddingLeft: "50px"
    },
    [`&.submenu-selected`]: {
        color: theme.palette.common.white,
        borderBottom: `4px solid ${theme.palette.common.white}`,
        opacity: "1"
    }
}))

const NavigationMenuItem = (props: NavigationLinkProps) => {
    const {t} = useTranslation('common');
    const mobileView = props.isMobile ? 'is-mobile' : 'web-view'
    const isContent = props.isContentItem ? 'is-content' : 'is-navbar'
    return (
        <StyledMenuItem
            classes={{
                selected: `${props.menuType.toLowerCase()}-selected`,
                root: `${props.menuType.toLowerCase()} ${mobileView} ${isContent}`
            }}
            onClick={() => props.onMenuItemClicked && props.onMenuItemClicked()}
            disabled={!props.page.enabled}
            selected={props.activeRoute?.path === props.page.path}
        >
            {!props.isContentItem && <UpperCaseHeader title={t(props.page.title)} fontSize={'small'} weight={'bold'}/>}
            {props.isContentItem && <UpperCaseHeader className={'menu-in-content-item'} title={t(props.page.title)} fontSize={'small'} weight={'normal'}/>}
        </StyledMenuItem>
    )
}

const NavigationLink = (props: NavigationLinkProps) => {
    const path = useRoutePath(props.page, props.subMenuPathValue)
    const theme = useTheme<Theme>()
    if (!props.page.enabled) {
        return (<NavigationMenuItem {...props}/>)
    }
    return (
        <Link
            component={NavLink}
            to={path}
            color={theme.palette.common.white}
            underline="none"
            variant="button"
        >
            <NavigationMenuItem {...props}/>
        </Link>
    )
}
export default NavigationLink