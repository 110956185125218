import {LeadGenerationResult} from "../../../../api/lead/LeadResources";
import React from "react";
import {AlertProps} from "../../../../components/feedback/Alert";
import {PageDetails} from "../../../../api/Page";

export type UndistributedRequestsActionsProps = {
    onLoadNextPage: () => void
}

export type UndistributedRequestsContextProps = {
    rejectedLeads: LeadGenerationResult[]
    pageDetails: PageDetails
    loading: boolean
    alert?: AlertProps
    actions: UndistributedRequestsActionsProps
}

export const initPageDetails: PageDetails = {size: 0, totalElements: 0, totalPages: 0, number: 0}

export const UndistributedRequestsContext = React.createContext<UndistributedRequestsContextProps>({
    rejectedLeads: [],
    pageDetails: initPageDetails,
    loading: false,
    actions: {
        onLoadNextPage: () => {},
    },
})