import React from "react";
import {Box, Grid, styled, Tooltip} from "@mui/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

export type ActionIconProps = {
    IconElement: IconType;
    sx?: SxProps<Theme>
    onClick?: () => void;
    tooltip?: string;
    justifyContent?: 'flex-end';
    color?: string;
}

type IconProps = React.SVGProps<
    SVGSVGElement
> & { title?: string };
export type IconType = React.FunctionComponent<IconProps>;

type IconWrapperProps = {
    fill?: string
}

const IconWrapper = styled(Box)<IconWrapperProps>(({theme, fill}) => ({
    [`&`]: {
        display: "flex",
        cursor: "pointer"
    },
    [`& path`]: {
        fill: fill || theme.umzugEasy.palette.darkGray,
    }
}));

const ActionIcon: React.FC<ActionIconProps> = ({
                                                   IconElement,
                                                   onClick,
                                                   tooltip,
                                                   sx,
                                                   color,
                                                   justifyContent
                                               }: ActionIconProps) => {
    const displayedIcon: JSX.Element = <IconWrapper fill={color} sx={sx}><IconElement/></IconWrapper>

    return (
        <Grid item display='flex' onClick={onClick} alignItems='center' justifyContent={justifyContent}>
            {tooltip ? <Tooltip title={tooltip}>{displayedIcon}</Tooltip> : displayedIcon}
        </Grid>
    );
}

export default ActionIcon;
