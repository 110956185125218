import React, {FC, useState} from "react";
import {ScheduledPartnerPauseResource} from "api/scheduledPartnerPause/ScheduledPartnerPauseResource";
import UmzugEasyBasicTable, {TableDataProps} from "../../../../../components/basicTable/UmzugEasyBasicTable";
import {useTranslation} from "react-i18next";
import ScheduledPauseTableRow from "./ScheduledPauseTableRow";
import {formatToLocaleTextWithDateTime} from "utils/DateUtil";
import {deleteScheduledPartnerPause} from "api/scheduledPartnerPause/ScheduledPartnerPauseApi";
import LabelWithColor from "./LabelWithColor";

type ScheduledPauseTableProps = {
    scheduledPauses: ScheduledPartnerPauseResource[] | []
    setScheduledPauses: React.Dispatch<React.SetStateAction<ScheduledPartnerPauseResource[]>>
    showAlert: Function
}
const ScheduledPauseTable: FC<ScheduledPauseTableProps> = ({scheduledPauses, setScheduledPauses, showAlert}) => {
    const {t} = useTranslation('dashboard')
    const [isDeleting, setDeleting] = useState<boolean>(false)

    const headerOf = (key?: string, style?: {}): TableDataProps => ({
        data: key ? t(`pause.schedule.header.${key}`) : '',
        align: 'left',
        style: style
    });

    const header = [
        headerOf('date', {width: '175px'}),
        headerOf('entry'),
        headerOf()
    ];

    const cellPropsOf = (data: any, style?: any, align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'): TableDataProps => ({
        data,
        style,
        align
    });

    const onRemove = (scheduledPause: ScheduledPartnerPauseResource) => {
        setDeleting(true)
        deleteScheduledPartnerPause(scheduledPause.partnerCwid, scheduledPause.id)
            .then(() => {
                const scheduledPausesAfterRemoval =
                    scheduledPauses.filter(pause => pause.id !== scheduledPause.id)
                setScheduledPauses(scheduledPausesAfterRemoval)
            })
            .catch(() => {
                showAlert('deleteScheduledPartnerPause');
            }).finally(() => setDeleting(false))
    }

    const formatDate: (dateString: string) => string = (dateString: string) => {
        const date = formatToLocaleTextWithDateTime(dateString)
        return date.split(" ")[0]
    }
    const rowOf = (scheduledPause: ScheduledPartnerPauseResource): TableDataProps[] => [
        cellPropsOf(LabelWithColor(scheduledPause.isOnline ? 'green' : 'red', formatDate(scheduledPause.changeAt)), {
            position: 'relative',
            paddingLeft: '15px'
        }),
        cellPropsOf(scheduledPause.scheduledBy + ' / ' + formatDate(scheduledPause.createdAt)),
        cellPropsOf(<ScheduledPauseTableRow isDeleting={isDeleting} scheduledPause={scheduledPause}
                                            onRemove={onRemove}/>)
    ];

    const rows = scheduledPauses.filter(scheduledPause => {
        return !scheduledPause.isDone
    }).map(scheduledPause => {
        return rowOf(scheduledPause);
    });

    return (
        <UmzugEasyBasicTable
            header={header}
            rows={rows}
        />
    )
}

export default ScheduledPauseTable