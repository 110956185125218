import React, {FC} from "react";
import BookingSection from "../BookingSection";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import FormInput from "../../../../../components/form/FormInput";
import FieldIcon from "../../../../../components/form/FieldIcon";
import {useFormikContext} from "formik";
import {PriceGroupType} from "api/booking/BookingResource";
import {BookingsSettings} from "api/bookingsSettings/BookingsSettingsResource";

export type ShopSettingsProps = {
    bookingsSettings: BookingsSettings | undefined;
}

const ShopSettings: FC<ShopSettingsProps> = ({bookingsSettings}: ShopSettingsProps) => {
    const translationNameSpace = 'bookings'
    const {t} = useTranslation(translationNameSpace)
    const translateKeyPrefix = 'booking.bookingsSettings.shop.inputs.'
    const textFieldMinWidth = '100px'
    const euroIcon = <FieldIcon type={'euro'}/>
    const formik = useFormikContext()
    const priceGroupType = bookingsSettings?.shopPricesByApartmentArea.priceGroupType

    return (
        <BookingSection title={t('booking.bookingsSettings.shop.title')} isModalSection={false} >
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'min-content min-content',
                justifyItems: 'end',
                columnGap: '140px',
                marginLeft: '48px',
                paddingTop: '16px'
            }}>
                <FormInput translateKey={`${translateKeyPrefix}${priceGroupType?.toLowerCase()}.priceGroup1`}
                           translationNameSpace={translationNameSpace}
                           formField={'priceGroup1'} width={'320px'}
                           minFormWidth={textFieldMinWidth}
                           type={'number'}
                           onBlur={() => formik.submitForm()}
                           endAdornment={euroIcon}/>
                { priceGroupType === PriceGroupType.STANDARD &&
                    <FormInput translateKey={`${translateKeyPrefix}${priceGroupType?.toLowerCase()}.priceGroup3`}
                           translationNameSpace={translationNameSpace}
                           formField={'priceGroup3'} width={'370px'}
                           minFormWidth={textFieldMinWidth}
                           type={'number'}
                           onBlur={() => formik.submitForm()}
                           endAdornment={euroIcon}/>
                }
                <FormInput translateKey={`${translateKeyPrefix}${priceGroupType?.toLowerCase()}.priceGroup2`}
                           translationNameSpace={translationNameSpace}
                           formField={'priceGroup2'} width={'320px'}
                           minFormWidth={textFieldMinWidth}
                           type={'number'}
                           onBlur={() => formik.submitForm()}
                           endAdornment={euroIcon}/>
                <FormInput translateKey={`${translateKeyPrefix}${priceGroupType?.toLowerCase()}.priceGroupOther`}
                           translationNameSpace={translationNameSpace}
                           formField={'priceGroupOther'} width={'370px'}
                           minFormWidth={textFieldMinWidth}
                           type={'number'}
                           onBlur={() => formik.submitForm()}
                           endAdornment={euroIcon}/>
            </Box>
        </BookingSection>
    )
}

export default ShopSettings
