import {DependencyList, useEffect} from "react";

const DEFAULT_DELAY = 1000;
const useDebounceTime = (callback: () => void, deps?: DependencyList, delay: number = DEFAULT_DELAY) => {
    useEffect(() => {
        const timeOutId = setTimeout(() => callback(), delay);
        return () => clearTimeout(timeOutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)
}
export default useDebounceTime