import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import {TableFooter} from "@mui/material";
import {StyledTableCell, StyledTableRow} from "components/reportTable/ReportTableComponents";

export type AggregationSettings = {
    aggregationRowLabel: string;
    aggregationFunction: (rows: ReportTableRowValues[]) => ReportTableRowValues;
}

export type ReportTableRowValues = Array<number>

export type ReportTableRow = {
    id: string | number;
    values: ReportTableRowValues;
}

export type Formatter = (value: any) => string

export type ReportTableProps = {
    headers: string[];
    rows: ReportTableRow[];
    formatters?: Array<Formatter>;
    aggregationSettings?: AggregationSettings
}

const defaultFormatter: Formatter = (value: number): string => {
    if (isNaN(value)) {
        return '-'
    }
    return value.toFixed(2)
}

const ReportTable = ({headers, rows, formatters, aggregationSettings}: ReportTableProps) => {

    const getAggregatedRowValues = (): ReportTableRowValues => {
        if (!aggregationSettings) return [];
        return aggregationSettings.aggregationFunction(rows.map(r => r.values))
    };

    const aggregatedRowValues = aggregationSettings ? getAggregatedRowValues() : null;

    const formatterOf = (columnIndex: number): Formatter => {
        if (formatters) {
            return formatters[columnIndex];
        }
        return defaultFormatter
    }

    const textAlignOf = (columnIndex: number) => columnIndex > 0 ? "right" : "left"

    return (
        <Table>
            <TableHead>
                <TableRow>
                    {headers.map(header => <StyledTableCell sx={{ height: "12px" }}>{header}</StyledTableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    rows.map(row =>
                        <StyledTableRow>
                            <StyledTableCell>{row.id}</StyledTableCell>
                            {
                                row.values.map((value, columnIndex: number) =>
                                    <StyledTableCell sx={{textAlign: textAlignOf(columnIndex + 1)}} key={columnIndex}>
                                        {formatterOf(columnIndex)(value)}
                                    </StyledTableCell>)
                            }
                        </StyledTableRow>
                    )
                }
            </TableBody>
            {aggregatedRowValues && (
                <TableFooter>
                    <TableRow>
                        <StyledTableCell sx={{ fontWeight: "bold" }}>{aggregationSettings?.aggregationRowLabel}</StyledTableCell>
                        {aggregatedRowValues.map((value, columnIndex) => (
                            <StyledTableCell key={columnIndex} sx={{textAlign: textAlignOf(columnIndex + 1)}}>
                                {formatterOf(columnIndex)(value)}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableFooter>
            )}
        </Table>
    )
}

export default ReportTable
