import NavigationLink, {NavigationContentProps} from "./NavigationLink";
import {Box} from "@mui/material";
import React, {FC, ReactElement} from "react";

const NavigationContent: FC<NavigationContentProps> = (props: NavigationContentProps): ReactElement => {
    return (
        <Box sx={{
            display: 'flex',
            marginLeft: props.menuType === 'SUBMENU' && !props.isSubMenuInContent ? '106px' : "0",
        }}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "end",
                    marginLeft: props.isSubMenuInContent ? "0px" : "24px",
                }}
            >
                {props.routeList.map((page) => (
                    <NavigationLink {...props} page={page} key={page.key} isMobile={false} isContentItem={props.isSubMenuInContent}/>
                ))}
            </Box>
        </Box>
    )
}
export default NavigationContent