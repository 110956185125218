import {Checkbox as MuiCheckbox, checkboxClasses, styled, svgIconClasses} from "@mui/material";
import {CheckboxProps as MuiCheckboxProps} from "@mui/material/Checkbox/Checkbox";

export type CheckboxProps = MuiCheckboxProps
const StyledCheckbox = styled(MuiCheckbox)(({theme}) => ({
    [`&.${checkboxClasses.root}`]: {
        color: theme.palette.warning.main,
    },
    [`&.${checkboxClasses.root} .${svgIconClasses.root}`]: {
        width: '20px',
        height: '20px',
    },
}));

const Checkbox = (props: CheckboxProps) => {
    return (<StyledCheckbox {...props}></StyledCheckbox>)
}
export default Checkbox;
