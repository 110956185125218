import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Box, tableCellClasses, Theme} from "@mui/material";
import {styled} from '@mui/material/styles';
import TableRowVisibilityDetector from "../table/TableRowVisibilityDetector";
import React from "react";
import {SxProps} from "@mui/system";

type TableProps = {
    header: TableDataProps[];
    rows: TableDataProps[][];
    onLastRowRendered?: () => void,
    sx?: SxProps<Theme>
};

export type TableDataProps = {
    data: any;
    style?: any;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.root}`]: {
        color: theme.umzugEasy.palette.darkGray,
        fontSize: "14px",
        lineHeight: '40px',
        fontWeight: '400',
        padding: '5px'
    },
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.text.secondary,
        lineHeight: '22px',
        fontStyle: "italic",
    },
}));

const UmzugEasyBasicTable = (props: TableProps) => {

    return (
        <TableContainer component={Box}>
            <Table aria-label="simple table" sx={props.sx}>
                <TableHead>
                    <TableRow>
                        {props.header.map((header, headerIndex) => (
                            <StyledTableCell
                                key={headerIndex}
                                align={header.align}
                                style={header.style}>
                                {header.data}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rows.map((row, rowIndex) => (
                        <TableRow
                            key={rowIndex}
                        >
                            {row.map((cell, cellIndex) => (
                                <StyledTableCell
                                    key={cellIndex}
                                    align={cell.align}
                                    style={cell.style}>
                                    {cell.data}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    ))}
                    { props.onLastRowRendered && <TableRowVisibilityDetector onIntersection={props.onLastRowRendered}/> }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UmzugEasyBasicTable;
