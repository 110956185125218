import React, {FC, ReactElement} from "react";
import {Box} from "@mui/material";
import ApiClientsList from "pages/admin/components/apiClients/ApiClientsList";
import {useInitApiClientsContext} from "pages/admin/components/apiClients/ApiClientsContext";
import {ApiClientsContext} from "pages/admin/components/apiClients/ApiClientsContext.model";
import Alert from "components/feedback/Alert";

const ApiClients: FC = (): ReactElement => {
    const ctx = useInitApiClientsContext()

    return (
        <Box sx={{width: '100%'}}>
            <ApiClientsContext.Provider value={ctx}>
                <ApiClientsList/>
                <Alert text={''} {...ctx.alert}/>
            </ApiClientsContext.Provider>
        </Box>
    )
}

export default ApiClients;