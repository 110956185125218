import {useContext, useEffect, useState} from "react";
import {useBookingContext} from "../../../../../../partner/components/bookings/BookingContext";
import {
    getNewExclusionType,
    getPostalCodeFromPopoverContent,
    getRegionFromPopoverContent,
    getTourWithoutPostalCodeOrRegion,
    isPostalCodeEqual,
    isRegionEqual,
    regionAndPostalCodeToPopoverContent
} from "../../../../../../partner/components/bookings/modal/tours/popover/BookingPopover.utils";
import {BookingTour} from "../../../../../../../api/booking/BookingResource";
import {useBookingTestContext} from "../BookingTestContext";
import {
    BookingTestPopoverActionsProps,
    BookingTestPopoverContext,
    BookingTestPopoverContextProps,
    TestPopoverContent
} from "./BookingTestPopoverContext.model";


export function useBookingTestPopoverContext(): BookingTestPopoverContextProps {
    return useContext(BookingTestPopoverContext)
}

export function useInitBookingTestPopoverContext(): BookingTestPopoverContextProps {
    const [popoverTestContent, setPopoverTestContent] = useState<TestPopoverContent | undefined>(undefined)

    const {regions} = useBookingContext()
    const {editedTour, actions} = useBookingTestContext()

    useEffect(() => {
        setPopoverTestContent(regionAndPostalCodeToPopoverContent(editedTour?.originalRegionOrPostalCodeToEdit))
    }, [editedTour?.originalRegionOrPostalCodeToEdit])

    useEffect(() => {
        const shouldIncludeId = (editedTour?.originalRegionOrPostalCodeToEdit.region == null && popoverTestContent?.regionId == null) || (editedTour?.originalRegionOrPostalCodeToEdit.region != null && popoverTestContent?.regionId != null);
        const newPostalCode = getPostalCodeFromPopoverContent(shouldIncludeId, popoverTestContent)
        const newRegion = getRegionFromPopoverContent(shouldIncludeId, popoverTestContent)
        if (newPostalCode != null || newRegion != null) {
            const shouldChange = !(isPostalCodeEqual(editedTour?.editedRegionOrPostalCode.postalCode, newPostalCode) && isRegionEqual(editedTour?.editedRegionOrPostalCode.region, newRegion))
            if (shouldChange) {
                actions.updateEditedTour({postalCode: newPostalCode, region: newRegion})
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popoverTestContent])

    const popoverActions: BookingTestPopoverActionsProps = {
        remove: (remove) => {
            if (editedTour != null) {
                let newTour: BookingTour = getTourWithoutPostalCodeOrRegion(editedTour.tour, remove);
                actions.setTour(newTour)
                actions.clearEditedTour()
            }
        },
        savePostalCode: (remove, newPostalCode) => {
            if (editedTour != null) {
                let newTour: BookingTour = getTourWithoutPostalCodeOrRegion(editedTour.tour, remove);
                if (!isPostalCodeEqual(undefined, newPostalCode)) {
                    actions.setTour({
                        ...newTour,
                        bookingTourPostalCodes: [...newTour.bookingTourPostalCodes, newPostalCode]
                    })
                }
                actions.clearEditedTour()
            }
        },
        saveRegion: (remove, newRegion) => {
            if (editedTour != null) {
                let newTour: BookingTour = getTourWithoutPostalCodeOrRegion(editedTour.tour, remove);
                if (!isRegionEqual(undefined, newRegion)) {
                    actions.setTour({
                        ...newTour,
                        bookingTourRegions: [...newTour.bookingTourRegions, newRegion]
                    })
                }
                actions.clearEditedTour()
            }
        },
        changeSelectedCountryOrRegion: (value: string) => {
            const isRegion = regions.map(region => `${region.id}`).indexOf(`${value}`) !== -1;
            if (isRegion) {
                setPopoverTestContent(prevState => ({...prevState, regionId: +value, country: undefined}))
            } else {
                setPopoverTestContent(prevState => ({...prevState, regionId: undefined, country: value}))
            }
        },
        changeExclusion: (isExcluded) =>
            setPopoverTestContent(prevState => ({
                ...prevState,
                type: getNewExclusionType(prevState?.type, isExcluded),
                isExcluded
            })),
        changePopoverPostalCode: (value) =>
            setPopoverTestContent(prevState => ({...prevState, postalCode: value}))
    }

    return {popoverTestContent: popoverTestContent, popoverTestActions: popoverActions}
}
