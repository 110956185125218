import {Box, Theme} from "@mui/material";
import React, {useEffect} from "react";
import {Booking} from "../../../../api/booking/BookingResource";
import UmzugEasyTable from "../../../../components/table/UmzugEasyTable";
import {useBookingToursLoader} from "../../../../components/table/schema/type/BookingTourSchema";
import {useBookingContext} from "./BookingContext";
import {BookingActionsProps} from "./BookingContext.model";
import BookingActions from "./BookingActions";
import {useTheme} from "@mui/styles";
import {convertPercentageToHexOpacity} from "../../../../utils/ColorUtils";

type BookingTourListProps = {
    booking: Booking
}
export const ActionRenderer = (tourId: number | undefined, bookingId: number | undefined, actions: BookingActionsProps) => {
    return (
        <div className="tour-actions">
            <BookingActions onRemove={() => tourId != null && bookingId != null && actions.onTourRemove(bookingId, tourId)}
                            onEdit={() => tourId != null && bookingId != null && actions.onTourEdit(bookingId, tourId)}/>
        </div>
    )
}

const BookingTourList: React.FC<BookingTourListProps> = ({booking}: BookingTourListProps) => {
    const {regions, actions} = useBookingContext()
    const {
        tableProps,
        setBookingTours
    } = useBookingToursLoader(regions, (row) => ActionRenderer(row.id, booking.id, actions))

    const theme = useTheme<Theme>()
    useEffect(() => setBookingTours(booking.bookingTours), [booking, setBookingTours]);

    return <Box
        sx={{
            paddingLeft: '40px',
            flexGrow: 1,
            display: "block",
        }}
    >
        <UmzugEasyTable
            {...tableProps}
            customStyles={{
                tableContainer: {
                    [`& .table-row .cell-action`]: {
                        width: '150px',
                        paddingRight: 0
                    },
                    [`& .table-row .cell-action .tour-actions`]: {
                        visibility: 'hidden',
                        display: 'flex',
                        justifyContent: 'end'
                    },
                    [`& .table-row .cell-direction`]: {
                        width: '50px',
                    },
                    [`& .table-row .cell-from, & .table-row .cell-to`]: {
                        maxWidth: '300px',
                        width: '300px'
                    },
                    [`& .table-row:hover .cell-action .tour-actions, & .table-row .cell-action .tour-actions:hover`]: {
                        visibility: 'visible',
                    },
                    [`& .table-row:hover, & .table-row:has(.tour-actions:hover)`]: {
                        backgroundColor: `${theme.palette.common.white}${convertPercentageToHexOpacity(30)}`
                    },
                    [`& .MuiTableCell-head`]: {
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        lineHeight: '14px'
                    },
                }
            }}
            isHeaderSticky={false}
            height='auto'
            theme='simple'
        />
    </Box>
}
export default BookingTourList