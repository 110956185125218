import {BookingTour, BookingTourType} from "api/booking/BookingResource";
import {useBookingContext} from "../../BookingContext";
import React, {createRef} from "react";
import {Box, Theme} from "@mui/material";
import Button from "../../../../../../components/form/Button";
import ActionImg from "../../../../../../components/shared/ActionImg";
import ActionIcon from "../../../../../../components/shared/ActionIcon";
import {ReactComponent as TwoWayIcon} from "../../../../../../assets/icons/two-way.svg"
import {ReactComponent as OneWayIcon} from "../../../../../../assets/icons/one-way.svg"
import {useTheme} from "@mui/styles";
import {useBookingModalContext} from "../BookingModalContext";
import {ChipItem} from "components/shared/ChipItem";

const TourListItem = ({tour, isFrom}: {
    tour: BookingTour,
    isFrom: boolean
}) => {
    const {regions} = useBookingContext()
    const {modalActions} = useBookingModalContext()
    const ref = createRef<HTMLDivElement>();
    const theme = useTheme<Theme>()

    const tourTypes: BookingTourType[] = (isFrom) ? [BookingTourType.FROM, BookingTourType.FROM_EXCLUDED] : [BookingTourType.TO, BookingTourType.TO_EXCLUDED]
    const excluded: BookingTourType[] = [BookingTourType.FROM_EXCLUDED, BookingTourType.TO_EXCLUDED];

    return (
        <Box sx={{display: 'flex', height: '100%', columnGap: '20px'}}>
            <Box style={{marginTop: '20px', marginBottom: '20px', flexGrow: 1, display: 'flex', columnGap: '10px', maxWidth: '100%', flexWrap: 'wrap'}}>
                <ChipItem sx={{justifyContent: 'center', backgroundColor: 'transparent'}} ref={ref}>
                    <ActionImg icon={'/icons/plus.svg'}
                               onClick={() => modalActions.newPostalCode(tour, ref.current, isFrom)}/>
                </ChipItem>
                {
                    tour.bookingTourPostalCodes
                        .filter(code => tourTypes.indexOf(code.type) !== -1)
                        .map(code => (
                            <>
                                <ChipItem key={`${code.type}-${code.country}-${code.postalCode || ''}`}
                                          isRed={excluded.indexOf(code.type) !== -1}
                                          onClick={(event) =>
                                              modalActions.editPostalCode(tour, code, event.currentTarget)}>
                                    <>{code.country}-{code.postalCode}</>
                                </ChipItem>
                            </>
                        ))
                }
                {
                    tour.bookingTourRegions
                        .filter(region => tourTypes.indexOf(region.type) !== -1)
                        .map(region => (
                            <ChipItem key={`${region.type}-${region.regionId}`}
                                      isRed={excluded.indexOf(region.type) !== -1}
                                      onClick={(event) =>
                                          modalActions.editRegion(tour, region, event.currentTarget)}>
                                <>{regions.find(item => item.id === region.regionId)?.name || ''}</>
                            </ChipItem>
                        ))
                }
            </Box>
            <Box sx={{height: '100%', alignItems: 'center', display: 'flex', cursor: 'pointer'}}>
                {isFrom && (
                    <Button icon={<ActionIcon IconElement={tour.direction === 'TWO_WAY' ? TwoWayIcon : OneWayIcon}
                                              color={theme.palette.common.white}/>}
                            onClick={() => modalActions.changeTourDirection(tour)}
                            sx={{width: '30px', height: '30px !important', padding: 0, minWidth: 0}}
                            appearance={'dark'}/>
                )}
                {!isFrom && (
                    <ActionImg icon={'/icons/trash.svg'}
                               onClick={() => modalActions.removeTour(tour.uuid)}/>
                )}
            </Box>
        </Box>
    )
}
export default TourListItem