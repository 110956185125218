import { useMsal } from "@azure/msal-react";
import { Grid, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SxProps } from "@mui/system";
import React from "react";
import {useTranslation} from "react-i18next";
import { loginRequest } from "../authConfig";
import Button from "../components/form/Button";

const loginPageStyles: SxProps<Theme> = {
    maxWidth: (theme) => theme.breakpoints.values.lg,
    margin: "auto",
    'div + div': {
        marginTop: '30px'
    }
};

const loginCaptionStyles: SxProps<Theme> = {
    color: (theme) => theme.palette.text.primary,
    maxWidth: '650px',
    textAlign: 'center',
};

const loginButtonIconStyle = makeStyles((theme: Theme) => ({
    icon: {
        paddingRight: '12px'
    }
}));

const Login: React.FC = () => {
    const { instance } = useMsal();
    const {t} = useTranslation('authentication');
    const loginButtonIconClasses = loginButtonIconStyle();
    
    return (
        <Grid container sx={loginPageStyles}>
            <Grid container item xs={12} justifyContent="center">
                <Typography sx={loginCaptionStyles}>{t('caption.unauthenticatedAccess')}</Typography>
            </Grid>
            <Grid container item xs={12} justifyContent="center" alignItems="center">
                <Button 
                    icon={<img alt='ms-login' className={loginButtonIconClasses.icon} src='/icons/microsoft_symbol.svg' />}
                    label={t('buttons.loginWithAzure')}
                    onClick={() => instance.loginRedirect(loginRequest)}
                />
            </Grid>
        </Grid>
    );
}

export default Login;