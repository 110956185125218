import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {MsalProvider} from "@azure/msal-react";
import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./authConfig";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const isProduction = process.env.REACT_APP_ENV === 'pro'

const msalInstance = new PublicClientApplication(msalConfig);

const renderApp = () => (
    <MsalProvider instance={msalInstance}>
        <App/>
    </MsalProvider>
)

root.render(
    isProduction ? (
        renderApp()
    ) : (
        <React.StrictMode>
            { renderApp() }
        </React.StrictMode>
    )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
