import React, {FC, ReactElement} from "react";
import {Box, Grid, IconButton, Menu, paperClasses, styled, Theme, Typography} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {Route, Routes, routes, useActiveRoute} from "../../routes/Routes";
import NavigationLink, {MenuType} from "./components/NavigationLink";
import {useTheme} from "@mui/styles";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import useUserProfileInfo from "../../hooks/useUserProfileInfo";
import ActionImg from "../shared/ActionImg";
import {SxProps} from "@mui/system";
import {Link} from "react-router-dom";

const Logo = () => (
    <Link to={Routes.MAIN_PAGE}>
        <Box
            component="img"
            sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)"
            }}
            alt="umzug-easy"
            src={process.env.REACT_APP_LOGO_URL}
        />
    </Link>
)

const StyledMenu = styled(Menu)(({theme}) => ({
    [`& .${paperClasses.root}`]: {
        width: "calc(100% - 32px)",
        backgroundColor: theme.palette.primary.dark,
        marginTop: "10px",
        maxHeight: "calc(100% - 70px)"
    }
}))

const userNameStyle: SxProps<Theme> = {
    fontSize: '14px',
    color: (theme) => theme.palette.success.main
};

const NavigationMobileSubmenu = (props: { subRoutes: Route[], onMenuItemClicked: () => void }) => {
    const activeRoute = useActiveRoute();
    return (
        <>
            {props.subRoutes.map(subPage => (
                <NavigationLink
                    page={subPage}
                    key={`mobile-${subPage.key}-link`}
                    isMobile={true}
                    onMenuItemClicked={props.onMenuItemClicked}
                    menuType={MenuType.SUBMENU}
                    activeRoute={activeRoute.child}
                    routeList={subPage.subRoutes || []}/>
            ))}
        </>
    )
}

const NavigationMobileContent = (props: { onMenuItemClicked: () => void }) => {
    const activeRoute = useActiveRoute();
    return (
        <>
            {routes.map((page) => (
                <div key={`mobile-${page.key}`}>
                    <NavigationLink page={page} menuType={MenuType.ROOT}
                                    activeRoute={activeRoute.parent}
                                    isMobile={true}
                                    onMenuItemClicked={props.onMenuItemClicked}
                                    routeList={routes.filter(item => !item.hideInNav)}/>
                    {
                        page.subRoutes && page.subRoutes.filter(item => !item.hideInNav).length > 0 &&
                        <NavigationMobileSubmenu subRoutes={page.subRoutes.filter(item => !item.hideInNav)}
                                                 onMenuItemClicked={props.onMenuItemClicked}/>
                    }
                </div>
            ))}
        </>
    )
}

const NavigationMobile: FC = (): ReactElement => {
    const theme = useTheme<Theme>()
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const isAuthenticated = useIsAuthenticated();
    const [userProfile] = useUserProfileInfo();
    const { instance } = useMsal();

    const handleOpenNavMenu = (event: any) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    return (
        <Box sx={{flexGrow: 1, display: {xs: "flex", md: "none"}, minHeight: "100%"}}>
            {isAuthenticated && (<IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
            >
                <MenuIcon sx={{color: theme.palette.common.white}}/>
            </IconButton>)}
            <Logo/>
            <StyledMenu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                    display: {xs: "block", md: "none"}
                }}
            >
                <NavigationMobileContent onMenuItemClicked={() => handleCloseNavMenu()} key={'mobile-content'}/>
                <Grid container alignItems='center'>
                    <Grid item sx={{ padding: '0 15px' }}>
                        <ActionImg icon={'/icons/offline.svg'} onClick={() => instance.logout() }/>
                    </Grid>
                    <Grid item>
                        <Typography sx={userNameStyle}>{userProfile?.name}</Typography>
                    </Grid>
                </Grid>
            </StyledMenu>
        </Box>
    )
}
export default NavigationMobile
