import React, {FC} from "react";
import {Box} from "@mui/material";
import {useFormikContext} from "formik";
import BookingSection from "../../../../partner/components/bookings/BookingSection";
import DatePicker from "../../../../../components/form/DatePicker";
import {useTranslation} from "react-i18next";

const DateSettings: FC = () => {
    const formik = useFormikContext();
    const {t} = useTranslation('bookingTest')
    const OLDEST_SUPPORTED_DATE = new Date("2019-01-01T00:00:00");

    return (
        <BookingSection title={t('form.date')} isModalSection={false}>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '16px',
                marginRight: '500px',
            }}>
                <DatePicker
                    initialValue={new Date(formik?.getFieldProps('startDate').value)}
                    onChange={(date) => {
                        formik?.setFieldValue('startDate', date)
                        formik?.submitForm()
                    }
                    }
                    disableFuture={true}
                    shouldDisableDate={(dateFrom) => dateFrom > formik.getFieldProps('endDate').value || dateFrom < OLDEST_SUPPORTED_DATE}
                />
                <DatePicker
                    initialValue={new Date(formik?.getFieldProps('endDate').value)}
                    onChange={(date) => {
                        formik?.setFieldValue('endDate', date)
                        formik?.submitForm()
                    }
                    }
                    disableFuture={true}
                    shouldDisableDate={(dateTo) => dateTo < formik.getFieldProps('startDate').value || dateTo < OLDEST_SUPPORTED_DATE}
                />
            </Box>
        </BookingSection>
    );
};

export default DateSettings;
