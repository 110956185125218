import React, {useContext} from 'react';
import {UmzugEasyTableStyles} from "./UmzugEasyTable";

export type TableTheme = 'default' | 'simple';

export type TableContextProps = {
  theme: TableTheme
  isRowActive?: (data: any) => boolean
  customStyles?: UmzugEasyTableStyles
}

export const UmzugEasyTableContext = React.createContext<TableContextProps>({
  theme: 'default'
});

export function useTableContext(): TableContextProps {
  return useContext(UmzugEasyTableContext)
}
