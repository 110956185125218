import React, {FC} from "react";
import Button from "../../../../../components/form/Button";
import ActionImg from "../../../../../components/shared/ActionImg";
import {Box} from "@mui/material";

type OpenScheduledPauseFormButtonProps = {
    onScheduledPause: () => void,
    label: string
}
const OpenScheduledPauseFormButton: FC<OpenScheduledPauseFormButtonProps> = ({onScheduledPause, label}) => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'end', paddingTop: '15px'}}>
            <Button icon={<ActionImg icon={'/icons/plus.svg'} iconStyle={{marginRight: '5px'}} alt={'Add'}/>}
                    label={label} type={'button'} appearance={'link'}
                    onClick={onScheduledPause}/>
        </Box>
    )
}

export default OpenScheduledPauseFormButton