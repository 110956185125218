import { GroupOfPartners } from './GroupOfPartners';
import {apiVersion, getApiClient} from '../ApiUtils';

export function getGroups(): Promise<GroupOfPartners[]> {
    return getApiClient().then(client => client.get<GroupOfPartners[]>(
        `/groups`,
        {baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data);
}

export function assignPartnerToGroup(groupId: number, partnerCwid: string): Promise<void> {
    return getApiClient().then(client => client.put(
        `/groups/${groupId}/partners/${partnerCwid}`,
        {},
        {baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    ));
}

export function removePartnerFromGroup(groupId: number, partnerCwid: string): Promise<void> {
    return getApiClient().then(client => client.delete(
        `/groups/${groupId}/partners/${partnerCwid}`,
        {baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    ));
}

export function createGroupOfPartners(groupName: String, partnerCwid: String): Promise<GroupOfPartners> {
    return getApiClient().then(client => client.post<GroupOfPartners>(
        `/groups`,
        {
            name: groupName,
            partnerCwid: partnerCwid
        },
        {baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data);
}
