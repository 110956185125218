import React, {FC} from "react";
import {Box} from "@mui/material";
import BookingSection from "../../../../../partner/components/bookings/BookingSection";
import {useTranslation} from "react-i18next";
import TourFlex from "../../../../../partner/components/bookings/modal/tours/TourFlex";
import {useFormikContext} from "formik";
import {BookingTour} from "../../../../../../api/booking/BookingResource";
import {BookingTestForm} from "../FormFields";
import TestTour from "./TestTour";
import TourTestEditPopover from "./popover/TourTestEditPopover";
import Label from "../../../../../../components/form/Label";
import {useInitBookingTestContext} from "pages/testing/bookingtest/components/form/tours/BookingTestContext";
import {BookingTestContext} from "pages/testing/bookingtest/components/form/tours/BookingTestContext.model";
import { BookingContext } from "pages/partner/components/bookings/BookingContext.model";
import {useInitBookingContext} from "../../../../../partner/components/bookings/BookingContext";

const TestTourSettings: FC = () => {
    const bookingTestContext = useInitBookingTestContext()
    const {t} = useTranslation('bookingTest')
    const formik = useFormikContext<BookingTestForm>();
    const fieldProps = formik.getFieldProps<BookingTour>('bookingTour')
    const bookingCtx = useInitBookingContext(undefined);


    return (
        <BookingContext.Provider value={bookingCtx}>
        <BookingTestContext.Provider value={bookingTestContext}>
            <BookingSection title={t('form.tour')} isModalSection={false}>
                <Box sx={{
                    marginRight: '100px'
                }}>
                    <Box sx={{flexGrow: 1, overflowY: 'auto', overflowX: 'hidden'}}>
                        <TourFlex
                            left={<Label label={'Von'}/>}
                            right={<Label label={'Nach'}/>}
                        />
                        <Box sx={{flexGrow: 1, overflowY: 'auto', overflowX: 'hidden'}}>
                            <TourFlex
                                left={<TestTour key={`${fieldProps.value.uuid}-left`}
                                                tour={fieldProps.value}
                                                isFrom={true} />}
                                right={<TestTour key={`${fieldProps.value.uuid}-right`}
                                                 tour={fieldProps.value}
                                                 isFrom={false} />}
                            />
                        </Box>
                    </Box>
                    <TourTestEditPopover/>
                </Box>
            </BookingSection>
        </BookingTestContext.Provider>
        </BookingContext.Provider>

    );
};
export default TestTourSettings;
