import {FC, ReactElement} from "react";
import {makeStyles} from "@mui/styles";
import {Box, Grid, Theme} from "@mui/material";

export type RelocationRequestDetailSectionProps = {
    header: string
    leftContent: ReactElement
    rightContent?: ReactElement
}

const relocationRequestDetailSectionStyles = makeStyles((theme: Theme) => ({
    sectionHeader: {
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '20px',
        fontStyle: 'italic'
    },
    sectionWrapper: {
        borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
        padding: '10px 0'
    },
    content: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px'
    }
}))

const RelocationRequestDetailSection: FC<RelocationRequestDetailSectionProps> = ({header, leftContent, rightContent}: RelocationRequestDetailSectionProps): ReactElement => {
    const classes = relocationRequestDetailSectionStyles()

    return (
        <Box className={classes.sectionWrapper}>
            <Box className={classes.sectionHeader}>{ header }</Box>
            <Grid container>
                <Grid item xs={rightContent ? 6 : 12} className={classes.content}>
                    { leftContent }
                </Grid>
                {rightContent &&
                <Grid item xs={6} className={classes.content}>
                    { rightContent }
                </Grid>}
            </Grid>
        </Box>
    )
}
export default RelocationRequestDetailSection
