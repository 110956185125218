import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import * as deAdminTranslations from './i18n/de/admin.json';
import * as enAdminTranslations from './i18n/en/admin.json';
import * as deCommonTranslations from './i18n/de/common.json';
import * as dePartnersTranslations from './i18n/de/partner.json';
import * as deOverviewTranslations from './i18n/de/overview.json';
import * as enCommonTranslations from './i18n/en/common.json';
import * as enPartnersTranslations from './i18n/en/partner.json';
import * as enOverviewTranslations from './i18n/en/overview.json';
import * as deReportsTranslations from './i18n/de/reports.json';
import * as enReportsTranslations from './i18n/en/reports.json';
import * as deAuthenticationTranslations from './i18n/de/authentication.json';
import * as enAuthenticationTranslations from './i18n/en/authentication.json';
import * as deRelocationRequestsTranslations from './i18n/de/relocationRequests.json';
import * as enRelocationRequestsTranslations from './i18n/en/relocationRequests.json';
import * as deAlgotestTranslations from './i18n/de/algotest.json';
import * as enAlgotestTranslations from './i18n/en/algotest.json';
import * as deBookingsTranslations from './i18n/de/bookings.json';
import * as enBookingsTranslations from './i18n/en/bookings.json';
import * as dePartnerDocumentsTranslations from './i18n/de/partnerDocuments.json';
import * as enPartnerDocumentsTranslations from './i18n/en/partnerDocuments.json';
import * as dePartnersReportTranslations from './i18n/de/partnersReport.json';
import * as enPartnersReportTranslations from './i18n/en/partnersReport.json';
import * as deRequestsTranslations from './i18n/de/requests.json';
import * as enRequestsTranslations from './i18n/en/requests.json';
import * as deUndistributedRequestsTranslations from './i18n/de/undistributedRequests.json';
import * as enUndistributedRequestsTranslations from './i18n/en/undistributedRequests.json';
import * as deDashboardTranslations from './i18n/de/dashboard.json';
import * as enDashboardTranslations from './i18n/en/dashboard.json';
import * as deInventoryTranslations from './i18n/de/inventory.json';
import * as enInventoryTranslations from './i18n/en/inventory.json';
import * as deBookingsTestTranslations from './i18n/de/bookingTest.json'
import * as enBookingsTestTranslations from './i18n/en/bookingTest.json'

export const i18nResources = {
    de: {
        admin: deAdminTranslations,
        common: deCommonTranslations,
        partner: dePartnersTranslations,
        overview: deOverviewTranslations,
        authentication: deAuthenticationTranslations,
        relocationRequests: deRelocationRequestsTranslations,
        algotest: deAlgotestTranslations,
        bookings: deBookingsTranslations,
        partnerDocuments: dePartnerDocumentsTranslations,
        partnersReport: dePartnersReportTranslations,
        requests: deRequestsTranslations,
        undistributedRequests: deUndistributedRequestsTranslations,
        dashboard: deDashboardTranslations,
        inventory: deInventoryTranslations,
        bookingTest: deBookingsTestTranslations,
        reports: deReportsTranslations
    },
    en: {
        admin: enAdminTranslations,
        common: enCommonTranslations,
        partner: enPartnersTranslations,
        overview: enOverviewTranslations,
        authentication: enAuthenticationTranslations,
        relocationRequests: enRelocationRequestsTranslations,
        algotest: enAlgotestTranslations,
        bookings: enBookingsTranslations,
        partnerDocuments: enPartnerDocumentsTranslations,
        partnersReport: enPartnersReportTranslations,
        requests: enRequestsTranslations,
        undistributedRequests: enUndistributedRequestsTranslations,
        dashboard: enDashboardTranslations,
        inventory: enInventoryTranslations,
        bookingTest: enBookingsTestTranslations,
        reports: enReportsTranslations
    }
}
if (localStorage.getItem('i18nextLng') === null) {
    localStorage.setItem('i18nextLng', 'de')
}
i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: i18nResources,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
