import React from "react";
import {Grid} from "@mui/material";
import LabeledField from "../../../../components/form/LabeledField";
import {useTranslation} from "react-i18next";
import {formatToLocaleTextWithDateTime} from "../../../../utils/DateUtil";
import {usePartnerContext} from "../../shared/PartnerContext";
import FormEditableTextField from "../../../../components/form/FormEditableTextField";

const AdministrationData: React.FC = () => {
    const {t} = useTranslation('partner');
    const {partner, partnerSettings} = usePartnerContext()

    return (
        <Grid container>
            <Grid container item xs={12} md={6}>
                <LabeledField
                    label={t('mainData.labels.registrationDate')}
                    children={<>{formatToLocaleTextWithDateTime(partnerSettings?.registrationDate)}</>}
                />
                <LabeledField label={t('mainData.labels.ustId')}>
                    <FormEditableTextField value={partnerSettings?.vatId}
                                           formikName={'vatId'} submitOnChange={true}/>
                </LabeledField>
            </Grid>

            <Grid container item xs={12} md={6}>
                <LabeledField label={t('mainData.labels.contactCwid')} children={<>{partner?.contact?.cwid}</>}/>
                <LabeledField label={t('mainData.labels.accountCwid')} children={<>{partner?.cwid}</>}/>
            </Grid>
        </Grid>
    );
}

export default AdministrationData;
