import React, {FC} from "react";
import Modal from "../../../../../components/form/Modal";
import {Box, Grid} from "@mui/material";
import {ReactComponent as Clock} from "../../../../../assets/icons/clock.svg"
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {useTranslation} from "react-i18next";
import {useDefaultTheme} from "themeConfig";
import {SelfPauseHistoryResource} from "api/selfPauseHistory/SelfPauseHistoryResource";
import NoHistoryContent from "./NoHistoryContent";
import LoadingElement from "./LoadingElement";
import CloseModalButton from "./CloseModalButton";
import SelfPauseHistoryRow from "./SelfPauseHistoryRow";
import ModalTitle from "./ModalTitle";
import {formatToLocaleTextWithDateTime} from "utils/DateUtil";

export type SelfPauseHistoryModalProps = {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    isLoading: boolean,
    isOpen: boolean,
    selfPauseHistory: SelfPauseHistoryResource[]
}

const SelfPauseHistoryModal: FC<SelfPauseHistoryModalProps> = ({setOpen, isLoading, isOpen, selfPauseHistory}) => {
    const {t} = useTranslation('dashboard')
    const historyElements: ReactJSXElement[] = []
    const theme = useDefaultTheme()

    const closeButton = <CloseModalButton label={t('pause.selfPause.modal.button')} setOpen={setOpen}/>

    const noSelfPauseHistoryContent = <NoHistoryContent text={t('pause.selfPause.modal.noData')}/>

    const loadingElement: JSX.Element = <LoadingElement />

    selfPauseHistory
        .sort((a, b) => new Date(b.changeDate).getTime() - new Date(a.changeDate).getTime())
        .forEach((entry, index) => {
            const date = formatToLocaleTextWithDateTime(entry.changeDate)
            const formattedDay = date.split(" ")[0]
            const formattedTime = date.split(" ")[1]

            historyElements.push(
                <SelfPauseHistoryRow
                    key={index}
                    formattedDay={formattedDay}
                    formattedTime={formattedTime}
                    status={entry.paused ? t('pause.selfPause.modal.values.paused') : t('pause.selfPause.modal.values.activated')}
                />
            )
        })

    const titleElement = <ModalTitle
        text={t('pause.selfPause.modal.title')}
        icon={<Clock/>}
    />

    const hasSelfPauseHistoryEntries = selfPauseHistory.length > 0

    return (
        <Modal
            title={titleElement}
            isOpen={isOpen}
            children={
                <>
                    <Box>
                        <Grid container item xs={12} alignItems='center' sx={{
                            fontSize: '14px',
                            color: theme.umzugEasy.palette.darkGray,
                            fontWeight: '600',
                            backgroundColor: '#FFFFFF',
                            textAlign: 'center',
                            paddingTop: '15px',
                            paddingBottom: '15px'
                        }}>
                            <Grid item xs={4}>
                                {t('pause.selfPause.modal.header.day')}
                            </Grid>
                            <Grid item xs={4}>
                                {t('pause.selfPause.modal.header.time')}
                            </Grid>
                            <Grid item xs={4}>
                                {t('pause.selfPause.modal.header.value')}
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{maxHeight: '50vh', overflow: 'auto', backgroundColor: "#fafafa"}}>
                        {isLoading ? loadingElement :
                            hasSelfPauseHistoryEntries ? historyElements : noSelfPauseHistoryContent}
                    </Box>
                    {closeButton}
                </>
            }
            width={'700px'}
            padding={'0'}
        />
    )
}

export default SelfPauseHistoryModal