const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/de.json"))

export type ISOCountryCode = {
    code: string,
    name: string,
}

export function getCountryCodes(): ISOCountryCode[] {
    const deCountries = countries.getNames("de", {select: "official"});
    return Object.keys(deCountries)
        .map((isoCode2: string): ISOCountryCode => ({code: isoCode2, name: deCountries[isoCode2]}))
}

export function getPopularCountryCodes(): ISOCountryCode[] {
    const popularCountryCodes = ['DE', 'AT', 'CH']
    return getCountryCodes()
        .filter(item => popularCountryCodes.indexOf(item.code) !== -1)
}