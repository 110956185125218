import React from "react";
import {Box, Theme, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {makeStyles, useTheme} from "@mui/styles";
import {Routes} from "../routes/Routes";
import {Link} from "react-router-dom";

const taskItemStyles = makeStyles((theme: Theme) => ({
    taskItem: {
        height: "200px",
        cursor: "pointer",
        width: "340px",
        margin: "24px",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.common.white,
        display: "flex",
        flexDirection: "column",
        '&:hover': {
            boxShadow: "5px 5px 5px lightgray"
        }
    },
    link: {
        textDecoration: "none",
        color: theme.palette.common.black
    }
}))

const TaskItem = (props: { title: string, description?: string, image: string, path: string }) => {
    const classes = taskItemStyles()
    const {t} = useTranslation('overview');
    return (
        <Link
            className={classes.link}
            to={props.path}
        >
            <Box className={classes.taskItem}>
                <Box
                    component="img"
                    sx={{
                        height: "36px",
                        marginBottom: "14px"
                    }}
                    src={`/images/overview/${props.image}.png`}
                    alt={props.image}/>
                <Typography
                    lineHeight="24px"
                    fontSize="18px"
                    fontWeight={600}>
                    {t(props.title)}
                </Typography>
                {
                    props.description &&
                    <Typography
                        sx={{marginTop: "4px"}}
                        lineHeight="16px"
                        fontSize="12px"
                        fontWeight={400}>
                        {t(props.description)}
                    </Typography>
                }
            </Box>
        </Link>
    )
}

const Tasks = () => {
    const theme = useTheme<Theme>()
    return (
        <Box
            sx={{
                display: "flex",
                color: theme.palette.common.black
            }}
        >
            <TaskItem
                title="inquiry"
                image="inquiry"
                path={Routes.RELOCATION_REQUESTS_PAGE}
            />
            <TaskItem
                title="partner"
                image="partner"
                path={Routes.PARTNERS_LIST_PAGE}
            />
            <TaskItem
                title="reports"
                image="reports"
                path={Routes.REPORTS_PAGE}
            />
        </Box>
    )
}
const Overview: React.FC = () => {
    const theme = useTheme<Theme>()
    return (
        <Box
            sx={{
                flexGrow: 1,
                display: "flex",
                color: theme.palette.common.black,
                justifyContent: "center",
                backgroundColor: "whitesmoke"
            }}
        >
            <Box
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: "10vh",
                    maxWidth: "max-content"
                }}
            >
                <Tasks/>
            </Box>
        </Box>
    );
}

export default Overview
