import React, {useContext} from "react";
import {PartnerResource} from "api/partner/PartnerResource";
import {
    PartnerSettingsOverduePaymentsRequest,
    PartnerSettingsRequest,
    PartnerSettingsResource
} from "api/partnerSettings/PartnerSettingsResource";
import {ContractResource} from "api/contract/ContractResourcePage";

export type PartnerDetailContextProps = {
    partner: PartnerResource | undefined,
    partnerSettings: PartnerSettingsResource | undefined,
    partnerContracts: ContractResource[] | undefined,
    isLoading: boolean,
    error: JSX.Element | undefined,
    isPartnerOnline: boolean,
    getValidContracts: () => ContractResource[],
    assignPartnerToGroup: (groupId?: number) => void,
    assignNewPartnerSettings: (newSettings: PartnerSettingsResource) => void,
    updatePartnerSettings: (cwid: string, newSettings: PartnerSettingsRequest) => void,
    toggleHasOverduePayments: (cwid: string, newValue: PartnerSettingsOverduePaymentsRequest) => void,
    resetDeactivationDate: (cwid: string) => void;
}

export const emptyPartnerDetailContext = (): PartnerDetailContextProps => ({
    partner: undefined,
    partnerSettings: undefined,
    partnerContracts: undefined,
    isLoading: true,
    error: undefined,
    isPartnerOnline: false,
    assignNewPartnerSettings: () => {},
    getValidContracts: () => [],
    assignPartnerToGroup: () => {},
    updatePartnerSettings: () => {},
    toggleHasOverduePayments: () => {},
    resetDeactivationDate: () => {}
})

export const PartnerDetailContext = React.createContext<PartnerDetailContextProps>(emptyPartnerDetailContext())

export function usePartnerContext(): PartnerDetailContextProps {
    return useContext(PartnerDetailContext)
}
