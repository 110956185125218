import {useDefaultTheme} from "../../../../../themeConfig";
import React from "react";

const LabelWithColor = (color: 'green' | 'red' | 'unset', text: string) => {
    const theme = useDefaultTheme()
    const colorStyles = {
        'green': theme.palette.success.main,
        'red': theme.palette.error.main,
        'unset': ''
    }
    return (
        <span
            style={{display: "block", width: "max-content"}}>
            <div style={{
                height: "calc(100% - 4px)",
                width: "4px",
                position: "absolute",
                top: "2px",
                left: 0,
                backgroundColor: colorStyles[color]
            }}></div>
            {text}
        </span>
    )
}

export default LabelWithColor