import {useTheme} from "@mui/styles";
import {Box, Theme} from "@mui/material";
import React from "react";

type TourFlexProps = {
    left: JSX.Element | JSX.Element[],
    right: JSX.Element | JSX.Element[]
}
const TourFlex = (props: TourFlexProps) => {
    const theme = useTheme<Theme>()
    return (
        <Box sx={{borderBottom: `1px solid ${theme.palette.primary.contrastText}`, width: '100%', display: 'flex'}}>
            <Box sx={{width: '50%', paddingRight: '10px'}}>{props.left}</Box>
            <Box sx={{width: '50%', paddingLeft: '10px'}}>{props.right}</Box>
        </Box>
    )
}
export default TourFlex
