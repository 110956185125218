import React, {FC, ReactElement, useState} from "react";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import Button from "../../../../components/form/Button";
import ActionImg from "../../../../components/shared/ActionImg";
import DateRangeCalendarModal from "../../../../components/dateRangeCalendar/DateRangeCalendarModal";
import {useBookingsSettingsContext} from "../bookings/bookingsSettings/BookingsSettingsContext";

const DateRangeFooter: FC = (): ReactElement => {
    const {t} = useTranslation('bookings')
    const { exclusionDateRangeActions } = useBookingsSettingsContext()

    const [isExclusionDateCalendarVisible, setIsExclusionDateCalendarVisible] = useState(false)

    const onSelectDateRange = (start: Date, end: Date) => {
        exclusionDateRangeActions.onAddRelocationExclusionDateRange(start, end)
        setIsExclusionDateCalendarVisible(false)
    }
    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                height: '54px'
            }}>
                <Button icon={<ActionImg icon={'/icons/plus.svg'}
                                        iconStyle={{marginRight: '5px'}}/>}
                        label={t('booking.bookingsSettings.exclusionDates.addDateRange')}
                        type={'button'}
                        appearance={'link'}
                        onClick={() => setIsExclusionDateCalendarVisible(true)}
                />
            </Box>
            <DateRangeCalendarModal
                disablePast
                showModal={isExclusionDateCalendarVisible}
                onSelect={onSelectDateRange}
                onCancel={() => setIsExclusionDateCalendarVisible(false)} />
        </>
    )
}

export default DateRangeFooter
