import {useContext, useEffect, useState} from 'react';
import {Region} from "../../../../api/region/RegionResource";
import {Booking} from "../../../../api/booking/BookingResource";
import {
    changeBookingActive,
    createBooking,
    deleteBooking,
    deleteBookingTour,
    getBookingById,
    getBookings,
    updateBooking
} from "../../../../api/booking/BookingApi";
import {getRegions} from "../../../../api/region/RegionApi";
import {
    BookingActionsProps,
    BookingContext,
    BookingContextProps,
    BookingModalProps,
    emptyBooking
} from "./BookingContext.model";
import {AlertProps} from "../../../../components/feedback/Alert";
import {useTranslation} from "react-i18next";
import {useBookingsSettingsContext} from "./bookingsSettings/BookingsSettingsContext";
import {DeletionConfirmationModalProps} from "./modal/DeletionConfirmationModal";
import { closeModal } from '../../../../components/form/Modal';

export function useInitBookingContext(partnerCwid: string | undefined): BookingContextProps {
    const [bookings, setBookings] = useState<Booking[]>([])
    const [regions, setRegions] = useState<Region[]>([])
    const [isLoading, setLoading] = useState(false)
    const [isSaving, setSaving] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [alert, setAlert] = useState<AlertProps | undefined>(undefined)
    const [modal, setModal] = useState<BookingModalProps | undefined>(undefined)
    const [deletionConfirmationModal, setDeletionConfirmationModal] = useState<DeletionConfirmationModalProps | undefined>(undefined)

    const namespace = 'bookings';
    const {t} = useTranslation(namespace)
    const updateBookingsAndCloseModal = (updatedBooking: Booking) => {
        setBookings(prevState => prevState.map(item => item.id === updatedBooking.id ? updatedBooking : item))
        setModal(undefined)
    }
    const addNewBookingAndCloseModal = (newBooking: Booking) => {
        setBookings(prevState => [...prevState, newBooking])
        setModal(undefined)
    }
    const showAlert = (translationKey: string) => {
        setAlert({
            color: 'error',
            open: true,
            text: t(`booking.edit.alerts.${translationKey}`),
            handleClose: () => setAlert(undefined)
        })
    }
    const hideDeletionConfirmationModal = () => closeModal(setDeletionConfirmationModal)
    const showDeletionConfirmationModal = (type: 'booking' | 'bookingTour', action: (partnerCwid: string) => void) => {
        setDeletionConfirmationModal({
            open: true,
            titleKey: `booking.edit.deletionConfirmation.${type}.title`,
            descriptionKey: `booking.edit.deletionConfirmation.${type}.description`,
            cancel: () => hideDeletionConfirmationModal(),
            confirm: () => partnerCwid != null && action(partnerCwid),
            namespace
        })
    }

    const updateBookingById = (partnerCwid: string, bookingId: number) => {
        getBookingById(partnerCwid, bookingId)
            .then((updatedBooking) => updateBookingsAndCloseModal(updatedBooking))
            .catch(() => showAlert('downloadBookingError'))
    }

    const actions: BookingActionsProps = {
        onShowDetailsInList: () => setShowDetails(prevState => !prevState),

        onBookingAdd: () => setModal(({action: 'add', type: 'booking'})),
        onBookingEdit: (bookingId: number) => setModal(({action: 'edit', type: 'booking', bookingId})),
        onBookingRemove: (bookingId: number) => showDeletionConfirmationModal('booking', (partnerCwid) => {
            deleteBooking(partnerCwid, bookingId)
                .then(() => setBookings(prevState => prevState.filter(item => item.id !== bookingId)))
                .catch(() => showAlert('removingBookingError'))
                .finally(() => hideDeletionConfirmationModal())
        }),

        onTourAdd: (bookingId: number) => setModal(({action: 'add', type: 'tour', bookingId})),
        onTourEdit: (bookingId: number, tourId: number) => setModal(({
            action: 'edit',
            type: 'tour',
            bookingId,
            bookingTourId: tourId
        })),
        onTourRemove: (bookingId: number, tourId: number) => showDeletionConfirmationModal('bookingTour', (partnerCwid) => {
            deleteBookingTour(partnerCwid, bookingId, tourId)
                .then(() => updateBookingById(partnerCwid, bookingId))
                .catch(() => showAlert('removingBookingTourError'))
                .finally(() => hideDeletionConfirmationModal())
        }),

        createBooking: (booking) => {
            if (partnerCwid) {
                setSaving(true)
                createBooking(partnerCwid, booking)
                    .then((newBooking) => addNewBookingAndCloseModal(newBooking))
                    .catch(() => showAlert('createBookingError'))
                    .finally(() => setSaving(false))
            }
        },
        updateBooking: (bookingId, booking) => {
            if (partnerCwid) {
                setSaving(true)
                updateBooking(partnerCwid, bookingId, booking)
                    .then((updatedBooking) => updateBookingsAndCloseModal(updatedBooking))
                    .catch(() => showAlert('updateBookingError'))
                    .finally(() => setSaving(false))
            }
        },
        changeBookingActive: (bookingId, isActive) => {
            if (partnerCwid) {
                setSaving(true)
                changeBookingActive(partnerCwid, bookingId, isActive)
                    .then((updatedBooking) => updateBookingsAndCloseModal(updatedBooking))
                    .catch(() => showAlert('updateBookingError'))
                    .finally(() => setSaving(false))
            }
        },

        onCloseModal: () => setModal(undefined)
    }

    useEffect(() => {
        if (partnerCwid) {
            setLoading(true)
            getBookings(partnerCwid).then((result) => setBookings(result)).finally(() => setLoading(false))
        }
    }, [partnerCwid])

    useEffect(() => {
        getRegions().then(result => setRegions(result))
            .catch(() => showAlert('downloadRegionsError'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        bookings,
        isLoading,
        regions,
        showDetails,
        actions,
        modal,
        partnerCwid,
        deletionConfirmationModal,
        alert,
        isSaving
    }
}

export function useBookingContext(): BookingContextProps {
    return useContext(BookingContext)
}

export const useBookingModal = () => {
    const [isOpen, setOpen] = useState(false)
    const [booking, setBooking] = useState<Booking | undefined>(undefined)
    const {modal, bookings, partnerCwid} = useBookingContext()
    const {bookingsSettings} = useBookingsSettingsContext()

    useEffect(() => {
        setOpen(modal != null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal, setOpen])

    useEffect(() => {
        if (isOpen && modal?.bookingId != null) {
            setBooking(bookings.find(booking => booking.id === modal?.bookingId));
        } else if (isOpen && modal?.action === 'add' && modal.type === 'booking' && partnerCwid != null) {
            setBooking(emptyBooking(partnerCwid, bookingsSettings?.shopPricesByApartmentArea))
        } else {
            setBooking(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, modal?.bookingId, setBooking, bookings])

    return {isOpen, booking}
}
