import React, {FC, useEffect, useState} from "react";
import Section from "../../../../components/form/Section";
import {useTranslation} from "react-i18next";
import {usePartnerContext} from "../../shared/PartnerContext";
import {ToggleHistoryResource} from "../../../../api/toggleHistory/ToggleHistoryResource";
import {getToggleHistory} from "../../../../api/toggleHistory/ToggleHistoryApi";
import {Grid} from "@mui/material";
import Button from "../../../../components/form/Button";
import ToggleHistoryModal from "./modal/ToggleHistoryModal";

const ToggleHistory: FC = () => {
    const {t} = useTranslation('dashboard')
    const {partner} = usePartnerContext()
    const [isLoading, setLoading] = useState(false)
    const [toggleHistory, setToggleHistory] = useState<ToggleHistoryResource[]>([])
    const [isOpen, setOpen] = useState(false)

    const partnerCwid = partner?.cwid

    useEffect(() => {
        if (partnerCwid && isOpen) {
            setLoading(true)
            getToggleHistory(partnerCwid).then(toggleHistory => {
                setToggleHistory(toggleHistory)
            }).finally(() => setLoading(false))
        }
    }, [isOpen, partnerCwid]);

    const onModalOpen = () => {
        setOpen(true)
    }

    return (
        <Section dataTestId={'toggleHistory'} title={t('toggleHistory.title')}>
            <Grid item xs={8}>
                <Button
                    sx={{
                        marginLeft: "14px",
                        paddingBottom: "1px",
                        '&:hover': {
                            paddingBottom: "0px",
                            borderBottom: 'solid 1px',
                            borderRadius: "0px"
                        },
                    }}
                    label={t('toggleHistory.link')} type={'button'} appearance={'link'}
                    onClick={() => onModalOpen()}
                />
            </Grid>
            <ToggleHistoryModal isLoading={isLoading} toggleHistory={toggleHistory} isOpen={isOpen} setOpen={setOpen}/>
        </Section>
    )
}

export default ToggleHistory