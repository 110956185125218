import React, {ReactElement, useState, useImperativeHandle, forwardRef} from "react"
import { getRequestsAndLeadsStatistics } from "api/statistics/StatisticsApi"
import ReportTable, { Formatter, ReportTableRow, ReportTableRowValues } from "components/reportTable/ReportTable"
import { currencyFormatter, fractionFormatter, toStringFormatter } from "pages/reports/utils/formatters"
import MultipleRowSkeleton from "components/feedback/MultipleRowSkeleton"
import { useTranslation } from "react-i18next"
import { RequestsAndLeadsStatisticsResource } from "api/statistics/Statistics"
import { getAggregatedStatistics } from "pages/reports/overview/RequestSourcesStatisticsService"

type RequestsAndLeadsReportProps = {
    startDate: Date
    endDate: Date
    countryCodes: string[]
}

export type RequestsAndLeadsReportRef = {
    fetchStatistics: () => void
}

const RequestsAndLeadsReport = forwardRef<RequestsAndLeadsReportRef, RequestsAndLeadsReportProps>(
    ({ startDate, endDate, countryCodes }, ref): ReactElement | null => {
        const { t } = useTranslation("reports")
        const headerOf = (column: string) => t(`partner.header.${column}`)
        const [statistics, setStatistics] = useState<RequestsAndLeadsStatisticsResource[]>([])
        const [isLoading, setLoading] = useState<boolean>(false)
        const [isVisible, setVisible] = useState<boolean>(false);

        const headers = [
            "countryCode",
            "requests",
            "revenuePerRequest",
            "leads",
            "revenuePerLead",
            "multiplier",
            "totalRevenue"
        ].map(headerOf)

        const formatters: Array<Formatter> = [
            toStringFormatter,
            currencyFormatter,
            toStringFormatter,
            currencyFormatter,
            fractionFormatter,
            currencyFormatter
        ]

        const aggregationFunction = (rowValues: ReportTableRowValues[]): ReportTableRowValues => {
            const aggregatedStatistics = getAggregatedStatistics(
                rowValues.map((values: ReportTableRowValues) => ({
                    requestCount: values[0],
                    leadCount: values[2],
                    revenue: values[5]
                }))
            )
            return [
                aggregatedStatistics.totalRequests,
                aggregatedStatistics.revenuePerRequest,
                aggregatedStatistics.totalLeads,
                aggregatedStatistics.revenuePerLead,
                aggregatedStatistics.multiplier,
                aggregatedStatistics.totalRevenue
            ]
        }

        const aggregationSettings = {
            aggregationRowLabel: t("partner.aggregationLabel"),
            aggregationFunction
        }

        const rows: ReportTableRow[] = statistics.map(s => ({
            id: s.countryCode,
            values: [
                s.requestCount,
                s.revenue / s.requestCount,
                s.leadCount,
                s.revenue / s.leadCount,
                s.leadCount / s.requestCount,
                s.revenue
            ]
        }))

        const fetchStatistics = async (): Promise<void> => {
            setLoading(true);
            try {
                setStatistics(await getRequestsAndLeadsStatistics(countryCodes, startDate, endDate));
                setVisible(true);
            } finally {
                setLoading(false);
            }
        };

        useImperativeHandle(ref, () => ({
            fetchStatistics
        }))

        if (!isVisible) {
            return null;
        }

        if (isLoading) return <MultipleRowSkeleton rows={5} fontSize={"35px"} styles={{ height: "200px", width: "640px" }} />

        return (
            <ReportTable
                headers={headers}
                rows={rows}
                formatters={formatters}
                aggregationSettings={aggregationSettings}
            />
        )
    }
)

export default RequestsAndLeadsReport
