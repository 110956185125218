import {
    ShopSubscriptionSettingsActionsProps, ShopSubscriptionSettingsContext,
    ShopSubscriptionSettingsContextProps
} from "pages/partner/components/bookings/bookingsSettings/shop/ShopSubscriptionSettingsContext.model";
import {useContext, useEffect, useState} from "react";
import {ShopSubscribedRegion, ShopSubscription} from "api/bookingsSettings/BookingsSettingsResource";
import {
    getShopSubscription,
    updateShopSubscription
} from "api/bookingsSettings/BookingsSettingsApi";
import {Region} from "api/region/RegionResource";
import {getRegions} from "api/region/RegionApi";
import {AlertProps} from "components/feedback/Alert";
import {useTranslation} from "react-i18next";

export function useInitShopSubscriptionSettingsContext(partnerCwid: string | undefined): ShopSubscriptionSettingsContextProps {
    const [isLoading, setLoading] = useState(false)
    const [shopSubscription, setShopSubscription] = useState<ShopSubscription | undefined>(undefined);
    const [selectedShopSubscribedRegion, setSelectedShopSubscribedRegion] = useState<ShopSubscribedRegion | undefined>(undefined);
    const [selectedHtmlElement, setSelectedHtmlElement] = useState<HTMLDivElement | null>(null)
    const [regions, setRegions] = useState<Region[]>([])
    const [alert, setAlert] = useState<AlertProps | undefined>()
    const {t} = useTranslation('bookings')

    useEffect(() => {
        if (partnerCwid) {
            setLoading(true)
            getShopSubscription(partnerCwid)
                .then((result) => setShopSubscription(result))
                .then((result) => getRegions().then(regions => setRegions(regions)))
                .catch(() => showAlert('getShopSubscriptionError'))
                .finally(() => setLoading(false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partnerCwid])

    const showAlert = (translationKey: string) => {
        setAlert({
            color: 'error',
            open: true,
            text: t(`booking.bookingsSettings.alerts.${translationKey}`),
            handleClose: () => setAlert(undefined)
        })
    }

    const actions: ShopSubscriptionSettingsActionsProps = {
        onAddNewShopSubscribedRegion: (element) => {
            setSelectedShopSubscribedRegion({country: 'DE'} as ShopSubscribedRegion)
            setSelectedHtmlElement(element)
        },
        onEditShopSubscribedRegion: (shopSubscribedRegion, element) => {
          setSelectedShopSubscribedRegion(shopSubscribedRegion)
          setSelectedHtmlElement(element)
        },
        onChangeShopNewsletter: (isSubscribed) => {
            if(shopSubscription) {
                updateShopSubscription({...shopSubscription, isSubscribed: isSubscribed})
                    .then((result) => setShopSubscription(result))
                    .catch(() => showAlert('updateShopSubscriptionError'))
            }
        },
        onChangeShopNewsletterFrequency: (frequencyPerDay) => {
            if(shopSubscription) {
                updateShopSubscription({...shopSubscription, frequencyPerDay: frequencyPerDay})
                    .then((result) => setShopSubscription(result))
                    .catch(() => showAlert('updateShopSubscriptionError'))
            }
        },
        onChangeSelectedCountryOrRegion: (value: string | number) => {
            if(typeof value === 'number') {
                const region = regions.find(region => region.id === value)
                setSelectedShopSubscribedRegion(prevState => ({...prevState, region: region, country: null, postalCode: null}))
            } else {
                setSelectedShopSubscribedRegion(prevState => ({...prevState, country: value, region: null}))
            }
        },
        onChangePostalCode: (postalCode) => {
            if(selectedShopSubscribedRegion?.country && !selectedShopSubscribedRegion?.region) {
                setSelectedShopSubscribedRegion(prevState => ({...prevState, postalCode: postalCode.trim().length === 0 ? null : postalCode}))
            }
        },
        onSaveShopSubscribedRegion: () => {
            // ignore if nothing changed
            setSelectedHtmlElement(null)
            if(selectedShopSubscribedRegion?.id) {
                const originalShopSubscribedRegion = shopSubscription?.regions.filter(region => region.id === selectedShopSubscribedRegion?.id)
                if(originalShopSubscribedRegion &&
                    originalShopSubscribedRegion[0]?.id === selectedShopSubscribedRegion?.id &&
                    originalShopSubscribedRegion[0]?.region?.id === selectedShopSubscribedRegion?.region?.id &&
                    originalShopSubscribedRegion[0]?.country === selectedShopSubscribedRegion?.country &&
                    originalShopSubscribedRegion[0]?.postalCode === selectedShopSubscribedRegion?.postalCode) {
                    setSelectedShopSubscribedRegion(undefined)
                    return
                }
            }
            if(shopSubscription && selectedShopSubscribedRegion) {
                updateShopSubscription({
                    ...shopSubscription,
                    regions: [...shopSubscription?.regions.filter(region => region.id !== selectedShopSubscribedRegion.id), selectedShopSubscribedRegion]
                })
                    .then((result) => setShopSubscription(result))
                    .catch(() => showAlert('updateShopSubscriptionError'))
                    .finally(() => {
                        setSelectedShopSubscribedRegion(undefined)
                    })
            }

        },
        onRemoveShopSubscribedRegion: () => {
            // ignore if try to delete not created subscribed region
            setSelectedHtmlElement(null)
            if(shopSubscription?.regions.filter(region => region.id === selectedShopSubscribedRegion?.id).length === 0) {
                setSelectedShopSubscribedRegion(undefined)
                return
            }
            if(shopSubscription && selectedShopSubscribedRegion) {
                updateShopSubscription({
                    ...shopSubscription,
                    regions: shopSubscription?.regions.filter(region => region.id !== selectedShopSubscribedRegion.id)
                })
                    .then((result) => setShopSubscription(result))
                    .catch(() => showAlert('updateShopSubscriptionError'))
                    .finally(() => {
                        setSelectedShopSubscribedRegion(undefined)
                    })
            }
        }
    }

    return {
        shopSubscription,
        selectedShopSubscribedRegion,
        selectedHtmlElement,
        allRegions: regions,
        isLoading,
        alert,
        actions
    }
}

export function useShopSubscriptionSettingsContext(): ShopSubscriptionSettingsContextProps {
    return useContext(ShopSubscriptionSettingsContext)
}