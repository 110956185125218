import {useMsal} from "@azure/msal-react";
import {useEffect, useState} from "react";
import {AccountInfo} from "@azure/msal-browser";
import {getUserHighestPrivileges, mapToSystemRole, UmzugEasyRole} from "../model/UmzugEasyRoles.model";

const useUserProfileInfo = (): [AccountInfo | undefined, UmzugEasyRole | undefined] => {
    const { accounts } = useMsal();
    const [userProfile, setUserProfile] = useState<AccountInfo | undefined>(undefined);
    const [userRole, setUserRole] = useState<UmzugEasyRole | undefined>(undefined);
    
    useEffect(() => {
        if(accounts[0]) {
            setUserProfile(accounts[0]);
            const userRoles =  accounts[0].idTokenClaims?.roles
                ?.map(mapToSystemRole)
                .filter(Boolean) as UmzugEasyRole[];
            setUserRole(getUserHighestPrivileges(userRoles));
        }
    }, [accounts]);
    
    return [userProfile, userRole];
}

export default useUserProfileInfo;