import {ContractResource, ContractStatus} from "api/contract/ContractResourcePage";
import {getContractsByPartnerCwid} from "api/contract/ContractsApi";
import {isAfterOrEqual, isBeforeOrEqual} from "utils/DateUtil";
import {startOfDay} from "date-fns";

export function isContractValid({startDate, endDate, contractStatus}: ContractResource): boolean {
    const now = startOfDay(new Date())
    return contractStatus === ContractStatus.CREATED
        && (startDate == null || isBeforeOrEqual(startDate, now))
        && (endDate == null || isAfterOrEqual(endDate, now));
}
export function getValidContractsByPartnerCwid(partnerCwid: string): Promise<ContractResource[]> {
    return getContractsByPartnerCwid(partnerCwid).then(contracts => contracts.filter(
        contract => isContractValid(contract)
        )
    )
}
