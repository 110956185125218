import {FC, ReactElement} from "react";
import {makeStyles} from "@mui/styles";
import {Box, Skeleton, Theme} from "@mui/material";

export type RelocationRequestDetailHeaderProps = {
    requestId: string;
    name: string;
    loading: boolean;
}

const relocationRequestDetailHeaderStyles = makeStyles((theme: Theme) => ({
    partnerId: {
        fontSize: "24px",
        fontWeight: "600",
        wordBreak: "break-all"
    },
    partnerName: {
        fontSize: "24px",
        whiteSpace: "nowrap",
        fontWeight: "400"
    }
}))

const RelocationRequestDetailHeader: FC<RelocationRequestDetailHeaderProps> = ({ requestId, name, loading }: RelocationRequestDetailHeaderProps): ReactElement => {
    const classes = relocationRequestDetailHeaderStyles()
    const commonSkeletonStyles = {
        fontSize: "24px",
        display: "inline-block"
    }

    return (
        loading ?
            <Box>
                <Skeleton sx={{ ...commonSkeletonStyles ,width: '850px', mr: '20px' }} variant="text" />
                <Skeleton sx={{ ...commonSkeletonStyles, width: '150px' }} variant="text" />
            </Box>
            :
            <Box>
                <Box component='span' className={classes.partnerId}>{`${requestId} `}</Box>
                <Box component='span' className={classes.partnerName}>{name}</Box>
            </Box>
    )
}
export default RelocationRequestDetailHeader
