import React from "react";
import {Radio, RadioProps, styled} from "@mui/material";

const StyledRadio = styled(Radio)(({ theme }) => ({
    color: theme.palette.primary.dark,
    '& .MuiSvgIcon-root': {
        fontSize: 20,
        padding: 0
    },
    '&.Mui-checked': {
        color: theme.palette.success.main,
    }
}));
const RadioButton = (props: RadioProps) => {
    return (
        <StyledRadio {...props} focusVisibleClassName=".Mui-focusVisible" disableRipple></StyledRadio>
    )
}
export default RadioButton;
