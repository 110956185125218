import React, {ReactElement, useState} from "react";
import { PartnerResource } from "../../../api/partner/PartnerResource";
import PartnerIdentification from "./mainData/PartnerIdentification";
import CompanyData from "./mainData/CompanyData";
import ContactData from "./mainData/ContactData";
import AdministrationData from "./mainData/AdministrationData";
import ContractsData from "./mainData/ContractsData";
import Section from "../../../components/form/Section";
import { useTranslation } from "react-i18next";
import { usePartnerContext } from "../shared/PartnerContext";
import LinkedAccountsData from './mainData/LinkedAccountsData';
import {PartnerSettingsResource} from "../../../api/partnerSettings/PartnerSettingsResource";
import { Formik } from "formik";
import {updatePartnerSettings} from "../../../api/partnerSettings/PartnerSettingsApi";
import {Shape} from "../../../components/form/FormInput";
import {boolean, object, string} from "yup";
import Alert, {AlertProps} from "../../../components/feedback/Alert";

export type PartnerDataProps = {
    partner?: PartnerResource
    partnerSettings?: PartnerSettingsResource
}

export interface PartnerSettingsDataForm extends Shape {
    alternativeMail?: string;
    additionalInformation?: string,
    vatId?: string,
    alternativeMailSelected?: boolean
}

const toPartnerSettingsDataForm = (partnerSettings?: PartnerSettingsResource): PartnerSettingsDataForm => {
    return {
        alternativeMail: partnerSettings?.alternativeMail || '',
        additionalInformation: partnerSettings?.additionalInformation || '',
        vatId: partnerSettings?.vatId || '',
        alternativeMailSelected: partnerSettings?.alternativeMailSelected
    };

}

export const schema = object<PartnerSettingsDataForm>().shape({
    alternativeMail: string().nullable().email(),
    alternativeMailSelected: boolean().required(),
    vatId: string().nullable(),
    additionalInformation: string().nullable(),
});

const MainData: React.FC = (): ReactElement => {
    const {t} = useTranslation('partner');
    const {partner, partnerSettings, assignNewPartnerSettings} = usePartnerContext()
    const [alert, setAlert] = useState<AlertProps | undefined>(undefined)

    const showAlert = (translationKey: string) => {
        setAlert({
            color: 'error',
            open: true,
            text: t(`mainData.alerts.${translationKey}`),
            handleClose: () => setAlert(undefined)
        })
    }

    const handleSubmit = (form: PartnerSettingsDataForm) => {
        if (partner != null && partnerSettings != null) {
            const stripToNull = (value: string | undefined) => value != null && value.trim().length > 0 ? value.trim() : undefined
            const updatePartnerSettingsRequest: PartnerSettingsResource = {
                ...partnerSettings,
                alternativeMail: stripToNull(form.alternativeMail),
                additionalInformation: stripToNull(form.additionalInformation),
                vatId: stripToNull(form.vatId),
                alternativeMailSelected: (() => {
                    if (stripToNull(form.alternativeMail) == null) {
                        return false
                    }
                    return form.alternativeMailSelected != null ? form.alternativeMailSelected : partnerSettings.alternativeMailSelected;
                })()
            }

            updatePartnerSettings(partner?.cwid, updatePartnerSettingsRequest)
                .then((result) => assignNewPartnerSettings(result))
                .catch(() => showAlert('unableToSaveChanges'));
        }
    }

    return (
        <>
            <Formik initialValues={toPartnerSettingsDataForm(partnerSettings)}
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={(values: PartnerSettingsDataForm) => handleSubmit(values)}>
                <>
                    <Section>
                        <PartnerIdentification/>
                    </Section>

                    <Section title={t('mainData.titles.companyData')}>
                        <CompanyData/>
                    </Section>

                    <Section title={t('mainData.titles.contactData')}>
                        <ContactData/>
                    </Section>

                    <Section title={t('mainData.titles.administrationData')}>
                        <AdministrationData/>
                    </Section>
                </>
            </Formik>

            <Section title={t('mainData.titles.groupOfPartners')}>
                <LinkedAccountsData />
            </Section>

            <Section title={t('mainData.titles.contractsData')}>
                <ContractsData partner={partner}/>
            </Section>

            <Alert text={''} {...alert} />
        </>
    );
};

export default MainData;
