import {Grid} from "@mui/material";
import React, {FC} from "react";
import {useDefaultTheme} from "../../../../../themeConfig";

type SelfPauseHistoryRowProps = {
    formattedDay: string,
    formattedTime: string,
    status: string
}
const SelfPauseHistoryRow: FC<SelfPauseHistoryRowProps> = ({formattedDay, formattedTime, status}) => {
    const theme = useDefaultTheme()
    return (
        <Grid container item xs={12} alignItems='center'
              sx={{
                  fontSize: '14px',
                  color: theme.umzugEasy.palette.darkGray,
                  textAlign: 'center',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  borderBottom: `0.5px solid ${theme.palette.primary.contrastText}`
              }}>
            <Grid item xs={4}>
                {formattedDay}
            </Grid>
            <Grid item xs={4}>
                {formattedTime}
            </Grid>
            <Grid item xs={4}>
                {status}
            </Grid>
        </Grid>
    )
}

export default SelfPauseHistoryRow