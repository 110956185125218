import {useFormikContext} from "formik";
import {getCountryCodes, ISOCountryCode} from "../../../../../utils/CountryUtil";
import {SelectField, SelectFieldOption} from "../../../../../components/form/SelectField";
import FilterLabelWithContent from "./FilterLabelWithContent";
import React from "react";

const CountryCodeSelectField = (props: { type: 'fromCountry' | 'toCountry' }) => {
    const formik = useFormikContext();
    const options = getCountryCodes().map((countryCode: ISOCountryCode): SelectFieldOption => ({
        text: countryCode.code,
        value: countryCode.code,
        key: countryCode.name
    }))
    const {value} = formik.getFieldProps(props.type)
    return (
        <FilterLabelWithContent type={props.type === 'fromCountry' ? 'from' : 'to'}>
            <SelectField id={props.type} onChange={formik?.handleChange}
                         sx={{minWidth: '120px'}}
                         initialValue={value || 'DE'} options={options}/>
        </FilterLabelWithContent>
    )
}

export default CountryCodeSelectField