import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import {PartnerFilters} from "./partner/PartnerFilters";
import {ContractFilters} from './contract/ContractFilters';

import {IPublicClientApplication, PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "../authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

const acquireAccessToken = async (msalInstance: IPublicClientApplication) => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();
    
    const account = activeAccount || accounts[0];

    if (!account) {
        throw new Error("User is not logged in");
    }

    const request = {
        scopes: [process.env.REACT_APP_AAD_BACKEND_SCOPE as string],
        account
    };

    const authResult = await msalInstance.acquireTokenSilent(request);
    
    return authResult.accessToken
};

export function getApiClient(): Promise<AxiosInstance> {
    const axiosInstance = axios.create();

    return acquireAccessToken(msalInstance)
        .then((token: string) => {
            axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
                return {
                    ...config,
                    headers: {
                        ...config.headers,
                        "Authorization": `Bearer ${token}`
                    }
                };
            });

            return axiosInstance;
        });
}

export function mapFilters(filter: PartnerFilters | ContractFilters): string { // add new type | NewType when you add new filter
    if (filter == null) {
        return '';
    }
    const filterAsAny = filter as any;
    return Object.keys(filterAsAny)
        .filter((key: string) => filterAsAny[key] != null)
        .filter((key: string) => filterAsAny[key].toString().trim().length > 0)
        .map(key => `${key}=${filterAsAny[key].toString()}`)
        .join("&")
}

export const apiVersion = '/api/v1'