import Label from "../../../../../../components/form/Label";
import {Box} from "@mui/material";
import Button from "../../../../../../components/form/Button";
import ActionImg from "../../../../../../components/shared/ActionImg";
import React, {FC} from "react";
import TourFlex from "./TourFlex";
import {useBookingModalContext} from "../BookingModalContext";

const Header: FC = () => {
    const {modalActions} = useBookingModalContext()
    return (
        <TourFlex
            left={<Label label={'Von'}/>}
            right={
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Label label={'Nach'}/>
                    <Button icon={<ActionImg icon={'/icons/plus.svg'} iconStyle={{marginRight: '5px'}}/>}
                            label={'Neue Tour'} onClick={() => modalActions.addTour()}
                            type={'button'} appearance={'link'}/>
                </Box>
            }
        />
    )
}
export default Header