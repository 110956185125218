import React from 'react';
import {BookingsSettings, DateRange} from "../../../../../api/bookingsSettings/BookingsSettingsResource";
import {AlertProps} from "../../../../../components/feedback/Alert";
import {DeletionConfirmationModalProps} from "../modal/DeletionConfirmationModal";
import {BookingsSettingsForm} from "./BookingsSettings";

const emptyFunction = () => {}

export type ExclusionDateRangeActionsProps = {
    onDeleteRelocationExclusionDateRange: (dateRange: DateRange) => void;
    onAddRelocationExclusionDateRange: (start: Date, end: Date) => void;
    onUpdateRelocationExclusionDateRange: (dateRange: DateRange, newDateRange: DateRange) => void;
}

export type BookingsSettingsContextProps = {
    bookingsSettings: BookingsSettings | undefined;
    partnerCwid?: string,
    isLoading: boolean;
    isSaving: boolean;
    alert?: AlertProps;
    exclusionDateRangeActions: ExclusionDateRangeActionsProps
    deletionConfirmationModal: DeletionConfirmationModalProps | undefined;
    saveChanges: (form: BookingsSettingsForm) => void;
}

export const BookingsSettingsContext = React.createContext<BookingsSettingsContextProps>({
    bookingsSettings: undefined,
    partnerCwid: undefined,
    isLoading: false,
    isSaving: false,
    exclusionDateRangeActions: {
        onDeleteRelocationExclusionDateRange: emptyFunction,
        onAddRelocationExclusionDateRange: emptyFunction,
        onUpdateRelocationExclusionDateRange: emptyFunction
    },
    deletionConfirmationModal: undefined,
    saveChanges: emptyFunction
});
