import React, {FC, ReactElement, useEffect} from "react";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {IconButton, inputBaseClasses, styled, textFieldClasses} from "@mui/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ClearIcon from '@mui/icons-material/Clear';
import {addOffset, reduceOffset} from "utils/DateUtil";

export type DatePickerProps = {
    initialValue: Date | null | undefined,
    sx?: SxProps<Theme>;
    onChange?: (date: Date | null | undefined) => void
    disableFuture?: boolean,
    disablePast?: boolean,
    isUTC?: boolean,
    shouldDisableDate?: (date: Date) => boolean,
    disabled?: boolean,
    placeholder?: string | undefined,
    clearable?: boolean,
    onClear?:  () => void
}
type CalendarIconProps = {
    title: string, onClick: () => void
}
const StyledDesktopDatePicker = styled(DesktopDatePicker<Date>)(({theme}) => ({
    [`&`]: {
        margin: "10px"
    },
    [`&.${textFieldClasses.root}`]: {
        borderRadius: "5px",
        color: theme.palette.common.black,
        backgroundColor: theme.palette.common.white,
        height: "40px"
    },
    [`& .${inputBaseClasses.root}`]: {
        borderRadius: "5px",
        color: theme.palette.common.black,
        height: "40px"
    },
    [`& .${textFieldClasses.root} > label`]: {
        display: "none"
    },
    [`& .${inputBaseClasses.root} input`]: {
        fontSize: "14px"
    },
    [`& .${inputBaseClasses.root} fieldset`]: {
        borderColor: `transparent !important`,
        backgroundColor: 'transparent',
        color: theme.palette.common.black
    }
}))

const DatePickerIcon = () => (<img src={'/icons/calendar.svg'} alt={'Show calendar'}/>)
const DisabledDatePickerIcon = () => (<img src={'/icons/calendar-disabled.svg'} alt={'Disabled calendar'}/>)
const CalendarIcons = (props: { icon: ReactElement } & CalendarIconProps) => (
    <IconButton color="inherit" aria-label="Left" component="label" sx={{width: '25px'}} title={props.title}
                onClick={props.onClick}>
        {props.icon}
    </IconButton>
)
const ChevronLeftIcon = (props: CalendarIconProps) => <CalendarIcons icon={<ChevronLeft/>} {...props}/>
const ChevronRightIcon = (props: CalendarIconProps) => <CalendarIcons icon={<ChevronRight/>} {...props}/>
const ArrowDropDownIcon = (props: CalendarIconProps) => <CalendarIcons icon={<ArrowDropDown/>} {...props}/>
const CustomClearIcon = () => <ClearIcon  htmlColor={'#0F2125'} sx={{maxWidth:'18px'}}/>

const DatePicker: FC<DatePickerProps> = (props: DatePickerProps): ReactElement => {
    const [value, setValue] = React.useState<Date | null | undefined>(null);

    const handleChange = (newValue: Date | null) => {
        setValue(newValue);
        props.onChange && props.onChange(newValue != null && props.isUTC ? reduceOffset(newValue) : newValue)
    };

    const shouldDisableDateForUTC = (date: Date) => {
        if(!props.shouldDisableDate) {
            return false
        }
        const dateWithReducedOffset = reduceOffset(date)
        if(!dateWithReducedOffset) {
            return false
        }
        return props.shouldDisableDate(dateWithReducedOffset)
    }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setValue(props.isUTC ? addOffset(props.initialValue) : props.initialValue), [props.initialValue])

    return (
        <StyledDesktopDatePicker
            label={null}
            components={{
                OpenPickerIcon: props.disabled? DisabledDatePickerIcon : DatePickerIcon,
                NextIconButton: ChevronRightIcon,
                PreviousIconButton: ChevronLeftIcon,
                SwitchViewButton: ArrowDropDownIcon,
                ClearIcon: CustomClearIcon
            }}
            value={value || null}
            onChange={handleChange}
            format="dd.MM.yyyy"
            disableFuture={props.disableFuture}
            disablePast={props.disablePast}
            shouldDisableDate={props.isUTC ? shouldDisableDateForUTC : props.shouldDisableDate}
            disabled={props.disabled}
            slotProps={{
                textField: { placeholder: props.placeholder },
                field: { clearable: props.clearable, onClear: props.onClear },
            }}
        />
    )
}

export default DatePicker
