import React, {FC, ReactElement} from "react";
import {Grid} from "@mui/material";
import Label from "../../../../components/form/Label";
import StyledTooltipWrapper from "../../../../components/shared/StyledTooltip";
import ToggleSwitch from "../../../../components/form/ToggleSwitch";
import DatePicker from "../../../../components/form/DatePicker";

export type QualitySealProps = {
    label: string,
    tooltipText: string,
    dateLabel: string,
    isQualitySealActive: boolean | undefined,
    qualitySealValidTo: string | null | undefined
}
const QualitySeal: FC<QualitySealProps> = ({label, tooltipText, dateLabel, isQualitySealActive, qualitySealValidTo}): ReactElement => {

    return (
        <>
            <Grid item xs={4}><Label label={label} sx={{textAlign: 'right'}}/></Grid>
            <Grid item xs={1}>
                <StyledTooltipWrapper
                    tooltipText={tooltipText}
                    placement={"top"}
                    children={
                        <ToggleSwitch disabled={true} checked={!!isQualitySealActive}/>
                    }
                />
            </Grid>
            <Grid item xs={1}><Label sx={{textAlign: 'right'}} label={dateLabel}/></Grid>
            <Grid item xs={4}>
                <StyledTooltipWrapper
                    tooltipText={tooltipText}
                    placement={"top"}
                    children={
                        <DatePicker
                            initialValue={qualitySealValidTo ? new Date(qualitySealValidTo) : null}
                            disabled={true}
                        />
                    }/>
            </Grid>
        </>
    )
}

export default QualitySeal