import React from "react";
import {Grid, Theme} from "@mui/material";
import {makeStyles} from "@mui/styles";

export type SectionStyles = {
    container?: React.CSSProperties,
    title?: React.CSSProperties,
    childrenContainer?: React.CSSProperties,
}

export type SectionProps = {
  title?: string | JSX.Element;
  children: JSX.Element | JSX.Element[];
  styles?: SectionStyles;
  dataTestId?: string
}

const sectionDefaultStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: "1rem",
    },
    title: {
        fontSize: "14px",
        color: theme.palette.text.primary,
        borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
        paddingBottom: "0.25rem",
        fontWeight: "700",
        fontStyle: "italic"
    },
    childrenContainer: {
        padding: "0.5rem 0"
    }
}));

const Section: React.FC<SectionProps> = (props: SectionProps) => {
    const classes = sectionDefaultStyles();

    return (
        <Grid data-testid={props.dataTestId} container className={classes.container} style={props.styles?.container}>
            {props.title && (<Grid item xs={12} className={classes.title} style={props.styles?.title}>
                {props.title}
            </Grid>)}
            <Grid container item xs={12} className={classes.childrenContainer} style={props.styles?.childrenContainer}>
                {props.children}
            </Grid>
        </Grid>
    );
};

export default Section;
