import {FC, ReactElement} from "react";
import {Box, Skeleton} from "@mui/material";
import {CSSProperties} from "@mui/styles";

export type MultipleRowSkeletonProps = {
    rows: number
    fontSize: string,
    width?: string,
    styles?: CSSProperties,
    dataTestid?: string,
}

const MultipleRowSkeleton: FC<MultipleRowSkeletonProps> = ({rows, fontSize, styles, dataTestid}: MultipleRowSkeletonProps): ReactElement => {
    return (
        <Box data-testid={dataTestid} sx={{ ...styles }}>
            { [...Array(rows)].map((_, index) =><Skeleton key={index} sx={{ fontSize: fontSize }} />) }
        </Box>
    )
}

export default MultipleRowSkeleton
