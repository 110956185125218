import React, {FC} from "react";
import {ToggleHistoryResource} from "api/toggleHistory/ToggleHistoryResource";
import CloseModalButton from "./CloseModalButton";
import NoHistoryContent from "./NoHistoryContent";
import LoadingElement from "./LoadingElement";
import {useTranslation} from "react-i18next";
import Modal from "../../../../../components/form/Modal";
import {Box, Grid} from "@mui/material";
import ModalTitle from "./ModalTitle";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import {useDefaultTheme} from "themeConfig";
import {ReactComponent as BulletList} from "../../../../../assets/icons/bullet-list.svg"
import {formatToLocaleTextWithDateTime} from "utils/DateUtil";
import ToggleHistoryRow from "./ToggleHistoryRow";

type ToggleHistoryModalProps = {
    isLoading: boolean,
    toggleHistory: ToggleHistoryResource[],
    isOpen: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ToggleHistoryModal: FC<ToggleHistoryModalProps> = ({isLoading, toggleHistory, isOpen, setOpen}) => {
    const {t} = useTranslation('dashboard')
    const historyElements: ReactJSXElement[] = []
    const theme = useDefaultTheme()

    const closeButton = <CloseModalButton label={t('toggleHistory.modal.button')} setOpen={setOpen}/>
    const noToggleHistoryContent = <NoHistoryContent text={t('toggleHistory.modal.noData')}/>
    const loadingElement: JSX.Element = <LoadingElement/>
    const titleElement = <ModalTitle
        text={t('toggleHistory.modal.title')}
        icon={<BulletList/>}
    />

    const hasToggleHistoryEntries = toggleHistory.length > 0

    toggleHistory
        .sort((a, b) => new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime())
        .forEach((entry, index) => {
            const date = formatToLocaleTextWithDateTime(entry.modifiedAt)
            const formattedDay = date.split(" ")[0]
            const formattedTime = date.split(" ")[1]

            function mapEntryValue(entryValue: string): string {
                if (entryValue === '1' || entryValue === 'true') {
                    return t('toggleHistory.modal.values.on');
                } else if (entryValue === '0' || entryValue === 'false') {
                    return t('toggleHistory.modal.values.off');
                } else {
                    return getFormattedValue(entryValue);
                }
            }

            function getFormattedValue(newValue: string): string {
                const numberValue = parseInt(newValue);
                return !!numberValue ? `${numberValue}%` : newValue
            }

            historyElements.push(
                <ToggleHistoryRow
                    key={index}
                    date={formattedDay}
                    time={formattedTime}
                    toggleName={entry.propertyName}
                    modifiedBy={entry.modifiedBy}
                    value={mapEntryValue(entry.newValue)}
                    text={entry.comment}
                />
            )
        })

    return (
        <Modal
            title={titleElement}
            isOpen={isOpen}
            children={
                <>
                    <Box>
                        <Grid container item xs={12} alignItems='center' sx={{
                            fontSize: '14px',
                            color: theme.umzugEasy.palette.darkGray,
                            fontWeight: '600',
                            backgroundColor: '#FFFFFF',
                            textAlign: 'center',
                            paddingTop: '15px',
                            paddingBottom: '15px'
                        }}>
                            <Grid item xs={2}>
                                {t('toggleHistory.modal.header.day')}
                            </Grid>
                            <Grid item xs={1}>
                                {t('toggleHistory.modal.header.time')}
                            </Grid>
                            <Grid item xs={2}>
                                {t('toggleHistory.modal.header.modifiedBy')}
                            </Grid>
                            <Grid item xs={3}>
                                {t('toggleHistory.modal.header.modifiedField')}
                            </Grid>
                            <Grid item xs={1}>
                                {t('toggleHistory.modal.header.status')}
                            </Grid>
                            <Grid item xs={2}>
                                {t('toggleHistory.modal.header.text')}
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{maxHeight: '50vh', overflow: 'auto', backgroundColor: "#fafafa"}}>
                        {isLoading ? loadingElement :
                            hasToggleHistoryEntries ? historyElements : noToggleHistoryContent}
                    </Box>
                    {closeButton}
                </>
            }
            width={'960px'}
            padding={'0'}
        />
    )
}

export default ToggleHistoryModal