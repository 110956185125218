import {apiVersion, getApiClient} from "../ApiUtils";
import {LicenseDuplicateCheckResponse, PartnerDocument, PartnerDocumentType} from "./PartnerDocumentsResource";
import { DocumentsModalForm } from '../../pages/partner/components/documents/DocumentsContext.model';
import { AxiosInstance } from 'axios';

const partnerDocumentsPath = (partnerCwid: string) => `/partners/${partnerCwid}/documents`
const downloadPartnerDocumentsPath = (partnerCwid: string, partnerDocumentId: number) => `/partners/${partnerCwid}/documents/${partnerDocumentId}/download`
const deletePartnerDocumentPath = (partnerCwid: string, partnerDocumentId: number) => `/partners/${partnerCwid}/documents/${partnerDocumentId}`
const uploadPartnerDocumentPath = (partnerCwid: string, partnerDocumentId?: number) => `/partners/${partnerCwid}/documents${partnerDocumentId ? `/${partnerDocumentId}` : '' }`
const changePartnerDocumentAvailabilityPath = (partnerCwid: string, partnerDocumentId: number) => `/partners/${partnerCwid}/documents/${partnerDocumentId}/availability`
const createAvailableEmptyPartnerDocumentMetadataPath = (partnerCwid: string, partnerDocumentType: PartnerDocumentType) => `/partners/${partnerCwid}/documents/${partnerDocumentType}`
const checkLicenseForDuplicatesPath = (partnerCwid: string, licenceNumber: string): string => `/partners/${partnerCwid}/documents/${licenceNumber}/duplicate`

export function getPartnerDocuments(partnerCwid: string): Promise<PartnerDocument[]> {
    return getApiClient()
        .then(client => client.get<PartnerDocument[]>(
            partnerDocumentsPath(partnerCwid),
            {baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
        ))
        .then(response => response.data)
}

export function downloadPartnerDocument(partnerCwid: string, partnerDocumentId: number): Promise<string> {
    return getApiClient()
        .then(client => client.get(
            downloadPartnerDocumentsPath(partnerCwid, partnerDocumentId),
            {baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion, responseType: "blob"}
        ))
        .then(response => URL.createObjectURL(response.data))
}

export function deletePartnerDocument(partnerCwid: string, partnerDocumentId: number): Promise<void> {
    return getApiClient()
        .then(client => client.delete(
            deletePartnerDocumentPath(partnerCwid, partnerDocumentId),
            {baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
        ))
        .then(response => {
            if (response.status !== 200) {
                throw new Error(`Cannot delete partner document with id: ${partnerDocumentId}`);
            }
        });
}

export function uploadPartnerDocument(partnerCwid: string, documentRequest: DocumentsModalForm, partnerDocumentId?: number): Promise<PartnerDocument> {
  const formData = new FormData()
  const meta = JSON.stringify({
    fileName: documentRequest.name || documentRequest.file?.name,
    type: documentRequest.type!!,
    licenseNumber: documentRequest.licenseNumber,
    expirationDate: documentRequest.expirationDate
  })
  if (documentRequest.file) formData.append('document', new Blob([documentRequest.file!!], { type: 'multipart/form-data' }), documentRequest.name!!)
  formData.append('metadata', new Blob([meta], { type: 'application/json' }))

  const createRequest = (client: AxiosInstance) => {
    const config = {baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion, headers: {'Content-Type': 'multipart/form-data' }}
    const path = uploadPartnerDocumentPath(partnerCwid, partnerDocumentId)
    return partnerDocumentId ? client.put(path, formData, config) : client.post(path, formData, config)
  }

  return getApiClient()
      .then(client => createRequest(client))
      .then(response => {
        if (response.status !== 200) {
          throw new Error(`Cannot upload partner ${partnerCwid} document: ${partnerDocumentId ? partnerDocumentId : '(new)'}`)
        }
        return response.data as PartnerDocument
      })
}

export function changePartnerDocumentAvailability(partnerCwid: string, partnerDocumentId: number, available: boolean): Promise<void> {
    return getApiClient()
        .then(client => client.post(
            changePartnerDocumentAvailabilityPath(partnerCwid, partnerDocumentId),
            { available: available },
            {baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
        ))
        .then(response => {
            if (response.status !== 200) {
                throw new Error(`Cannot change availability partner document with id: ${partnerDocumentId}`);
            }
        });
}

export function createAvailableEmptyPartnerDocumentMetadata(partnerCwid: string, type: PartnerDocumentType): Promise<PartnerDocument> {
    return getApiClient()
        .then(client => client.post(
            createAvailableEmptyPartnerDocumentMetadataPath(partnerCwid, type),
            undefined,
            {baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
        ))
        .then(response => {
            if (response.status !== 200) {
                throw new Error(`Cannot create available empty partner document metadata with type: ${type} for partner ${partnerCwid}`);
            }
            return response.data;
        });
}

export function checkLicenseForDuplicates(partnerCwid: string, licenceNumber: string): Promise<LicenseDuplicateCheckResponse> {
    return getApiClient()
        .then(client => client.get(
            checkLicenseForDuplicatesPath(partnerCwid, licenceNumber),
            {baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
        ))
        .then(response => response.data)
}