import {Booking} from "./BookingResource";
import {apiVersion, getApiClient} from "../ApiUtils";

const prefix = '/partners/'

const bookingsPathOf = (partnerCwid: string, postfix?: string) => `${partnersPathOf(partnerCwid, '/bookings')}${postfix || ''}`
const partnersPathOf = (partnerCwid: string, postfix?: string) => `${prefix}${partnerCwid}${postfix || ''}`

export function getBookings(partnerCwid: string): Promise<Booking[]> {
    return getApiClient().then(client => client.get<Booking[]>(
        bookingsPathOf(partnerCwid),
        { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data)
}
export function getBookingById(partnerCwid: string, bookingId: number): Promise<Booking> {
    return getApiClient().then(client => client.get<Booking>(
        bookingsPathOf(partnerCwid, `/${bookingId}`),
        { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data)
}
export function updateBooking(partnerCwid: string, bookingId: number, booking: Booking): Promise<Booking> {
    return getApiClient().then(client => client.put<Booking>(
        bookingsPathOf(partnerCwid, `/${bookingId}`),
        booking,
        { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data)
}
export function createBooking(partnerCwid: string, booking: Booking): Promise<Booking> {
    return getApiClient().then(client => client.post<Booking>(
        bookingsPathOf(partnerCwid),
        booking,
        { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data)
}
export function changeBookingActive(partnerCwid: string, bookingId: number, active: boolean): Promise<Booking> {
    return getApiClient().then(client => client.post<Booking>(
        bookingsPathOf(partnerCwid, `/${bookingId}/active`),
        {active: active},
        { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data)
}
export function deleteBookingTour(partnerCwid: string, bookingId: number, tourId: number): Promise<Booking> {
    return getApiClient().then(client => client.delete(
        bookingsPathOf(partnerCwid, `/${bookingId}/tour/${tourId}`),
        { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data)
}
export function deleteBooking(partnerCwid: string, bookingId: number): Promise<Booking> {
    return getApiClient().then(client => client.delete(
        bookingsPathOf(partnerCwid, `/${bookingId}`),

        { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data)
}
