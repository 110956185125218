import React, {FC} from "react";
import {Box, CircularProgress} from "@mui/material";

const LoadingElement: FC = () => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            height: '30vh'
        }}>
            <CircularProgress data-testid={'loading-element'} size={40} sx={{mr: '10px'}} color='inherit'/>
        </Box>
    )
}

export default LoadingElement