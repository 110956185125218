import React, {FC, ReactElement, useEffect, useState} from "react";
import {Box, Skeleton} from "@mui/material";
import {InventoryItem, InventoryRoom, RelocationRequestInventory} from "api/relocationRequest/RelocationRequestDetail";
import UmzugEasyBasicTable, {TableDataProps} from "components/basicTable/UmzugEasyBasicTable";
import {useTranslation} from "react-i18next";

type InventoryListDetailsProps = {
    inventory?: RelocationRequestInventory
    isLoading?: boolean
}

const useInventoryListDetailsToUmzugEasyBasicTable = () => {
    const {t} = useTranslation('inventory')
    const [relocationRequestInventory, setRelocationRequestInventory] = useState<RelocationRequestInventory | undefined>()
    const [rows, setRows] = useState<TableDataProps[][]>([])

    const getHeaders = () => {
        return [
            t('inventoryList.headers.room'),
            t('inventoryList.headers.itemName'),
            t('inventoryList.headers.quantity'),
            t('inventoryList.headers.cubicMeter'),
        ].map((header) => ({data: header}))
    }

    useEffect(() => {
        const distinctRoomNamesWithoutMisc = (inventory: RelocationRequestInventory) => {
            return Array.from(new Set((inventory.inventoryList?.items.filter(item => item.room !== InventoryRoom.MISC) || []).map((inventory) => inventory.roomName)))
        }
        const getMiscRoomName = (inventory: RelocationRequestInventory) => inventory.inventoryList?.items.find(item => item.room === InventoryRoom.MISC)?.roomName
        const getRoom = (inventory: RelocationRequestInventory, room: string) => {
            const roomInventory = (inventory.inventoryList?.items || []).filter((inventory) => inventory.roomName === room)
            return [
                [{data: room}, {data: ''}, {data: ''}, {data: ''}],
                ...getRoomInventory(roomInventory)
            ]
        }
        const getRoomInventory = (roomInventory: InventoryItem[]) => {
            return roomInventory.map((item) => [
                {data: ''},
                {data: item.name},
                {data: item.value},
                {data: item?.totalVolume?.toFixed(2) || ''}
            ]);
        }

        const getTotal = (inventory: RelocationRequestInventory) => {
            return [
                {data: t('inventoryList.total')},
                {data: ''},
                {data: inventory.inventoryList?.itemCount},
                {data: inventory.inventoryList?.totalVolume?.toFixed(2)}
            ]
        }

        if (relocationRequestInventory != null) {
            const newRows: TableDataProps[][] = []

            distinctRoomNamesWithoutMisc(relocationRequestInventory).forEach((room) => {
                newRows.push(...getRoom(relocationRequestInventory, room))
            })
            const miscRoomName = getMiscRoomName(relocationRequestInventory);
            if (miscRoomName) {
                newRows.push(...getRoom(relocationRequestInventory, miscRoomName))
            }
            newRows.push(getTotal(relocationRequestInventory))
            setRows(newRows)
        } else {
            setRows([])
        }
    }, [relocationRequestInventory, t])

    return {
        relocationInventoryUpdated: setRelocationRequestInventory,
        rows,
        headers: getHeaders()
    }
}

const InventoryListDetailsSkeleton: FC = (): ReactElement => {
    return (
        <>
            {
                Array.from(Array(8)).map((_, index) => index)
                    .map((index) => <Skeleton data-testid={`inventory-list-detail-sekeleton-${index}`} key={index}
                                              variant="text" width="100%" height={48}
                                              sx={{marginTop: '4px'}}/>)
            }
        </>
    )
}
const InventoryListDetails: FC<InventoryListDetailsProps> = ({inventory, isLoading}): ReactElement => {
    const {relocationInventoryUpdated, rows, headers} = useInventoryListDetailsToUmzugEasyBasicTable()

    useEffect(() => {
        relocationInventoryUpdated(inventory)
    }, [relocationInventoryUpdated, inventory])

    if (isLoading) {
        return <InventoryListDetailsSkeleton/>
    }
    if (inventory == null) {
        return <></>
    }
    return (
        <Box
            data-testid={'inventory-list-details-container'}
            sx={{
                flexGrow: 1,
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <UmzugEasyBasicTable sx={{
                '& tr > td': {
                    padding: '0 !important'
                }
            }} header={headers} rows={rows}/>
        </Box>
    );
};

export default InventoryListDetails;