import React from "react";
import {RegionAndPostalCode} from "../BookingTestContext.model";
import {
    BookingTourPostalCode,
    BookingTourRegion,
    BookingTourType
} from "../../../../../../../api/booking/BookingResource";

export type TestPopoverContent = {
    id?: number,
    regionId?: number,
    type?: BookingTourType,
    country?: string,
    postalCode?: string;
    isExcluded?: boolean;
}
const emptyFunction = () => {
    console.log('im empty')
}
export type BookingTestPopoverActionsProps = {
    remove: (remove: RegionAndPostalCode | undefined) => void,
    savePostalCode: (remove: RegionAndPostalCode | undefined, newPostalCode: BookingTourPostalCode) => void,
    saveRegion: (remove: RegionAndPostalCode | undefined, newRegion: BookingTourRegion) => void,
    changeSelectedCountryOrRegion: (value: string) => void,
    changeExclusion: (isExcluded: boolean) => void
    changePopoverPostalCode: (value: string) => void
}
export type BookingTestPopoverContextProps = {
    popoverTestContent: TestPopoverContent | undefined,
    popoverTestActions: BookingTestPopoverActionsProps;
}
export const BookingTestPopoverContext = React.createContext<BookingTestPopoverContextProps>({
    popoverTestContent: undefined,
    popoverTestActions: {
        remove: emptyFunction,
        savePostalCode: emptyFunction,
        saveRegion: emptyFunction,
        changeSelectedCountryOrRegion: emptyFunction,
        changeExclusion: emptyFunction,
        changePopoverPostalCode: emptyFunction
    }
})
