import {AlertProps} from "components/feedback/Alert";
import {PartnerApiClient} from "api/partnerApi/PartnerApiAdminResource";
import React from "react";

export type ApiClientsContextProps = {
    apiClients: PartnerApiClient[]
    isLoading: boolean
    alert?: AlertProps
}

export const ApiClientsContext = React.createContext<ApiClientsContextProps>({
    apiClients: [],
    isLoading: false
});