import React from 'react';
import {Grid} from "@mui/material";
import Section from "../../../../components/form/Section";
import {usePartnerContext} from "../../shared/PartnerContext";
import {useTranslation} from "react-i18next";
import QualitySeal from "./QualitySeal";
import OnlineStatus from 'pages/partner/components/dashboard/onlineStatus/OnlineStatus';
import PartnerUfcProfile from "pages/partner/components/dashboard/PartnerUfcProfile";
const General: React.FC = () => {

    const {
        partner,
        partnerSettings,
        isLoading,
        isPartnerOnline,
        getValidContracts,
        updatePartnerSettings
    } = usePartnerContext();
    const {t} = useTranslation('dashboard');
    const SPACING = 3;
    const ROW_ALIGN = 'center'

    return (
        <>
            <Section dataTestId={'general'} title={t('general.title')}>
                <Grid container item xs={6} spacing={SPACING} alignItems={ROW_ALIGN}>
                    <OnlineStatus partner={partner}
                                  partnerSettings={partnerSettings}
                                  updatePartnerSettingsCallback={updatePartnerSettings}
                                  isLoading={isLoading}
                                  isPartnerOnline={isPartnerOnline}
                                  validContracts={getValidContracts()}
                    />
                </Grid>
                <Grid container item xs={6} spacing={SPACING} alignItems={ROW_ALIGN}>
                    <QualitySeal
                        label={t('general.qualitySealLabel')}
                        tooltipText={t('general.tooltip')}
                        dateLabel={t('general.datePickerLabel')}
                        isQualitySealActive={partnerSettings?.isQualitySealActive}
                        qualitySealValidTo={partnerSettings?.qualitySealValidTo}
                    />
                </Grid>
            </Section>
            {partnerSettings?.ufcProfileUrl &&
                <Section dataTestId={'ufcProfile'} styles={{
                    container: {
                        paddingTop: '0',
                        paddingBottom: '0'
                    }
                }}>
                    <Grid container item xs={6} spacing={SPACING} alignItems={ROW_ALIGN}/>
                    <Grid container item xs={6} spacing={SPACING} alignItems={ROW_ALIGN}>
                        <PartnerUfcProfile
                            label={t('general.partnerUfcProfile')}
                            ufcProfileUrl={partnerSettings?.ufcProfileUrl}
                        />
                    </Grid>
                </Section>}
        </>
    )
}

export default General
