import {ScheduledPartnerPauseResource, SchedulePartnerPauseRequestResource} from "./ScheduledPartnerPauseResource";
import {apiVersion, getApiClient} from "../ApiUtils";

export function getScheduledPartnerPause(partnerCwid: string):  Promise<ScheduledPartnerPauseResource[]> {
    return getApiClient().then(client => client.get<ScheduledPartnerPauseResource[]>(
        `/partners/${partnerCwid}/settings/online-status-schedule`,
        {baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data);
}

export function addScheduledPartnerPause(partnerCwid: string, requestBody: SchedulePartnerPauseRequestResource): Promise<ScheduledPartnerPauseResource[]> {
    return getApiClient().then(client => client.post<ScheduledPartnerPauseResource[]>(
        `/partners/${partnerCwid}/settings/online-status-schedule`,
        requestBody,
        {baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => {
        if (response.status !== 201) {
            throw new Error(`Cannot create scheduled partner pause for ${partnerCwid}`);
        }
        return response.data
    })
}

export function deleteScheduledPartnerPause(partnerCwid: string, id: number): Promise<void>{
    return getApiClient().then(client => client.delete(
        `/partners/${partnerCwid}/settings/online-status-schedule/${id}`,
        {baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => {
        if (response.status !== 200) {
            throw new Error(`Cannot delete scheduled partner pause with id: ${id}`);
        }
    })
}