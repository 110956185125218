import {useContext, useEffect, useState} from "react";
import {ApiClientsContext, ApiClientsContextProps} from "pages/admin/components/apiClients/ApiClientsContext.model";
import {PartnerApiClient} from "api/partnerApi/PartnerApiAdminResource";
import {getPartnerApiClients} from "api/partnerApi/PartnerApiAdminApi";
import {AlertProps} from "components/feedback/Alert";
import {useTranslation} from "react-i18next";

export function useInitApiClientsContext(): ApiClientsContextProps {
    const [apiClients, setApiClients] = useState<PartnerApiClient[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [alert, setAlert] = useState<AlertProps | undefined>(undefined)
    const {t} = useTranslation('admin')

    const showAlert = (translationKey: string) => {
        setAlert({
            color: 'error',
            open: true,
            text: t(`apiClients.alerts.${translationKey}`),
            handleClose: () => setAlert(undefined)
        })
    }

    useEffect(() => {
        setLoading(true);
        getPartnerApiClients()
            .then(partnerApiClients => setApiClients(partnerApiClients))
            .catch(() => showAlert('listError'))
            .finally(() => setLoading(false));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return {
        apiClients,
        isLoading,
        alert
    }
}

export function useApiClientsContext(): ApiClientsContextProps {
    return useContext(ApiClientsContext)
}