import React, {FC, ReactElement, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    InvoicesStatisticsResource,
    YearlyInvoicesStatisticsResource
} from "../../../api/partner/InvoicesStatisticsResource";
import {getPartnerInvoicesStatistics} from "../../../api/partner/PartnerApi";
import {useParams} from "react-router-dom";
import YearlyInvoicesSummary from "./bills/YearlyInvoicesSummary";
import Grid from "@mui/material/Grid";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material";
import LoadableComponent from "../../../components/feedback/LoadableComponent";
import {AxiosError} from "axios";
import {extractApiErrorMessage} from "../../../utils/ApiErrorUtil";

const billsHeaderStyles: SxProps<Theme> = {
    padding: '25px 0 10px 0',
    borderBottom: (theme) => `1px solid ${theme.palette.primary.contrastText}`,
    fontSize: '14px',
    fontWeight: '700',
    fontStyle: 'italic'
}

const Bills: FC<any> = (): ReactElement => {
    const {t} = useTranslation('partner');
    const {cwid} = useParams();

    const [isLoadingInvoices, setLoadingInvoices] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [invoiceStatistics, setInvoiceStatistics] = useState<InvoicesStatisticsResource>({ yearlyInvoicesStatistics: [] })

    useEffect(() => {
        if (cwid) {
            setLoadingInvoices(true);
            getPartnerInvoicesStatistics(cwid)
                .then((result) => {
                    setInvoiceStatistics(result);
                    setLoadingInvoices(false);
                })
                .catch((error: Error | AxiosError) => {
                    setLoadingInvoices(false);

                    setErrorMessage(extractApiErrorMessage(error));
                });
        }

    }, [cwid])

    const getYearlyInvoicesSummaries = (): JSX.Element[] => {
        if (!invoiceStatistics.yearlyInvoicesStatistics.length) {
            return [<div key={0}>{t('bills.noInvoices')}</div>]
        }

        return invoiceStatistics.yearlyInvoicesStatistics
            .sort((a, b) => b.year - a.year)
            .map((yearlyInvoicesStatistics: YearlyInvoicesStatisticsResource) => (
                <YearlyInvoicesSummary key={yearlyInvoicesStatistics.year} yearlyInvoiceSummary={yearlyInvoicesStatistics} />
            ));
    }

    return (
        <Grid container>
            <Grid item xs={12} sx={billsHeaderStyles}>
                {t('bills.header')}
            </Grid>
            <Grid item xs={12}>
                <LoadableComponent isLoading={isLoadingInvoices} errorMessage={errorMessage} styles={{ childContainer: { paddingTop: '1rem' }}}>
                    {getYearlyInvoicesSummaries()}
                </LoadableComponent>
            </Grid>
        </Grid>
    );
};

export default Bills;
