import {Box, Theme, Typography} from "@mui/material";
import {Routes, routes, useActiveRoute} from "../../routes/Routes";
import React, {FC, ReactElement} from "react";
import {makeStyles, useTheme} from "@mui/styles";
import QuickSearch from "./components/QuickSearch";
import {MenuType} from "./components/NavigationLink";
import NavigationContent from "./components/NavigationContent";
import useUserProfileInfo from "../../hooks/useUserProfileInfo";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import ActionImg from "../shared/ActionImg";
import {useTranslation} from "react-i18next";
import {SxProps} from "@mui/system";
import {Link} from "react-router-dom";

const styles = makeStyles((theme: Theme) => ({
    logo: {
        objectFit: "contain",
        width: 106,
        alignItems: "center",
        display: "flex"
    }
}))

const Logo = () => {
    const classes = styles()
    return (
        <Link className={classes.logo} to={Routes.MAIN_PAGE}>
            <Box
                component="img"
                alt="umzug-easy"
                src={process.env.REACT_APP_LOGO_URL}
            />
        </Link>
    )
}

const userNameStyle: SxProps<Theme> = {
    fontSize: '14px',
    color: (theme) => theme.palette.success.main
};

const userRoleStyle: SxProps<Theme> = {
    fontSize: '12px',
    color: (theme) => theme.palette.secondary.contrastText
}

const NavigationNormal: FC = (): ReactElement => {
    const {t} = useTranslation('common');

    const theme = useTheme<Theme>()
    const activeRoute = useActiveRoute();
    const isSubRoutesExists = activeRoute.parent?.subRoutes && activeRoute.parent.subRoutes.filter(item => !item.hideInNav).length > 0;

    const isAuthenticated = useIsAuthenticated();
    const [userProfile, userRole] = useUserProfileInfo();
    const { instance } = useMsal();

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Box
                sx={{
                    flexGrow: 1,
                    height: "84px",
                    display: {xs: "none", md: "flex"},
                    maxWidth: theme.breakpoints.values.lg,
                    width: "100%"
                }}
            >
                <Logo/>
                {isAuthenticated && <NavigationContent
                    menuType={MenuType.ROOT}
                    activeRoute={activeRoute.parent}
                    routeList={routes.filter(item => !item.hideInNav)}
                />}

                {isAuthenticated && <Box sx={{
                    flexGrow: 1,
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "end",
                    'div + div':  {
                        padding: '0 30px',
                        '&:last-child': {
                            padding: 0
                        }
                    }
                }}>
                    <QuickSearch/>
                    <ActionImg icon={'/icons/offline.svg'} onClick={() => instance.logout() }/>
                    <div>
                        <Typography sx={userNameStyle}>{userProfile?.name}</Typography>
                        <Typography sx={userRoleStyle}>{t(`roles.${userRole}`)}</Typography>
                    </div>
                </Box>}
            </Box>
            {
                isSubRoutesExists &&
                <Box
                    sx={{
                        display: {xs: "none", md: "flex"},
                        minHeight: "100%",
                        minWidth: "100%",
                        paddingLeft: "2px",
                        maxWidth: theme.breakpoints.values.lg,
                        justifyContent: 'center',
                        height: "36px",
                        backgroundColor: theme.palette.warning.main
                    }}
                >
                    <Box sx={{display: {md: "flex"}, flexGrow: '1', maxWidth: theme.breakpoints.values.lg}}>
                        <NavigationContent
                            menuType={MenuType.SUBMENU}
                            activeRoute={activeRoute.child}
                            routeList={activeRoute?.parent?.subRoutes?.filter(item => !item.hideInNav) || []}
                        />
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default NavigationNormal;
