import {FC, ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {useUndistributedRequestsContext} from "./UndistributedRequestsContext";
import UmzugEasyBasicTable, {TableDataProps} from "components/basicTable/UmzugEasyBasicTable";
import {LeadGenerationResult} from "api/lead/LeadResources";
import {CollapsibleTableCellProps} from "components/collapsibleTable/UmzugEasyCollapsibleTable";
import {formatToLocaleTextWithDateTime} from "utils/DateUtil";
import {loadNextPageIfPossible} from "components/table/util/UmzugEasyTableFunctions";
import {formatNumberToEuroCurrency} from "utils/CurrencyUtil";
import {mapToCamelCasedString} from "utils/StringUtils";
import MultipleRowSkeleton from "components/feedback/MultipleRowSkeleton";
import {Box} from "@mui/material";

const columnsStyle = {
    requestId: {
        width: '200px',
        minWidth: '200px',
    },
    relocationType: {
        width: '150px',
        minWidth: '150px',
    },
    date: {
        width: '150px',
        minWidth: '150px',
    },
    price: {
        width: '75px',
        minWidth: '75px',
    },
    from: {
        width: '100px',
        minWidth: '100px',
    },
    to: {
        width: '100px',
        minWidth: '100px',
    },
    livingSpace: {
        width: '75px',
        minWidth: '75px',
    },
    reason: {
        width: '300px',
        minWidth: '300px',
    },
}

const cellPropsOf = (data: any, style?: any, align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'): CollapsibleTableCellProps => ({
    data,
    style,
    align
});

const camelCase = (text: string) => text.toLowerCase().replace(/_./g, x => x[1].toUpperCase())
const getTranslationKey = (prefix: string, value?: string) => value ? `${prefix}.${camelCase(value)}` : ''

const UndistributedLeadsList: FC = (): ReactElement => {
    const {t} = useTranslation(['undistributedRequests', 'algotest'])
    const {rejectedLeads, pageDetails, loading, actions} = useUndistributedRequestsContext()

    const displaySkeletonOnLoadingOr = (reactElement: ReactElement) => {
        return loading ?
            <MultipleRowSkeleton rows={20} fontSize='30px' styles={{width: '100%', lineHeight: 1.5}}/> : reactElement
    }
    const headerOf = (key?: string): TableDataProps => ({
        data: key ? t(`rejectedLeads.table.header.${key}`) : '',
        style: {paddingLeft: "0px"},
    });
    const header = [
        headerOf('requestId'),
        headerOf('relocationType'),
        headerOf('date'),
        headerOf('price'),
        headerOf('from'),
        headerOf('to'),
        headerOf('livingSpace'),
        headerOf('reason'),
    ];

    const rowOf = (rejectedLead: LeadGenerationResult): TableDataProps[] => [
        cellPropsOf(
            rejectedLead.relocationRequestId,
            columnsStyle.requestId
        ),
        cellPropsOf(
            t(`rejectedLeads.table.relocationType.${mapToCamelCasedString(rejectedLead.relocationType)}`),
            columnsStyle.relocationType
        ),
        cellPropsOf(
            formatToLocaleTextWithDateTime(rejectedLead.createdAt),
            columnsStyle.date
        ),
        cellPropsOf(
            formatNumberToEuroCurrency(rejectedLead.price),
            columnsStyle.price
        ),
        cellPropsOf(
            rejectedLead.fromZipCode,
            columnsStyle.from
        ),
        cellPropsOf(
            rejectedLead.toZipCode,
            columnsStyle.to
        ),
        cellPropsOf(
            `${rejectedLead.livingSpace} m²`,
            columnsStyle.livingSpace
        ),
        cellPropsOf(
            t(getTranslationKey('list.table.rejectionReason', rejectedLead.rejectionReason), {ns: 'algotest'}),
            columnsStyle.reason
        ),
    ]

    const rows = rejectedLeads.map(rejectedLead => rowOf(rejectedLead));

    return (
        <Box sx={{width: "100%"}}>
            {displaySkeletonOnLoadingOr(
                <UmzugEasyBasicTable
                    header={header}
                    rows={rows}
                    onLastRowRendered={() => loadNextPageIfPossible(pageDetails.number, pageDetails.totalPages, actions.onLoadNextPage)}
                />
            )}
        </Box>
    )
}

export default UndistributedLeadsList;
