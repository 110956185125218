import React, {FC} from "react";
import BookingSection from "../BookingSection";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import FormInput from "../../../../../components/form/FormInput";
import {useFormikContext} from "formik";

const CancellationRules: FC = () => {
    const {t} = useTranslation('bookings')

    const translateKeyPrefix = 'booking.bookingsSettings.cancellationRules.inputs.'
    const translationNameSpace = 'bookings'
    const valueAlign = 'left'

    const formik = useFormikContext()
    return (
        <BookingSection title={t('booking.bookingsSettings.cancellationRules.title')} isModalSection={false}>
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: 'min-content min-content',
                justifyItems: 'end',
                columnGap: '140px',
                marginLeft: '48px',
                paddingTop: '16px'
            }}>
                <FormInput translateKey={`${translateKeyPrefix}description`}
                           translationNameSpace={translationNameSpace}
                           formField={'cancellationRules'}
                           width={'830px'}
                           minFormWidth={'610px'}
                           type={'string'}
                           valueAlign={valueAlign}
                           multiline={true}
                           rows={4}
                           onBlur={() => formik.submitForm()}
                />
            </Box>
        </BookingSection>
    )
}

export default CancellationRules
