import {FC, ReactElement } from "react";
import {Box, Grid, Theme, useTheme} from "@mui/material";
import UmzugEasyCollapsibleTable, {
    CollapsibleTableCellProps,
    CollapsibleTableRowProps
} from "../../../../components/collapsibleTable/UmzugEasyCollapsibleTable";
import {TableDataProps} from "../../../../components/basicTable/UmzugEasyBasicTable";
import {useRequestsContext} from "./RequestsContext";
import {useTranslation} from "react-i18next";
import {formatToLocaleTextWithDateTime} from "../../../../utils/DateUtil";
import RelocationRequestDetailDetailsTab
    from "../../../relocationRequest/relocationRequestDetail/relocationRequestDetailsTab/RelocationRequestDetailDetailsTab";
import MultipleRowSkeleton from "../../../../components/feedback/MultipleRowSkeleton";
import {SxProps} from "@mui/system";
import LeadActions from "./LeadActions";
import {formatNumberToEuroCurrency} from "../../../../utils/CurrencyUtil";
import {mapToCamelCasedString} from "../../../../utils/StringUtils";
import {loadNextPageIfPossible} from "../../../../components/table/util/UmzugEasyTableFunctions";
import {LeadType, PartnerLead} from "../../../../api/lead/LeadResources";

const columnsStyle = {
   type: {
       width: '150px',
       minWidth: '150px',
   },
   source: {
       width: '100px',
       minWidth: '100px',
   },
   requestId: {
       width: '200px',
       minWidth: '200px',
   },
   relocationType: {
       width: '150px',
       minWidth: '150px',
   },
   date: {
       width: '150px',
       minWidth: '150px',
   },
   price: {
       width: '100px',
       minWidth: '100px',
   },
   sent: {
       width: '150px',
       minWidth: '150px',
   },
}

const cellPropsOf = (data: any, style?: any, align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'): CollapsibleTableCellProps => ({
    data,
    style,
    align
});

const LeadsList: FC = (): ReactElement => {
    const { partnerLeads, leadsByRequestId, openLeadDetailsId, loading, page, totalPages, anonymizationConfirmationModal, isAnonymizingRelocationRequest, actions } = useRequestsContext()
    const {t} = useTranslation('requests')
    const theme = useTheme();

    const displaySkeletonOnLoadingOr = (reactElement: ReactElement) => {
        return loading ? <MultipleRowSkeleton rows={20} fontSize='30px' styles={{ width: '100%', lineHeight: 1.5 }} /> : reactElement
    }

    const headerOf = (key?: string): TableDataProps => ({
        data: key ? t(`leads.table.header.${key}`) : '',
    });

    const leadTypeStyles = (theme: Theme, leadType: LeadType, gratis: boolean): SxProps<Theme> => {
        // @ts-ignore - TS compiler validate object keys
        const color = theme.umzugEasy.palette.lead[`${mapToCamelCasedString(leadType)}${gratis ? 'Gratis' : ''}`]
        return {
            height: '100%',
            paddingLeft: '7px',
            borderLeft: `4px solid ${color}`
        };
    }

    const displayType = (type: LeadType, gratis: boolean): string =>
        `${t(`leads.table.type.${mapToCamelCasedString(type)}`)}${gratis ? ` (${t('leads.table.type.gratis')})` : ''}`;

    const rowOf = (lead: PartnerLead): CollapsibleTableRowProps<string> => {
        const cells = [
            cellPropsOf(
                <Grid item sx={leadTypeStyles(theme, lead.leadType, lead.gratis)}>{displayType(lead.leadType, lead.gratis)}</Grid>,
                columnsStyle.type
            ),
            cellPropsOf(
                lead.relocationRequest.affiliatePartner,
                columnsStyle.source
            ),
            cellPropsOf(
                lead.relocationRequest.requestId,
                columnsStyle.requestId
            ),
            cellPropsOf(
                t(`leads.table.relocationType.${mapToCamelCasedString(lead.relocationRequest.type)}`),
                columnsStyle.relocationType
            ),
            cellPropsOf(
                formatToLocaleTextWithDateTime(lead.relocationRequest.createdAt),
                columnsStyle.date
            ),
            cellPropsOf(
                formatNumberToEuroCurrency(lead.price),
                columnsStyle.price
                ),
            cellPropsOf(
                formatToLocaleTextWithDateTime(lead.sent),
                columnsStyle.sent
                ),
            cellPropsOf(
                <LeadActions onSendAgain={() => {}} />
            ),
        ];
        const details = () => {
            return  cellPropsOf(
                <RelocationRequestDetailDetailsTab
                    relocationRequestDetail={lead.relocationRequest}
                    loading={loading}
                    onAnonymizeRelocationRequest={actions.onAnonymizeRelocationRequest}
                    anonymizationConfirmationModal={anonymizationConfirmationModal}
                    isAnonymizingRelocationRequest={isAnonymizingRelocationRequest}
                    leads={leadsByRequestId.get(lead.relocationRequest.requestId)}
                    customStyles={
                    {
                        sections: {
                                height: 'auto',
                                paddingTop: 'unset'
                            },
                        actionsBar: {
                            marginTop: '0px',
                            margin: '6px',
                            paddingRight: '15px',
                        }
                    }
                }
                />
            )
        }

        return {
            id: lead.relocationRequest.requestId,
            cells: cells,
            details: details,
        }
    }

    const header = [
        headerOf('type'),
        headerOf('source'),
        headerOf('requestId'),
        headerOf('relocationType'),
        headerOf('date'),
        headerOf('price'),
        headerOf('sent'),
        headerOf(),
    ];

    const rows = partnerLeads.map(lead => rowOf(lead));

    return (
      <>
          <Box sx={{width: "100%"}}>
              {displaySkeletonOnLoadingOr(
                  <UmzugEasyCollapsibleTable
                      header={header}
                      rows={rows}
                      open={openLeadDetailsId}
                      onRowClick={actions.onOpenPartnerLeadDetails}
                      onLastRowRendered={() => loadNextPageIfPossible(page, totalPages, actions.onLoadNextPage)}
                  />)}
          </Box>
      </>
    );
}

export default LeadsList;