import {Button as MuiButton, buttonBaseClasses, styled} from "@mui/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

export type ButtonProps = {
    label?: string,
    icon?: JSX.Element,
    appearance?: 'dark' | 'link',
    type?: 'submit' | 'reset' | 'button' | undefined,
    onClick?: () => void,
    variant?: 'outlined' | 'contained',
    sx?: SxProps<Theme>,
    disabled?: boolean
}
const StyledButton = styled(MuiButton)(({theme}) => ({
    [`&.style-dark.MuiButton-contained.${buttonBaseClasses.root}:not(.${buttonBaseClasses.disabled})`]: {
        backgroundColor: theme.palette.primary.dark,
    },
    [`&.style-dark.MuiButton-outlined.${buttonBaseClasses.root}:not(.${buttonBaseClasses.disabled})`]: {
        borderColor: theme.palette.primary.dark,
        color: theme.palette.primary.dark,
    },
    [`&.style-dark.${buttonBaseClasses.root}`]: {
        height: "40px",
        fontSize: "14px"
    },
    [`&.style-dark.MuiButton-contained.${buttonBaseClasses.root}:hover`]: {
        backgroundColor: theme.palette.secondary.light
    },
    [`&.style-dark.MuiButton-outlined.${buttonBaseClasses.root}:hover`]: {
        backgroundColor: theme.palette.action.active
    },
    [`&.style-link.${buttonBaseClasses.root}:not(.${buttonBaseClasses.disabled})`]: {
        backgroundColor: 'transparent',
    },
    [`&.style-link.${buttonBaseClasses.root}`]: {
        height: "40px",
        fontSize: "14px",
        color: theme.palette.warning.main,
        boxShadow: 'none'
    },
    [`&.style-link.${buttonBaseClasses.root}:hover`]: {
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
    }
}));

const Button = (props: ButtonProps) => {
    return (
        <StyledButton
            className={`style-${props?.appearance || 'dark'}`}
            disabled={props.disabled === true}
            type={props.type}
            sx={{
                "&::first-letter": {
                    textTransform: 'uppercase'
                },
                textTransform: 'none',
                ...(props?.sx || {})
            }}
            variant={props.variant || 'contained'}
            onClick={props.onClick} >
            {props.icon} {props.label}
        </StyledButton>)
}
export default Button;
