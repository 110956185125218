import {PartnerResource} from "../api/partner/PartnerResource";
import {PartnerSettingsResource} from "../api/partnerSettings/PartnerSettingsResource";
import {ContractResource} from "../api/contract/ContractResourcePage";

export const isPartnerOnline = (partner: PartnerResource,
                         partnerSettings: PartnerSettingsResource,
                         validContracts: Array<ContractResource>) => {
    if (validContracts.length === 0) {
        return false
    }
    if (partner?.isPausedByCustomerCare) {
        return false
    }
    return !(partner?.isSelfPaused || partnerSettings?.hasOverduePayments || !partner.isActiveInCrm);
}
