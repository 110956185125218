import React, {FC, ReactElement} from "react";
import {Box, Skeleton} from "@mui/material";
import {RelocationRequestInventory} from "api/relocationRequest/RelocationRequestDetail";
import {useTranslation} from "react-i18next";

type FreeTextInventoryDetailsProps = {
    inventory?: RelocationRequestInventory,
    hide?: boolean,
    isLoading?: boolean
}

const FreeTextInventoryDetailsSkeleton: FC = (): ReactElement => {
    return (
        <>
            <Skeleton data-testid={'free-text-inventory-skeleton-label'} variant="text" width="100%" height={33} sx={{marginTop: '24px'}}/>
            <Skeleton data-testid={'free-text-inventory-skeleton-value'} variant="text" width="100%" height={128}/>
        </>
    )
}
const FreeTextInventoryDetails: FC<FreeTextInventoryDetailsProps> = ({inventory, hide, isLoading}): ReactElement => {
    const {t} = useTranslation('inventory')
    if (isLoading) {
        return <FreeTextInventoryDetailsSkeleton/>
    }
    if (inventory == null || hide) {
        return <></>
    }
    return (
        <Box
            data-testid={'free-text-inventory-list-container'}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                fontSize: "14px",
                marginTop: '32px',
                '& > div': {
                    padding: "5px",
                },
                '& > div:first-child': {
                    width: "50%",
                    fontWeight: 600
                }
            }}
        >
            <div data-testid={'free-text-inventory-list-label'}>{t('freeText')}</div>
            <div data-testid={'free-text-inventory-list-value'}>{inventory.freeTextInventoryList}</div>
        </Box>
    );
};

export default FreeTextInventoryDetails;