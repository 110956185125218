import {useContext, useEffect, useState} from "react";
import {
    BookingPopoverActionsProps,
    BookingPopoverContext,
    BookingPopoverContextProps,
    PopoverContent
} from "./BookingPopoverContext.model";
import {BookingTour} from "../../../../../../../api/booking/BookingResource";
import {useBookingContext} from "../../../BookingContext";
import {
    getNewExclusionType,
    getPostalCodeFromPopoverContent,
    getRegionFromPopoverContent,
    getTourWithoutPostalCodeOrRegion,
    isPostalCodeEqual,
    isRegionEqual,
    regionAndPostalCodeToPopoverContent
} from "./BookingPopover.utils";
import {useBookingModalContext} from "../../BookingModalContext";

export function useBookingPopoverContext(): BookingPopoverContextProps {
    return useContext(BookingPopoverContext)
}

export function useInitBookingPopoverContext(): BookingPopoverContextProps {
    const [popoverContent, setPopoverContent] = useState<PopoverContent | undefined>(undefined)

    const {regions} = useBookingContext()
    const {editedTour, modalActions} = useBookingModalContext()

    useEffect(() => {
        setPopoverContent(regionAndPostalCodeToPopoverContent(editedTour?.originalRegionOrPostalCodeToEdit))
    }, [editedTour?.originalRegionOrPostalCodeToEdit])

    useEffect(() => {
        const shouldIncludeId = (editedTour?.originalRegionOrPostalCodeToEdit.region == null && popoverContent?.regionId == null) || (editedTour?.originalRegionOrPostalCodeToEdit.region != null && popoverContent?.regionId != null);
        const newPostalCode = getPostalCodeFromPopoverContent(shouldIncludeId, popoverContent)
        const newRegion = getRegionFromPopoverContent(shouldIncludeId, popoverContent)
        if (newPostalCode != null || newRegion != null) {
            const shouldChange = !(isPostalCodeEqual(editedTour?.editedRegionOrPostalCode.postalCode, newPostalCode) && isRegionEqual(editedTour?.editedRegionOrPostalCode.region, newRegion))
            if (shouldChange) {
                modalActions.updateEditedTour({postalCode: newPostalCode, region: newRegion})
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popoverContent])

    const popoverActions: BookingPopoverActionsProps = {
        remove: (remove) => {
            if (editedTour != null) {
                let newTour: BookingTour = getTourWithoutPostalCodeOrRegion(editedTour.tour, remove);
                modalActions.setTours((tours) => tours.map((item) => {
                    return item.uuid !== newTour.uuid ? item : newTour
                }))
                modalActions.clearEditedTour()
            }
        },
        savePostalCode: (remove, newPostalCode) => {
            if (editedTour != null) {
                let newTour: BookingTour = getTourWithoutPostalCodeOrRegion(editedTour.tour, remove);
                if (!isPostalCodeEqual(undefined, newPostalCode)) {
                    modalActions.setTours((tours) => tours.map((item) => {
                        return item.uuid !== newTour.uuid ? item : {
                            ...newTour,
                            bookingTourPostalCodes: [...newTour.bookingTourPostalCodes, newPostalCode]
                        }
                    }))
                }
                modalActions.clearEditedTour()
            }
        },
        saveRegion: (remove, newRegion) => {
            if (editedTour != null) {
                let newTour: BookingTour = getTourWithoutPostalCodeOrRegion(editedTour.tour, remove);
                if (!isRegionEqual(undefined, newRegion)) {
                    modalActions.setTours((tours) => tours.map((item) => {
                        return item.uuid !== newTour.uuid ? item : {
                            ...newTour,
                            bookingTourRegions: [...newTour.bookingTourRegions, newRegion]
                        }
                    }))
                }
                modalActions.clearEditedTour()
            }
        },
        changeSelectedCountryOrRegion: (value: string) => {
            const isRegion = regions.map(region => `${region.id}`).indexOf(`${value}`) !== -1;
            if (isRegion) {
                setPopoverContent(prevState => ({...prevState, regionId: +value, country: undefined}))
            } else {
                setPopoverContent(prevState => ({...prevState, regionId: undefined, country: value}))
            }
        },
        changeExclusion: (isExcluded) =>
            setPopoverContent(prevState => ({
                ...prevState,
                type: getNewExclusionType(prevState?.type, isExcluded),
                isExcluded
            })),
        changePopoverPostalCode: (value) =>
            setPopoverContent(prevState => ({...prevState, postalCode: value}))
    }

    return {popoverContent, popoverActions}
}