import React, {FC, ReactElement} from "react";
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

const Administration: FC<any> = (): ReactElement => {
    const {t} = useTranslation('common');
    return (
        <Box
            sx={{
                flexGrow: 1,
                backgroundColor: "whitesmoke",
                display: "block",
                color: 'black',
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Typography variant="h3">{t('nav.administration')}</Typography>
        </Box>
    );
};

export default Administration;