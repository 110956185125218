import React, {FC, ReactElement} from "react";
import Section, {SectionStyles} from "components/form/Section";
import {Grid} from "@mui/material";
import LabeledField from "components/form/LabeledField";
import {useTranslation} from "react-i18next";
import {PartnerApiClient} from "api/partnerApi/PartnerApiAdminResource";

export type ApiClientsItemProps = {
    apiClient: PartnerApiClient
}

const sectionStyles: SectionStyles = {
    container: {
        paddingLeft: '0'
    }
}

const ApiClientsItem: FC<ApiClientsItemProps> = (props: ApiClientsItemProps): ReactElement => {
    const {t} = useTranslation('admin');

    return (
        <Section
            title={props.apiClient.appName}
            styles={sectionStyles}
        >
            <Grid container>
                <Grid container item xs={8} md={4}>
                    <LabeledField label={t('apiClients.list.item.clientId')}
                                  children={<>{props.apiClient.clientId}</>}/>
                    <LabeledField label={t('apiClients.list.item.secret')} children={<>{props.apiClient.secret}</>}/>
                </Grid>

                <Grid container item xs={8} md={4}>
                    <LabeledField label={t('apiClients.list.item.apiVersion')}
                                  children={<>{props.apiClient.apiVersion}</>}/>
                    <LabeledField label={t('apiClients.list.item.resources')}
                                  children={<>{props.apiClient.templates.join(", ")}</>}/>
                </Grid>
            </Grid>
        </Section>
    )
}

export default ApiClientsItem;