import {FC, ReactElement} from "react";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {RelocationRequestDetail} from "../../../../../api/relocationRequest/RelocationRequestDetail";
import {formatToLocaleTextWithDate} from "../../../../../utils/DateUtil";
import RealEstateDetailsSubsection from "./RealEstateDetailsSubsection";
import MoveInFeaturesSubsection from "./MoveInFeaturesSubsection";

export type RelocationToSubsectionProps = {
    relocationRequestDetail: RelocationRequestDetail
}
const fromToSectionKeyOf = (postfix: string): string => `requestDetails.detailsTab.sections.fromTo.${postfix}`;

const RelocationToSubsection: FC<RelocationToSubsectionProps> = ({ relocationRequestDetail: {toObject, features, type} }: RelocationToSubsectionProps): ReactElement => {
    const {t} = useTranslation('relocationRequests')
    const yes = t(fromToSectionKeyOf('features.yes'))
    const no = t(fromToSectionKeyOf('features.no'))

    if(!toObject) {
        return <MoveInFeaturesSubsection relocationFeatures={features} relocationType={type} />
    }

    const { relocationDate, street, postalCode, city, realEstateType, hasElevator, additionalInfo } = toObject
    return (
        <Box>
            <Box>{ relocationDate && t(fromToSectionKeyOf('relocationToDate'), {date: formatToLocaleTextWithDate(relocationDate)}) }</Box>
            <Box>{ street && street }</Box>
            <Box sx={{mb: 2.5}}>{ postalCode } { city }</Box>
            <Box>{ toObject && realEstateType && <RealEstateDetailsSubsection relocationRequestDetails={toObject} /> }</Box>
            <Box sx={{my: 2.5}}>{ hasElevator !== undefined && t(fromToSectionKeyOf('features.moveIn.elevator'), {elevator: hasElevator ? yes : no}) }</Box>
            <Box><MoveInFeaturesSubsection relocationFeatures={features} relocationType={type} /></Box>
            { additionalInfo && <Box sx={{mt: 2.5}}>{ t(fromToSectionKeyOf('additionalInformationTo'), {info: additionalInfo}) }</Box> }
        </Box>
    )
}

export default RelocationToSubsection
