import {PartnerResource, PartnerResourcePage} from "./PartnerResource";
import {apiVersion, getApiClient, mapFilters} from "../ApiUtils";
import {Page} from "../Page";
import {PartnerFilters} from "./PartnerFilters";
import {InvoicesStatisticsResource} from "./InvoicesStatisticsResource";

export function getPartners(page: number = 0, size: number = 20, filters: PartnerFilters): Promise<Page<{ partners: PartnerResource[] }>> {
    const filterString = mapFilters(filters);
    return getApiClient().then(client => client.get<Page<PartnerResourcePage>>(
        `/partners?page=${page}&size=${size}&${filterString}`, 
        { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data);
}

export function getPartner(cwid: string): Promise<PartnerResource> {
    return getApiClient().then(client => client.get<PartnerResource>(
        `/partners/${cwid}`, 
        { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion}
    )).then(response => response.data);
}


export function getPartnerInvoicesStatistics(cwid: string): Promise<InvoicesStatisticsResource> {
    return getApiClient().then(client => client.get<InvoicesStatisticsResource>(
        `/invoices/statistics?customerCwid=${cwid}`,
        { baseURL: process.env.REACT_APP_UMZUG_EASY_BACKEND_DOMAIN + apiVersion }
    )).then(response => response.data);
}
