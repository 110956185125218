import React, {FC, ReactElement} from "react";
import {Box, Skeleton} from "@mui/material";
import {useTranslation} from "react-i18next";
import {RelocationRequestDetail} from "api/relocationRequest/RelocationRequestDetail";
import {formatToIsoTextWithDate, formatToLocaleTextWithDateTime} from "utils/DateUtil";

type LeadDetailsProps = {
    details?: RelocationRequestDetail,
    isLoading?: boolean
    hide?: boolean
}
type SingleLeadDetailsProps = {
    name: string,
    value?: string
}

const SingleLeadDetails: FC<SingleLeadDetailsProps> = ({name, value}): ReactElement => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontSize: "14px",
                '& > div': {
                    padding: "5px",
                },
                '& > div:first-child': {
                    width: "50%",
                },
                '& > div:last-child': {
                    fontWeight: 600
                }
            }}
        >
            <div>{name}</div>
            <div>{value || '-'}</div>
        </Box>
    );
}

const LeadDetailsSkeleton: FC = (): ReactElement => {
    return (
        <>
            {
                Array.from(Array(10)).map((_, index) => index)
                    .map((index) => <Skeleton data-testid={`lead-details-skeleton-${index}`}
                                              key={index} variant="text" width="100%" height={24}
                                              sx={{marginTop: '4px'}}/>)
            }
        </>
    )
}
const LeadDetails: FC<LeadDetailsProps> = ({details, isLoading, hide}): ReactElement => {
    const {t} = useTranslation('inventory')

    if (hide) {
        return <></>
    }
    if (details == null || isLoading) {
        return <LeadDetailsSkeleton/>
    }
    const {fromObject, toObject} = details
    return (
        <Box
            data-testid={'lead-details-container'}
            sx={{
                flexGrow: 1,
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <SingleLeadDetails
                name={t('leadDetails.requestId')}
                value={details.anfrageId}
            />
            <SingleLeadDetails
                name={t('leadDetails.date')}
                value={formatToLocaleTextWithDateTime(details.createdAt)}
            />
            <SingleLeadDetails
                name={t('leadDetails.payment')}
                value={t(`paymentType.${details.payment}`)}
            />
            <SingleLeadDetails
                name={t('leadDetails.relocationOutDate')}
                value={formatToIsoTextWithDate(fromObject?.relocationDate)}
            />
            <SingleLeadDetails
                name={t('leadDetails.relocationInDate')}
                value={formatToIsoTextWithDate(toObject?.relocationDate)}
            />
            <SingleLeadDetails
                name={t('leadDetails.outZipCode')}
                value={`${fromObject?.iso2CountryCode || ''}-${fromObject?.postalCode || ''} ${fromObject?.city || ''}`}
            />
            <SingleLeadDetails
                name={t('leadDetails.inZipCode')}
                value={`${toObject?.iso2CountryCode || ''}-${toObject?.postalCode || ''} ${toObject?.city || ''}`}
            />
            <SingleLeadDetails
                name={t('leadDetails.outCity')}
                value={fromObject?.city || ''}
            />
            <SingleLeadDetails
                name={t('leadDetails.inCity')}
                value={toObject?.city || ''}
            />
            <SingleLeadDetails
                name={t('leadDetails.livingSpaceArea')}
                value={`${fromObject.livingSpaceAreaSqm || ''} m²`}
            />
        </Box>
    );
};

export default LeadDetails;