import {Box} from "@mui/material";
import {FC, ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {formatToLocaleTextWithDateTime} from "../../../../../utils/DateUtil";
import {RelocationRequestDetail, RelocationType} from "../../../../../api/relocationRequest/RelocationRequestDetail";

export type RelocationTypeSubsectionProps = {
    relocationRequestDetail: RelocationRequestDetail
}

const {EXTRA_CARGO, RELOCATION, CLEAR_OUT, COMPANY_RELOCATION, STORAGE, PIANO} = RelocationType;
const relocationTypeToTranslationKeyMap = {
    [RELOCATION]: 'private',
    [COMPANY_RELOCATION]: 'company',
    [STORAGE]: 'storage',
    [CLEAR_OUT]: 'clearOut',
    [PIANO]: 'piano',
    [EXTRA_CARGO]: 'extraCargo',
}

const translationKeyOf = (type: RelocationType): string => relocationTypeToTranslationKeyMap[type]
const RelocationTypeSubsection: FC<RelocationTypeSubsectionProps> = ({relocationRequestDetail}: RelocationTypeSubsectionProps): ReactElement => {
    const {t} = useTranslation('relocationRequests')
    const translationKey = translationKeyOf(relocationRequestDetail.type)

    return (
        <Box>
            <Box>{t(`requestDetails.detailsTab.sections.relocationType.type.${translationKey}`)} </Box>
            <Box>{t('requestDetails.detailsTab.sections.relocationType.createdAt', {dateTime: formatToLocaleTextWithDateTime(relocationRequestDetail.createdAt)})} </Box>
        </Box>
    )
}

export default RelocationTypeSubsection
