import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/styles";
import {Box, Theme} from "@mui/material";
import {useFormikContext} from "formik";
import {allRelocationTypes} from "../../../../../../api/relocationRequest/RelocationRequestDetail";
import BookingSection from "../../BookingSection";
import Checkbox from "../../../../../../components/form/Checkbox";
import {insertOrRemoveValue} from "../../../../../../utils/FormUtils";
import Label from "../../../../../../components/form/Label";
import {mapToCamelCasedString} from "../../../../../../utils/StringUtils";
import React, {FC} from "react";

const RelocationTypes: FC = () => {
    const {t} = useTranslation('bookings')
    const theme = useTheme<Theme>()
    const formik = useFormikContext();
    const fieldProps = formik.getFieldProps('relocationTypes')
    return (
        <BookingSection title={t('booking.edit.sections.relocationType')} isModalSection={true}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                [theme.breakpoints.down('sm')]: {
                    flexWrap: 'wrap',
                    justifyContent: 'start',
                    columnGap: '20px'
                }
            }}>
                {
                    allRelocationTypes.map(relocationType => (
                        <Box key={relocationType} sx={{display: 'flex'}}>
                            <Checkbox id={fieldProps.name}
                                      onChange={() => formik?.setFieldValue(fieldProps.name, insertOrRemoveValue(fieldProps.value, relocationType))}
                                      checked={fieldProps.value.indexOf(relocationType) !== -1}/>
                            <Label label={t(`relocationType.${mapToCamelCasedString(relocationType)}`)}/>
                        </Box>
                    ))
                }
            </Box>
        </BookingSection>
    )
}
export default RelocationTypes