import React, {FC, ReactElement} from "react";
import {Box, Theme} from "@mui/material";
import {useTheme} from "@mui/styles";

type BookingSectionAction = {
    text: string;
    onClick: () => void;
}
export type BookingSectionProps = {
    title: string;
    action?: BookingSectionAction;
    children: JSX.Element;
    isModalSection: boolean;
}

const Title = (props: { title: string, isModalSection: boolean }) => {
    const theme = useTheme<Theme>()
    return (
        <Box sx={{
            fontStyle: 'normal',
            fontWeight: 600,
            color: (props.isModalSection) ? theme.palette.primary.dark : theme.palette.text.secondary
        }}>
            {props.title}
        </Box>
    );
}
const TitleAction = ({action}: { action?: BookingSectionAction }) => {
    const theme = useTheme<Theme>()
    return (
        <>
            {action && <Box sx={{color: theme.palette.warning.main, fontWeight: 400, cursor: 'pointer'}}
                            onClick={action.onClick}>{action.text}</Box>}
        </>
    );
};
const BookingSection: FC<BookingSectionProps> = ({
                                                     children,
                                                     title,
                                                     action,
                                                     isModalSection
                                                 }: BookingSectionProps): ReactElement => {
    const theme = useTheme<Theme>()
    return (
        <Box sx={{paddingTop: '22px', width: '100%'}}>
            <Box sx={{
                lineHeight: '20px',
                borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
                fontSize: '14px',
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '4px'
            }}>
                <Title title={title} isModalSection={isModalSection}/>
                <TitleAction action={action}/>
            </Box>
            {children}
        </Box>
    );
};

export default BookingSection;
