import React, {FC} from "react";
import {Grid} from "@mui/material";
import {useDefaultTheme} from "../../../../../themeConfig";

type ToggleHistoryRowProps = {
    date: string,
    time: string,
    modifiedBy: string,
    toggleName: string,
    value: string,
    text: string | undefined
}

const ToggleHistoryRow: FC<ToggleHistoryRowProps> = ({date, toggleName, time, text, value, modifiedBy}) => {
    const theme = useDefaultTheme()
    return (
        <Grid container item xs={12} alignItems='center'
              sx={{
                  fontSize: '14px',
                  color: theme.umzugEasy.palette.darkGray,
                  textAlign: 'center',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                  borderBottom: `0.5px solid ${theme.palette.primary.contrastText}`
              }}>
            <Grid item xs={2}>
                {date}
            </Grid>
            <Grid item xs={1}>
                {time}
            </Grid>
            <Grid item xs={2}>
                {modifiedBy}
            </Grid>
            <Grid item xs={3} sx={{textWrap: 'wrap'}}>
                {toggleName}
            </Grid>
            <Grid item xs={1}>
                {value}
            </Grid>
            <Grid item xs={2} sx={{textWrap: 'wrap'}}>
                {text}
            </Grid>
        </Grid>
    )
}

export default ToggleHistoryRow