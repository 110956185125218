import {Grid, Theme} from "@mui/material";
import React from "react";
import {makeStyles} from "@mui/styles";
import ActionImg from "../../../components/shared/ActionImg";
import PartnerAdditionalData from "../components/mainData/PartnerAdditionalData";

export type PartnerHeaderInformationProps = {
    partnerName?: string;
    isPartnerOnline: boolean;
    accountOwner?: string;
    scoutId?: number;
    email?: string;
}

const partnerHeaderStyles = makeStyles((theme: Theme) => ({
    container: {
        borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
        paddingBottom: '0.5rem'
    },
    icon: {
        height: '33px'
    },
    companyName: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '33px'
    }
}));

type StatusIconProps = {
    isOnline: boolean
}

const StatusIcon: React.FC<StatusIconProps> = ({isOnline}: StatusIconProps) => isOnline ?  <ActionImg icon={'/icons/online.svg'} tooltip={'Online'}/> :  <ActionImg icon={'/icons/offline.svg'} tooltip={'Offline'}/>

const PartnerHeader: React.FC<PartnerHeaderInformationProps> = ({partnerName, isPartnerOnline, accountOwner, scoutId, email}: PartnerHeaderInformationProps) => {
    const classes = partnerHeaderStyles();

    return (
        <Grid container>
            <Grid container alignItems='flex-start' className={classes.container}>
                <Grid container item xs={12} md={6} lg={8} alignItems='flex-start' direction="row" columnSpacing={2}>
                    <Grid container item xs='auto' alignItems='center' className={classes.icon}>
                        <img src={'/icons/truck.svg'} alt={'Moving Company symbol'}/>
                    </Grid>
                    <Grid container item xs='auto' direction='column'>
                        <Grid item className={classes.companyName}>
                            {partnerName}
                        </Grid>
                        <PartnerAdditionalData accountOwner={accountOwner} scoutId={scoutId} email={email} />
                    </Grid>
                </Grid>
                <Grid container item xs={12} md={6} lg={4} justifyContent='space-between'>
                    <StatusIcon isOnline={isPartnerOnline} />
                    <ActionImg icon={'/icons/S.svg'}/>
                    <ActionImg icon={'/icons/refresh.svg'}/>
                    <ActionImg icon={'/icons/euro.svg'}/>
                    <ActionImg icon={'/icons/salesforce.svg'}/>
                    <ActionImg icon={'/icons/M.svg'}/>
                    <ActionImg icon={'/icons/shield.svg'}/>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default PartnerHeader;
