import {Typography} from "@mui/material";
import React, {FC, ReactElement} from "react";

export type FormLabelProps = {
    title: string;
    fontSize?: string;
    fontWeight?: number;

    customStyles?: React.CSSProperties;
}

const FormLabel: FC<FormLabelProps> = (props: FormLabelProps): ReactElement => (
    <Typography
        fontWeight={props.fontWeight || 600}
        textAlign="left"
        style={{
            textTransform: 'capitalize',
            color: "black",
            marginLeft: "20px",
            fontSize: props.fontSize || "14px",
            ...(props?.customStyles || {})
        }}
        data-testid={'formlabel'}
    >
        {props.title}
    </Typography>
)

export default FormLabel;