import {RelocationFeature, RelocationType} from "../../../../../api/relocationRequest/RelocationRequestDetail";
import {FC, ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";

export type RelocationFeaturesSubsectionProps = {
    relocationFeatures: Array<RelocationFeature>
    relocationType: RelocationType
}

const MoveInFeaturesSubsection: FC<RelocationFeaturesSubsectionProps> = ({relocationFeatures, relocationType} : RelocationFeaturesSubsectionProps): ReactElement => {
    const {t} = useTranslation('relocationRequests')
    const translationPathPrefix = 'requestDetails.detailsTab.sections.fromTo.features'
    const yes = t(`${translationPathPrefix}.yes`)
    const no = t(`${translationPathPrefix}.no`)
    const client = t(`${translationPathPrefix}.client`)
    const spedition = t(`${translationPathPrefix}.spedition`)
    const moveInKeyOf = (postfix: string) => `${translationPathPrefix}.moveIn.${postfix}`
    const hasFeature = (feature: RelocationFeature, truthyString: string, falsyString: string) => {
        return relocationFeatures.includes(feature) ? truthyString : falsyString
    }
    const isPrivate = relocationType === RelocationType.RELOCATION
    const isCorporate = relocationType === RelocationType.COMPANY_RELOCATION
    const isPrivateOrCorporate = isPrivate || isCorporate

    return (
        <>
            { isPrivateOrCorporate && <Box>{ t(moveInKeyOf('subheader')) }</Box> }
            { isPrivateOrCorporate && <Box>{ t(moveInKeyOf('unpack'), {clientOrSpedition: hasFeature(RelocationFeature.UNPACK, spedition, client)}) }</Box> }
            { isPrivateOrCorporate && <Box>{ t(moveInKeyOf('furnitureAssembly'), {clientOrSpedition: hasFeature(RelocationFeature.FURNITURE_ASSEMBLY, spedition, client)}) }</Box> }
            { isPrivate && <Box>{ t(moveInKeyOf('kitchenAssembly'), {clientOrSpedition: hasFeature(RelocationFeature.KITCHEN_ASSEMBLY, spedition, client)}) }</Box> }
            { isCorporate && <Box>{ t(moveInKeyOf('officeEquipmentOrMachineAssembly'), {clientOrSpedition: hasFeature(RelocationFeature.OFFICE_EQUIPMENT_OR_MACHINE_ASSEMBLY, spedition, client)}) }</Box> }
            { isCorporate && <Box>{ t(moveInKeyOf('disposal'), {disposal: hasFeature(RelocationFeature.DISPOSAL_TO, spedition, client)}) }</Box> }
            { isPrivateOrCorporate && <Box>{ t(moveInKeyOf('noParkingZoneTo'), {noParking: hasFeature(RelocationFeature.NO_PARKING_ZONE_TO, yes, no)}) }</Box> }
            { isPrivate && <Box>{ t(moveInKeyOf('storage'), {storage: hasFeature(RelocationFeature.STORAGE, yes, no)}) }</Box> }
        </>
    )
}

export default MoveInFeaturesSubsection
