import React, {FC} from "react";
import {Box} from "@mui/material";
import BookingSection from "../../../../partner/components/bookings/BookingSection";
import FormInput from "../../../../../components/form/FormInput";
import FieldIcon from "../../../../../components/form/FieldIcon";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import {BookingTestForm} from "./FormFields";

const DetailsSettings: FC = () => {
    const {t} = useTranslation('bookingTest')
    const translationNameSpace = 'bookingTest'
    const minFormInputWidth = '150px'
    const minWidth = '170px'
    const translateKeyPrefix = 'form.'
    const formik = useFormikContext<BookingTestForm>();

    return (
        <BookingSection title={t('form.additionalCriteria')} isModalSection={false}>
            <Box sx={{
                display: 'flex',
                paddingTop: '10px',
                gap: '150px',
            }}>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, auto)',
                    justifyContent: 'start',
                }}>
                    <FormInput
                        translateKey={`${translateKeyPrefix}squareMeters`}
                        translationNameSpace={translationNameSpace}
                        formField={'minSquareMeters'}
                        type={'number'}
                        width={minWidth}
                        onBlur={() => formik.submitForm()}
                        minFormWidth={minFormInputWidth}
                        endAdornment={<FieldIcon type={'m2'}/>}
                    />
                    <FormInput
                        translateKey={`${translateKeyPrefix}upTo`}
                        translationNameSpace={translationNameSpace}
                        formField={'maxSquareMeters'}
                        type={'number'}
                        width={minWidth}
                        onBlur={() => formik.submitForm()}
                        minFormWidth={minFormInputWidth}
                        endAdornment={<FieldIcon type={'m2'}/>}
                    />
                </Box>

                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, auto)',
                    justifyContent: 'start',
                }}>
                    <FormInput
                        translateKey={`${translateKeyPrefix}distance`}
                        translationNameSpace={translationNameSpace}
                        formField={'minDistance'}
                        type={'number'}
                        onBlur={() => formik.submitForm()}
                        width={minWidth}
                        minFormWidth={minFormInputWidth}
                        endAdornment={<FieldIcon type={'km'}/>}
                    />
                    <FormInput
                        translateKey={`${translateKeyPrefix}upTo`}
                        translationNameSpace={translationNameSpace}
                        formField={'maxDistance'}
                        onBlur={() => formik.submitForm()}
                        type={'number'}
                        width={minWidth}
                        minFormWidth={minFormInputWidth}
                        endAdornment={<FieldIcon type={'km'}/>}
                    />
                </Box>
            </Box>
        </BookingSection>
    )
}

export default DetailsSettings
