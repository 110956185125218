import React, {FC, ReactElement} from "react";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import DummyPlug from "pages/DummyPlug";

const ApiDocumentation: FC<any> = (): ReactElement => {
    const {t} = useTranslation('common');
    return (
        <Box
            sx={{
                flexGrow: 1,
                backgroundColor: "whitesmoke",
                display: "flex",
                color: 'black',
                justifyContent: "center",
            }}
        >
            <DummyPlug text={t('nav.apiDocumentation')} height='80vh' horizontalMargin='20px' />
        </Box>
    );
};

export default ApiDocumentation;
