import React from 'react';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import {chipClasses} from "@mui/material";

const StyledChip = styled(Chip)<{ component?: React.ElementType }>(({ theme }) => ({
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        height: "42px",
        borderRadius: "21px",
        fontSize: "14px",
        ":hover": {
                backgroundColor: theme.palette.secondary.light,
        },
        [`&.${chipClasses.colorPrimary}`]: {
                backgroundColor: theme.palette.secondary.light,
        },
        [`& .${chipClasses.label}`]: {
                padding: "16px",
        },
}));

export default StyledChip
