import React, { useEffect } from 'react';
import Modal from '../../../../components/form/Modal';
import { Box, Grid } from '@mui/material';
import Button from '../../../../components/form/Button';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../../../components/form/TextField';
import Label from '../../../../components/form/Label';
import { makeStyles } from '@mui/styles';

export type NewGroupModalProps = {
    open: boolean,
    cancel: () => void,
    create: (groupName: string) => void,
}

const labeledFieldStyle = makeStyles(() => ({
    container: {
        alignItems: "center"
    }
}))

export const NewGroupModal = (props: NewGroupModalProps) => {
    const {t} = useTranslation('partner');
    const [name, setName] = React.useState<string>('')
    const classes = labeledFieldStyle();
    const onCreate = () => props.create(name.trim())

    useEffect(() => {
        if (props.open) {
            setName('')
            setTimeout(() => document.getElementById('newGroupName')?.focus(), 100)
        }
    }, [props.open]);

    return (
        <Modal isOpen={props.open}
               title={<Box sx={{whiteSpace: 'nowrap'}}>{t('mainData.groups.modal.title')}</Box>}
               width={'400px'}>
            <Box sx={{marginTop: '15px', display: 'flex', flexDirection: 'column', rowGap: '25px'}}>
                <Box sx={{fontSize: '16px'}}>
                    <Grid container item xs={12} md={12} className={classes.container}>
                        <Label label={t('mainData.groups.modal.name')} sx={{paddingRight: "1rem"}}/>
                        <TextField id="newGroupName"
                                   autoFocus={true}
                                   focused={true}
                                   sx={{ minWidth: '240px'}}
                                   onKeyUp={(event) => event.key === 'Enter' ? onCreate() : null }
                                   onChange={ event => setName(event.target.value) }/>
                    </Grid>
                </Box>

                <Box sx={{display: 'flex', justifyContent: 'end', columnGap: '15px'}}>
                    <Button label={t('mainData.groups.modal.cancel')} appearance={'dark'} variant={'outlined'}
                            onClick={() => props.cancel()}
                    />
                    <Button label={t('mainData.groups.modal.create')} appearance={'dark'}
                            disabled={name.trim() === ''}
                            onClick={ onCreate }
                    />
                </Box>
            </Box>
        </Modal>
    )
}
