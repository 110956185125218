import {
    Booking,
    BookingTour,
    BookingTourPostalCode,
    BookingTourRegion
} from "../../../../../../api/booking/BookingResource";
import React from "react";

const emptyFunction = () => {
}

export type BookingTestEditTourProps = {
    tour: BookingTour;
    originalRegionOrPostalCodeToEdit: RegionAndPostalCode
    editedRegionOrPostalCode: RegionAndPostalCode
    element: HTMLDivElement | null,
}
export type RegionAndPostalCode = {
    region?: BookingTourRegion;
    postalCode?: BookingTourPostalCode;
}
export type BookingTestActionsProps = {
    editPostalCode: (tour: BookingTour, postalCode: BookingTourPostalCode, element: HTMLDivElement) => void
    editRegion: (tour: BookingTour, region: BookingTourRegion, element: HTMLDivElement) => void,
    newPostalCode: (tour: BookingTour, element: HTMLDivElement | null, isFrom: boolean) => void,
    changeTourDirection: (tour: BookingTour) => void,
    clearEditedTour: () => void,
    updateEditedTour: (newValue: RegionAndPostalCode) => void,
    setTour: (tours: BookingTour) => void
}
export type BookingTestContextProps = {
    booking: Booking | undefined,
    editedTour?: BookingTestEditTourProps | undefined,
    actions: BookingTestActionsProps;
    isValid: boolean;
}

export const BookingTestContext = React.createContext<BookingTestContextProps>({
    editedTour: undefined,
    booking: undefined,
    isValid: false,
    actions: {
        editPostalCode: emptyFunction,
        editRegion: emptyFunction,
        newPostalCode: emptyFunction,
        changeTourDirection: emptyFunction,
        updateEditedTour: emptyFunction,
        clearEditedTour: emptyFunction,
        setTour: emptyFunction
    }
});
