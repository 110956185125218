import {FC, ReactElement} from "react";
import ActionImg from "../../../../components/shared/ActionImg";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";

type LeadActionsProps = {
    onSendAgain: () => void;
}

const LeadActions: FC<LeadActionsProps> = ({onSendAgain}: LeadActionsProps): ReactElement => {
    const {t} = useTranslation('requests')

    return (
        <Box sx={{width: '100%', display: "flex", justifyContent: "flex-end"}}>
            <ActionImg icon={'/icons/send.svg'} iconStyle={{marginLeft: '25px'}}
                       onClick={(event) => {
                           event.stopPropagation();
                           onSendAgain();
                       }}
                       tooltip={t('leads.table.tooltip.sendAgain')}
            />
        </Box>
    )
}
export default LeadActions;