import React, {FC, ReactElement, useRef, useState} from "react";
import {Box, Theme, Typography, useTheme} from "@mui/material";
import DatePicker from "components/form/DatePicker";
import {useTranslation} from "react-i18next";
import RequestsAndLeadsReport, {RequestsAndLeadsReportRef} from "pages/reports/overview/RequestsAndLeadsReport";
import {formatToLocaleTextWithDate} from "utils/DateUtil";
import RequestTypesReport, {RequestTypesReportRef} from "pages/reports/overview/RequestsTypesReport";
import RequestSourcesReport, {RequestSourcesReportRef} from "pages/reports/overview/RequestsSourcesReport";
import DateRangeTypePicker from "pages/reports/overview/DateRangeTypePicker";
import ChipIcon from "components/shared/ChipIcon";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import useDateRanges from "pages/reports/overview/useDateRanges";
import StyledChip from "components/shared/StyledChip";

const countryCodes = ['DE', 'CH', 'AT']

const OverviewReport: FC = (): ReactElement => {
    const theme = useTheme<Theme>()
    const reportsTranslation = useTranslation('reports').t
    const {
        dateRangeType,
        startDate,
        endDate,
        comparisonStartDate,
        comparisonEndDate,
        goBack,
        goForward,
        setDateRangeType,
        setCustomRange
    } = useDateRanges()

    const mainLeadsReportRef = useRef<RequestsAndLeadsReportRef>(null);
    const comparisonLeadsReportRef = useRef<RequestsAndLeadsReportRef>(null);
    const mainTypesReportRef = useRef<RequestTypesReportRef>(null);
    const comparisonTypesReportRef = useRef<RequestTypesReportRef>(null);
    const mainSourcesReportRef = useRef<RequestSourcesReportRef>(null);
    const comparisonSourcesReportRef = useRef<RequestSourcesReportRef>(null);

    const [searchDisabled, setSearchDisabled] = useState<boolean>(false);
    const [refreshDisabled, setRefreshDisabled] = useState<boolean>(true);

    const fetchStatistics = async () => {
        setRefreshDisabled(true);
        setSearchDisabled(true)
        try {
            await Promise.all([
                mainLeadsReportRef.current?.fetchStatistics(),
                comparisonLeadsReportRef.current?.fetchStatistics(),
                mainTypesReportRef.current?.fetchStatistics(),
                comparisonTypesReportRef.current?.fetchStatistics(),
                mainSourcesReportRef.current?.fetchStatistics(),
                comparisonSourcesReportRef.current?.fetchStatistics(),
            ]);
        } finally {
            setRefreshDisabled(false);
        }
    };

    const handleStartDateChange = (date: Date | null | undefined) => {
        if (!date) return;

        const adjustedEndDate = endDate < date ? date : endDate;

        const maxEndDate = new Date(date);
        maxEndDate.setFullYear(maxEndDate.getFullYear() + 1);

        const validatedEndDate = adjustedEndDate > maxEndDate ? maxEndDate : adjustedEndDate;

        setCustomRange(date, validatedEndDate);
        setSearchDisabled(false);
    };

    const handleEndDateChange = (date: Date | null | undefined) => {
        if (!date) return;

        const adjustedStartDate = startDate > date ? date : startDate;

        const minStartDate = new Date(date);
        minStartDate.setFullYear(minStartDate.getFullYear() - 1);

        const validatedStartDate = adjustedStartDate < minStartDate ? minStartDate : adjustedStartDate;

        setCustomRange(validatedStartDate, date);
        setSearchDisabled(false);
    };

    const handleRefreshData = () => {
        if (refreshDisabled) {
            return
        }
        fetchStatistics().then()
    };

    const handleSearch = () => {
        if (searchDisabled) {
            return
        }
        fetchStatistics().then()
    };

    const handleGoBack = () => {
        goBack();
        setSearchDisabled(false)
        setRefreshDisabled(true)
    };

    const handleGoForward = () => {
        goForward();
        setSearchDisabled(false)
        setRefreshDisabled(true)
    };

    const handleDateRangeTypeChange = (newType: typeof dateRangeType) => {
        setDateRangeType(newType)
        setSearchDisabled(false)
        setRefreshDisabled(true)
    };

    return (
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Box sx={{maxWidth: "1600px"}}>
                <Box sx={{
                    display: "grid",
                    gridTemplateColumns: {
                        xs: "1fr",
                        lg: "1fr 1fr"
                    },
                    gap: "15px"
                }}>
                    <Box sx={{marginTop: "10px", display: "flex", justifyContent: {xs: "center", lg: "flex-end"}}}>
                        <DatePicker initialValue={startDate}
                                    onChange={handleStartDateChange}
                                    disableFuture={true}
                                    isUTC={false}
                                    sx={{marginLeft: "0"}}
                        />
                        <DatePicker initialValue={endDate}
                                    onChange={handleEndDateChange}
                                    disableFuture={true}
                                    isUTC={false}
                        />
                        <StyledChip sx={{ marginTop: "10px", marginRight: "10px" }}
                            disabled={searchDisabled}
                            color={'default'}
                            label={reportsTranslation("partner.search")}
                            onClick={handleSearch} />
                        <ChipIcon
                            disabled={refreshDisabled}
                            sx={{marginTop: "10px"}}
                            onClick={handleRefreshData}
                            icon={<AutorenewIcon sx={{height: "26px", fill: theme.palette.common.white}}/>}/>
                    </Box>
                    <Box sx={{marginTop: {xs: "0px", lg: "20px"}, marginBottom: {xs: "20px", lg: "0px"}}}>
                        <DateRangeTypePicker
                            dateRangeType={dateRangeType}
                            onClickPrevious={handleGoBack}
                            onClickNext={handleGoForward}
                            onDateRangeTypeChange={handleDateRangeTypeChange}
                        />
                    </Box>
                </Box>
                <Box
                  sx={{
                      display: "grid",
                      gridTemplateColumns: { xs: "1fr", lg: "1fr 1fr" },
                      gap: "15px",
                      marginTop: "40px",
                  }}
                >
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                        <Typography>
                            {
                                `${reportsTranslation("partner.headline.mainDateRangeHeader")}
                                ${formatToLocaleTextWithDate(startDate)} - ${formatToLocaleTextWithDate(endDate)}`
                            }
                        </Typography>
                        <RequestsAndLeadsReport ref={mainLeadsReportRef} startDate={startDate} endDate={endDate} countryCodes={countryCodes}/>
                        <RequestTypesReport ref={mainTypesReportRef} startDate={startDate} endDate={endDate}/>
                        <RequestSourcesReport ref={mainSourcesReportRef} startDate={startDate} endDate={endDate} countryCodes={countryCodes}/>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                        <Typography>
                            {
                                `${reportsTranslation("partner.headline.comparisonDateRangeHeader")} 
                                ${formatToLocaleTextWithDate(comparisonStartDate)} - ${formatToLocaleTextWithDate(comparisonEndDate)}`
                            }
                        </Typography>
                        <RequestsAndLeadsReport ref={comparisonLeadsReportRef} startDate={comparisonStartDate} endDate={comparisonEndDate} countryCodes={countryCodes}/>
                        <RequestTypesReport ref={comparisonTypesReportRef} startDate={comparisonStartDate} endDate={comparisonEndDate}/>
                        <RequestSourcesReport ref={comparisonSourcesReportRef} startDate={comparisonStartDate} endDate={comparisonEndDate} countryCodes={countryCodes}/>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default OverviewReport;
