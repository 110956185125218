import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import FormLabel from "../../../../../components/form/FormLabel";
import React, {ReactElement} from "react";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";


export type FilterLabelWithContentProps = {
    type: string;
    hideLabel?: boolean;
    sx?: SxProps<Theme>;
    children?: ReactElement;
}

const FilterLabelWithContent = (props: FilterLabelWithContentProps) => {
    const {t} = useTranslation('algotest');
    const {type, hideLabel, children} = props;
    return (
        <Box sx={props.sx} data-testid={`label-${type}`}>
            {!hideLabel && <FormLabel
                title={t(`form.${type}`)}
                fontSize="12px"
                customStyles={{lineHeight: '14px', marginTop: '10px'}}
                fontWeight={600}/>}
            {children}
        </Box>
    )
}
export default FilterLabelWithContent