import {
    initPageDetails,
    UndistributedRequestsActionsProps,
    UndistributedRequestsContext,
    UndistributedRequestsContextProps
} from "./UndistributedRequestsContext.model";
import {useContext, useEffect, useState} from "react";
import {LeadGenerationResult, LeadGenerationResultType} from "api/lead/LeadResources";
import {AlertProps} from "components/feedback/Alert";
import {useTranslation} from "react-i18next";
import {PageDetails} from "api/Page";
import {getLeadGenerationResults} from "api/lead/LeadApi";


export function useInitUndistributedRequestsContext(partnerCwid: string | undefined): UndistributedRequestsContextProps {
    const [loading, setLoading] = useState<boolean>(false);
    const [alert, setAlert] = useState<AlertProps | undefined>(undefined)

    const [rejectedLeads, setRejectedLeads] = useState<LeadGenerationResult[]>([])
    const [pageDetails, setPageDetails] = useState<PageDetails>(initPageDetails)

    const {t} = useTranslation('undistributedRequests')

    const showAlert = (translationKey: string) => {
        setAlert({
            color: 'error',
            open: true,
            text: t(`rejectedLeads.alerts.${translationKey}`),
            handleClose: () => setAlert(undefined)
        })
    }

    const loadRejectedLeads = (pageForLoad: number) => {
        if (partnerCwid) {
            if (pageForLoad === 0) {
                setLoading(true)
            }
            getLeadGenerationResults(partnerCwid, LeadGenerationResultType.REJECTED, pageForLoad, 20)
                .then(rejectedLeads => {
                    if (pageForLoad === 0) {
                        setRejectedLeads(rejectedLeads._embedded?.leads || []);
                    } else {
                        setRejectedLeads(prevState => [...prevState, ...(rejectedLeads._embedded?.leads || [])]);
                    }
                    setPageDetails(rejectedLeads.page)
                })
                .catch(() => showAlert('getRejectedLeadsError'))
                .finally(() => setLoading(false))
        }
    }

    const actions: UndistributedRequestsActionsProps = {
        onLoadNextPage: () => {
            const {number, totalPages} = pageDetails
            if (number + 1 < totalPages) {
                loadRejectedLeads(number + 1);
            }
        },
    }

    useEffect(() => {
        if (partnerCwid) {
            loadRejectedLeads(0)
        }
        // eslint-disable-next-line
    }, [partnerCwid])

    return {
        rejectedLeads,
        pageDetails,
        loading,
        alert,
        actions,
    }
}

export function useUndistributedRequestsContext(): UndistributedRequestsContextProps {
    return useContext(UndistributedRequestsContext)
}