import {RegionAndPostalCode} from "../../BookingModalContext.model";
import {
    BookingTourPostalCode,
    BookingTourRegion,
    BookingTourType
} from "../../../../../../../api/booking/BookingResource";
import React from "react";

export type PopoverContent = {
    id?: number,
    regionId?: number,
    type?: BookingTourType,
    country?: string,
    postalCode?: string;
    isExcluded?: boolean;
}
const emptyFunction = () => {}
export type BookingPopoverActionsProps = {
    remove: (remove: RegionAndPostalCode | undefined) => void,
    savePostalCode: (remove: RegionAndPostalCode | undefined, newPostalCode: BookingTourPostalCode) => void,
    saveRegion: (remove: RegionAndPostalCode | undefined, newRegion: BookingTourRegion) => void,
    changeSelectedCountryOrRegion: (value: string) => void,
    changeExclusion: (isExcluded: boolean) => void
    changePopoverPostalCode: (value: string) => void
}
export type BookingPopoverContextProps = {
    popoverContent: PopoverContent | undefined,
    popoverActions: BookingPopoverActionsProps;
}
export const BookingPopoverContext = React.createContext<BookingPopoverContextProps>({
    popoverContent: undefined,
    popoverActions: {
        remove: emptyFunction,
        savePostalCode: emptyFunction,
        saveRegion: emptyFunction,
        changeSelectedCountryOrRegion: emptyFunction,
        changeExclusion: emptyFunction,
        changePopoverPostalCode: emptyFunction
    }
})